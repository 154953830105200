import { types } from 'mobx-state-tree'

import { ScriptStatus, ScriptStatusMap } from '@showrunner/codex'

import { SharedScriptStatus } from '@state/types'

import type { IFolder, IOrgMember } from '../types'

import { BaseModel } from './BaseModel'
import { IsoDate } from './IsoDate'

export const ListingBase = BaseModel.named('ListingBase')
  .props({
    id: types.union(types.string, types.number),
    name: types.string,
    contentsModifiedAt: IsoDate,
    contentsModifiedBy: types.string,
    createdAt: IsoDate,
    folderId: types.string,
  })
  .views((self) => ({
    get lastModifier(): IOrgMember | undefined {
      return self.rootStore.currentOrg?.getMember(self.contentsModifiedBy)
    },
    get parentFolder(): IFolder | undefined {
      return self.rootStore.folderMap.get(self.folderId)
    },
    get inTrash(): boolean {
      return !!this.parentFolder?.inTrash
    },
    get isFavorite(): boolean {
      return !!self.rootStore.currentOrg?.favoriteListingIds.includes(self.id)
    },
    get isPrivate(): boolean {
      return !!this.parentFolder?.isPrivate
    },
    get isCurrentScript(): boolean {
      return !!(self.id === self.rootStore.currentScript?.id)
    },
    get openedAt(): Date | undefined {
      const match = self.rootStore.currentOrg?.myHistory.find(
        ([, listingId]) => listingId === self.id,
      )
      return match ? new Date(match[0]) : undefined
    },
  }))
  .actions((self) => ({
    handleListingUpdate({
      name,
      folderId,
      contentsModifiedAt,
      contentsModifiedBy,
    }: {
      name: string
      folderId: string
      contentsModifiedAt: Date
      contentsModifiedBy: string
    }) {
      self.contentsModifiedAt = contentsModifiedAt
      self.contentsModifiedBy = contentsModifiedBy
      self.name = name
      self.folderId = folderId
    },
  }))

export const ScriptListingBase = ListingBase.named('ScriptListingBase')
  .props({
    // The goal is to turn this into just LIMITED and OPEN
    status: types.enumeration<ScriptStatus>(Object.values(ScriptStatusMap)),
  })
  .views((self) => ({
    get accessLevel(): ScriptStatus {
      return self.isPrivate ? 'PRIVATE' : self.status
    },
    // right now the only reason a user cant comment on a script is when its in the trash
    // but in a world with more fine-grained permissions, that will change
    get canAddComments(): boolean {
      return !self.inTrash
    },
  }))
  .actions((self) => ({
    setSharedStatus(value: SharedScriptStatus) {
      self.status = value

      if (self.isCurrentScript) {
        const { currentScript } = self.rootStore
        currentScript?.setPmEditability(currentScript?.isEditable ?? false)
      }
    },
    // little hack so we pretend the api NEVER sends us
    // a status of private
    afterAttach() {
      if (self.isPrivate) {
        self.status = 'OPEN'
      }
    },
  }))
