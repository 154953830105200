import React from 'react'

import { useQuery } from '@tanstack/react-query'
import { RouteComponentProps } from 'wouter'

import { ArchivedScript } from '@components/ArchivedScript'
import { NotFoundPage, UnknownErrorPage } from '@components/ErrorPage'
import { Loader } from '@components/Loader'
import { useMst } from '@state'

export const ArchivedScriptRoute = ({
  params,
}: RouteComponentProps<{ scriptId: string }>) => {
  const mst = useMst()
  const { scriptId } = params

  const { data, isError, isLoading } = useQuery({
    queryFn: async () => {
      await mst.doDebug()
      return mst.scrapi.workspaces.getArchivedScript({
        params: {
          id: mst.currentOrg?.id ?? 'ruh-roh',
          scriptId,
        },
      })
    },
    queryKey: ['archived-script', scriptId],
    cacheTime: Infinity, // it aint changin
    staleTime: Infinity,
  })

  if (isError) return <UnknownErrorPage />

  if (data) {
    const { status } = data
    if (status === 200) {
      return <ArchivedScript script={data.body} />
    }
    if (status === 404) return <NotFoundPage />
    return <UnknownErrorPage />
  }

  return <Loader visible={isLoading} />
}
