import { z } from 'zod'

import { schemas as scrapiSchemas } from '@showrunner/scrapi'

import { printPreferences } from '@util/zodSchemas'

// prefs are always stored as objects
const SERIALIZED_PREF_RE = /^{.*}$/

const localStorageValueToPojo = (value: unknown): object => {
  if (typeof value === 'string' && SERIALIZED_PREF_RE.test(value.trim())) {
    try {
      return JSON.parse(value)
    } catch {
      return {}
    }
  }
  return {}
}

const basePreference = z.unknown().transform(localStorageValueToPojo)

const storedRundownPrintPrefs = basePreference.pipe(
  printPreferences.rundownPrintPrefs,
)

const storedScriptPrintPrefs = basePreference
  .pipe(printPreferences.scriptPrintPreferences)
  .catch(printPreferences.DEFAULT_SCRIPT_PREFS)

const openedListing = z.tuple([
  scrapiSchemas.SerializableDate,
  scrapiSchemas.ListingId,
])
const openedListingsPref = basePreference
  .pipe(
    z.object({
      listings: z.array(openedListing),
    }),
  )
  .catch({
    listings: [],
  })

export type OpenedListingsPref = z.infer<typeof openedListingsPref>

export const prefSchemas = {
  openedListingsPref,
  storedRundownPrintPrefs,
  storedScriptPrintPrefs,
}
