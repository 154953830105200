import React from 'react'

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ModuleRegistry } from '@ag-grid-community/core'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { MenuModule } from '@ag-grid-enterprise/menu'
import NiceModal from '@ebay/nice-modal-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import axiosStatic from 'axios'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { createRoot } from 'react-dom/client'

import { createRootStore, MstProvider } from '@state'
import { LocalPersistence } from '@util/LocalPersistence'

import {
  exposeDevTools,
  getEnvLogoOverride,
  getFaKitRoot,
  // see below for why this is commented out
  // getHttpTimeoutMs,
  getServerConfig,
} from './config'
import { DebugTools } from './debug/DebugTools'
import reportWebVitals from './reportWebVitals'
import { MainRouter } from './routes'
import { ThemeProvider } from './theme'

import 'normalize.css/normalize.css'
import '@mantine/core/styles.layer.css'
import '@mantine/dates/styles.layer.css'
import './wombat-scss/showrunner.scss'

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-061211}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Scripto_Enterprises_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Scripto}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Scripto}_need_to_be_licensed___{Scripto}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_August_2025}____[v3]_[01]_MTc1NDI2MjAwMDAwMA==f509adc2a8dd25d373569459c7bcc04d',
)

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ClipboardModule,
  MenuModule,
])

const config = Object.freeze(getServerConfig())
const favicon = getEnvLogoOverride(config.name)
if (favicon) {
  document.getElementById('scripto-favicon')?.setAttribute('href', favicon)
}

const axios = axiosStatic.create({
  // enables cookie auth
  withCredentials: true,
  baseURL: config.API_URL,

  // We are seeing weird sync loop errors in production when this is set, so
  // commenting this out. If you run in debugMode, you can, however,
  // configure the default timeout with window.d.setAPITimeout(ms: number)

  // timeout: getHttpTimeoutMs(),
  headers: {
    'content-type': 'application/json',
  },
})

const localPersistence = new LocalPersistence()
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
})

const store = createRootStore({
  snapshot: {},
  axios,
  config,
  localPersistence,
})
store.bootstrap().catch(() => store.setServerDown(true))
window.addEventListener('storage', store.handleStorageEvent)

/*
  This is a crude way to wire up this listener but I think choo
  is meddling with the popstate handler and preventing us from doing this
  in normal unload handlers.

  TODO: Revisit this when choo is no longer involved in routing and see if
  we can detect whether we actually need to tear down the current script
*/
window.addEventListener('popstate', () => {
  if (store.currentScript && store.currentRundown) {
    window.location.reload()
  } else {
    store.removeCurrentScript()
  }
})

if (exposeDevTools()) {
  window.d = new DebugTools(store)
}

const fontAwesomeCss = document.createElement('link')
fontAwesomeCss.type = 'text/css'
fontAwesomeCss.rel = 'stylesheet'
document.head.appendChild(fontAwesomeCss)
fontAwesomeCss.href = getFaKitRoot() + 'f915463568/css/all.min.css'

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <MstProvider value={store}>
          <ThemeProvider>
            <NiceModal.Provider>
              <DndProvider backend={HTML5Backend}>
                <MainRouter />
              </DndProvider>
            </NiceModal.Provider>
          </ThemeProvider>
        </MstProvider>
      </QueryClientProvider>
    </React.StrictMode>,
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
