import React from 'react'

import { showError } from '@components/Modals'
import { useMst } from '@state'
import { createSnapshotEditorState } from '@util'
import { buildPrintableScriptHtml } from '@util/princePrinting'
import { getBlobAndPrint, getBlobAndSave } from '@util/printing'

import { SnapshotPage } from '../SnapshotPage'
import { useScriptData, useSnapshotData } from '../useSnapshotQueries'

import { SnapshotViewerBase } from './SnapshotViewer'

export const StaticSnapshotPage = ({
  params,
}: {
  params: {
    scriptId: string
    snapshotId: string
  }
}) => {
  const { scriptId, snapshotId } = params
  const { isLoading, isError, data } = useSnapshotData(params)
  // we only use the script data for the title when downloading and we'll work around it
  // if it fails to load
  // ideally the GET snapshot payload would return the current name of the script too
  const { data: scriptData } = useScriptData(scriptId)
  const [isPrinting, setIsPrinting] = React.useState(false)

  const mst = useMst()
  const { apiClient, currentOrg, user, view, environment } = mst
  const isInk = data?.scriptFormat.definition.scriptType === 'ink'
  const pageless = user.prefs.pageless || isInk
  const noWrap =
    isInk && !environment.localPersistence.getInkPreferences(user.id)?.lineWrap

  const handleDownloadFdx = async () => {
    setIsPrinting(true)
    try {
      await currentOrg?.exportSnapshotToFdx({ scriptId, snapshotId })
    } catch (e) {
      showError('Failed to create FDX')
    } finally {
      setIsPrinting(false)
    }
  }

  const handleCreatePdf = async (type: 'download' | 'print') => {
    const unwatermark = view.shouldUnwatermark
    const filenamePrefix = scriptData ? `${scriptData.script.name} - ` : ''
    const snapshotName = data?.name ?? data?.autoSave ?? 'Snapshot'
    const bareFileName = `${filenamePrefix}${snapshotName}`
    const fileName = `${bareFileName}.pdf`

    setIsPrinting(true)
    const downloadFn = async () => {
      if (!data) return
      const html = buildPrintableScriptHtml({
        snapshot: data,
        prefs: user.scriptPrintPrefs,
        title: bareFileName,
        readRate: currentOrg?.readRate,
      })
      return apiClient.passThroughPrint({
        html,
        fileName,
        unwatermark,
      })
    }

    try {
      if (type === 'print') {
        await getBlobAndPrint(downloadFn)
      } else {
        await getBlobAndSave({ downloadFn, fileName })
      }
    } catch (e) {
      showError('Failed to create PDF')
    } finally {
      setIsPrinting(false)
    }
  }

  return (
    <SnapshotPage loading={isLoading || isPrinting} error={isError} {...params}>
      <SnapshotPage.Toolbar
        activeTab="snapshot"
        scriptId={params.scriptId}
        snapshotId={params.snapshotId}
        onPrint={() => handleCreatePdf('print')}
        onDownloadPdf={() => handleCreatePdf('download')}
        onDownloadFdx={handleDownloadFdx}
      />
      {data && (
        <SnapshotViewerBase
          editorZoom={user.prefs.editorZoom}
          pageless={pageless}
          noWrap={noWrap}
          editorState={createSnapshotEditorState(data, mst)}
          format={data.scriptFormat}
        />
      )}
    </SnapshotPage>
  )
}
