import { types } from 'mobx-state-tree'

import { ExplorerViewType } from '@state/types'

import { BaseModel } from '../BaseModel'

// This models state of the sidebar buttons, selected view, collapsed state
// etc. The reason it has relatively few props is largely because so much of
// the state of the explorer is persisted in UserPreferences (and thus this model
// accesses it via self.rootStore.user.prefs)
export const ExplorerState = BaseModel.named('ExplorerState')
  .props({
    collectionsExpanded: true,
    dragging: false,
    collapsedSections: types.array(types.string),
    // if the user clicks the crosshairs to locate a document, we store the
    // data here until we can scroll to or jiggle the requested items (they may not
    // actually be in the dom yet)
    crosshairsRequestDocId: types.maybe(types.string),
    crosshairsRequestFolderId: types.maybe(types.string),
    // these are selectors for dom nodes we've added css classes to. The useDocLocator
    // uses this to clean up the classes after a timeout
    jiggledSelectors: types.array(types.string),
    // when navigating from the snapshot history to a snapshot, store this
    // so we know where to return to
    lastScriptPath: types.maybe(types.string),
  })
  .views((self) => ({
    // Get the user's most recently selected explorer view IF it is currently valid
    // (if no preference or not valid, use 'browser' which is always valid)
    get selectedView(): ExplorerViewType {
      const preferredView =
        self.rootStore.user.prefs.sidebarCurrentPane ?? 'browser'
      return this.enabledViews.includes(preferredView)
        ? preferredView
        : 'browser'
    },
    get enabledViews(): ExplorerViewType[] {
      // i suspect there's probably a less brittle way to
      // determine the current route type than any of these
      const isSnapshotRoute = /\/(diff|compare|snapshots)\//.test(
        self.rootStore.location.pathname,
      )
      const isScriptRoute =
        !isSnapshotRoute && self.rootStore.location.getPathParam('scriptId')
      const isRundownRoute = self.rootStore.location.getPathParam('rundownId')

      const isFolderRoute =
        self.rootStore.location.pathname.includes('/folders/') ||
        self.rootStore.location.pathname === '/'

      const isArchivedScriptRoute =
        self.rootStore.location.pathname.includes('/wayback/')

      const result: ExplorerViewType[] = []
      if (
        isFolderRoute ||
        isRundownRoute ||
        isScriptRoute ||
        isArchivedScriptRoute
      ) {
        result.push('browser', 'search')
      }
      if (isRundownRoute || isScriptRoute) {
        result.push('outline')
      }
      if (isScriptRoute) {
        result.push('history')
      }
      return result
    },
    get explorerOpenWidth(): number | undefined {
      return self.rootStore.user.prefs.sidebarOpenWidth
    },
    get isExplorerClosed(): boolean {
      return (
        !!self.rootStore.user.prefs.sidebarClosed ||
        this.enabledViews.length === 0
      )
    },
    isSectionCollapsed(sectionId: string): boolean {
      return self.collapsedSections.includes(sectionId)
    },
  }))
  .actions((self) => ({
    expandFolderView() {
      this.setExplorerClosed(false)
      this.selectView('browser')
      self.collapsedSections.remove('folders')
      self.collapsedSections.remove('documents')
    },
    toggleCollections() {
      self.collectionsExpanded = !self.collectionsExpanded
    },
    setDragging(value: boolean) {
      self.dragging = value
    },
    selectView(view: ExplorerViewType) {
      if (self.enabledViews.includes(view)) {
        self.rootStore.user.updatePreferences({ sidebarCurrentPane: view })
      }
    },
    setExplorerClosed(value: boolean) {
      self.rootStore.user.updatePreferences({
        sidebarClosed: value,
      })
    },
    setExplorerWidth(value: number) {
      self.rootStore.user.updatePreferences({ sidebarOpenWidth: value })
    },
    setSectionCollapsed(sectionId: string, value: boolean) {
      const currentValue = self.collapsedSections.includes(sectionId)
      if (currentValue && !value) {
        self.collapsedSections.remove(sectionId)
      } else if (!currentValue && value) {
        self.collapsedSections.push(sectionId)
      }
    },
    toggleSectionCollapsed(sectionId: string) {
      if (self.collapsedSections.includes(sectionId)) {
        self.collapsedSections.remove(sectionId)
      } else {
        self.collapsedSections.push(sectionId)
      }
    },
    setCrosshairsDocRequest(id: string) {
      self.crosshairsRequestDocId = id
    },
    clearCrosshairsDocRequest() {
      self.crosshairsRequestDocId = undefined
    },
    setCrosshairsFolderRequest(id: string) {
      self.crosshairsRequestFolderId = id
    },
    clearCrosshairsFolderRequest() {
      self.crosshairsRequestFolderId = undefined
    },
    setLastScriptPath(value: string) {
      self.lastScriptPath = value
    },
  }))
