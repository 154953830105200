import { useState } from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { Checkbox, Group, Text } from '@mantine/core'

import { ModalShell, useModalControls } from '@components/Modals'
import { useMst } from '@state'

import avatars from '../../assets/images/avatars.png'
import cursors from '../../assets/images/cursors.png'
import blockmenu from '../../assets/images/el-menu.png'

import styles from './ViewPreferencesModal.module.scss'

export const ViewPreferences = () => {
  const controls = useModalControls()
  const mst = useMst()
  const { hideCollabAvatars, showCollabCursors, hideBlockMenu } = mst.user.prefs

  const [hideAvatars, setHideAvatars] = useState(hideCollabAvatars ?? false)
  const [showCursors, setShowCursors] = useState(showCollabCursors ?? false)
  const [hideMenu, setHideMenu] = useState(hideBlockMenu ?? false)

  const togglePrefs = () => {
    mst.user.updatePreferences({
      hideCollabAvatars: hideAvatars,
      showCollabCursors: showCursors,
      hideBlockMenu: hideMenu,
    })
    mst.currentScript?.pmEditor.rerender()
    controls.onClose()
  }

  // dont expose this pref in ink scripts because we suppress the element menu altogether there
  const canUpdateBlockMenuPref = !mst.currentScript?.isInk

  return (
    <ModalShell
      title="View preferences"
      onConfirm={togglePrefs}
      onClose={controls.onClose}
      confirmLabel="Save"
      cancelLabel="Cancel"
      size="md"
      opened={controls.opened}
    >
      <Group justify="space-between" wrap="nowrap">
        <Checkbox
          classNames={{
            body: styles.collabPreferenceModal_body,
            description: styles.collabPreferenceModal_description,
          }}
          label={<Text fw="bold">Show collaborators in margin</Text>}
          description="You'll see other people's avatars to the left of any text block their cursor is in"
          checked={!hideAvatars}
          onChange={() => setHideAvatars(!hideAvatars)}
        />
        <img src={avatars} alt="collaborator avatars" />
      </Group>
      <Group justify="space-between" wrap="nowrap">
        <Checkbox
          classNames={{
            body: styles.collabPreferenceModal_body,
            description: styles.collabPreferenceModal_description,
          }}
          label={<Text fw="bold">Show collaborator cursors</Text>}
          description="You'll see other people's cursors and text selections"
          checked={showCursors}
          onChange={() => setShowCursors(!showCursors)}
        />
        <img src={cursors} alt="collaborator cursors" />
      </Group>
      {canUpdateBlockMenuPref && (
        <Group justify="space-between" wrap="nowrap">
          <Checkbox
            classNames={{
              body: styles.collabPreferenceModal_body,
              description: styles.collabPreferenceModal_description,
            }}
            label={<Text fw="bold">Show inline block type menu</Text>}
            description="You'll see the current block type represented by an icon in the left margin"
            checked={!hideMenu}
            onChange={() => setHideMenu(!hideMenu)}
          />
          <img src={blockmenu} alt="block menu" />
        </Group>
      )}
    </ModalShell>
  )
}

export const ViewPreferenceModal = NiceModal.create(ViewPreferences)

export const showViewPreferencesModal = () =>
  NiceModal.show(ViewPreferenceModal)
