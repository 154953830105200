import { EditorState, Plugin, PluginKey } from 'prosemirror-state'
import { DecorationSet } from 'prosemirror-view'

import {
  createPaginationBreakdown,
  PaginationBreakdown,
} from '@util/pagination'

import { getConfigData } from '../configData'

import { buildDesiredDecorations } from './decorations'

export type PageBreakPluginState = {
  decorations: DecorationSet
  data: PaginationBreakdown
} | null

export const inlinePagebreaksKey = new PluginKey<PageBreakPluginState>(
  'inlinePageBreaks',
)

const getEnabled = (editorState: EditorState): boolean => {
  const { paginationType } = getConfigData(editorState)
  return paginationType !== 'structural'
}

export const inlinePageBreaksPlugin = () => {
  return new Plugin<PageBreakPluginState>({
    key: inlinePagebreaksKey,
    state: {
      init(config, editorState) {
        const isEnabled = getEnabled(editorState)
        if (!isEnabled) {
          return null
        }

        const paginationBreakdown = createPaginationBreakdown(
          editorState.doc,
          getConfigData(editorState).currentBlockFormats,
        )

        return {
          data: paginationBreakdown,
          decorations: DecorationSet.create(
            editorState.doc,
            buildDesiredDecorations(paginationBreakdown),
          ),
        }
      },
      apply(tr, pluginState, oldState, newState) {
        const isEnabled = getEnabled(newState)
        if (!isEnabled) {
          return null
        }

        const docEqual = oldState.doc.eq(newState.doc)
        const newlyEnabled = pluginState === null

        if (docEqual && !newlyEnabled) {
          return pluginState
        }

        const paginationBreakdown = createPaginationBreakdown(
          newState.doc,
          getConfigData(newState).currentBlockFormats,
        )

        return {
          data: paginationBreakdown,
          decorations: DecorationSet.create(
            newState.doc,
            buildDesiredDecorations(paginationBreakdown),
          ),
        }
      },
    },
    props: {
      decorations(editorState) {
        return inlinePagebreaksKey.getState(editorState)?.decorations
      },
    },
  })
}
