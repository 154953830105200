import { EditorView } from 'prosemirror-view'

type AsteriskPositionData = {
  [top: number]: {
    type: 'changed' | 'removed'
    blockId: string
    blockYOffset: number
  }
}

const processOneChange = (params: {
  editorView: EditorView
  position: number
  type: 'changed' | 'removed'
}):
  | {
      mapKey: number
      blockId: string
      blockYOffset: number
    }
  | undefined => {
  const { editorView, position, type } = params
  const node = editorView.state.doc.nodeAt(position)
  // we only want to add asterisks that are in text nodes
  if (node && node.isText) {
    // use prosemirror resolve to find the parent block of depth 2
    // (which will have an ID)
    const resolved = editorView.state.doc.resolve(position)
    const block = resolved.node(2)
    const blockId = block.attrs.id
    // Use the block's coords and the change's coords to figure out
    // how far from the top of the block the asterisk needs to go
    const blockStart = resolved.start(2)
    const { top } = editorView.coordsAtPos(position)
    const blockTop = editorView.coordsAtPos(blockStart).top
    const blockYOffset = top - blockTop

    return {
      mapKey: top,
      blockId,
      blockYOffset,
    }

    // for removed nodes, if we aren't in a text node we want to find the last text node BEFORE
    // the removal and use that for the mark. So, if we're not at 0, recurse with the previous
    // position
  } else if (type === 'removed' && position > 0) {
    return processOneChange({ editorView, type, position: position - 1 })
  }
}

export const buildAsteriskData = (params: {
  editorView: EditorView
  changes: [number, number][]
  removals: number[]
}): AsteriskPositionData => {
  const { editorView, changes, removals } = params
  // create a map where the keys are the "top" DOM position and the values
  // give us the needed info to put a symbol at the correct place in the doc.
  // By doing this as a map, we can consolidate multiple changes that affect the same
  // line (and clobber deletion marks with addition marks)
  const asteriskMap: AsteriskPositionData = {}

  // start by processing the removals as we want them to get clobbered
  removals.forEach((position) => {
    const result = processOneChange({ editorView, position, type: 'removed' })
    if (result) {
      asteriskMap[result.mapKey] = {
        type: 'removed',
        blockId: result.blockId,
        blockYOffset: result.blockYOffset,
      }
    }
  })

  // now process the changes, additions. We want to process each individual character in
  // the range
  changes.forEach(([startPosition, endPosition]) => {
    for (let i = startPosition; i < endPosition; i++) {
      const result = processOneChange({
        editorView,
        position: i,
        type: 'changed',
      })
      if (result) {
        asteriskMap[result.mapKey] = {
          type: 'changed',
          blockId: result.blockId,
          blockYOffset: result.blockYOffset,
        }
      }
    }
  })

  return asteriskMap
}

export const insertAsteriskNodes = (data: AsteriskPositionData) => {
  // the X position of the revision asterisk is absolute, based on the
  // right edge of the editor. Note that although this seems overly ornate,
  // the server lays out the dom differently and changes to this positioning
  // model are fragile
  const editor = document.getElementById('prosemirror-editor')
  const horizontalPosition = editor
    ? editor.getBoundingClientRect().right - 96
    : 0

  Object.values(data).forEach(({ blockId, blockYOffset, type }) => {
    const block = document.getElementById(blockId)
    if (!block) {
      return
    }

    // we're going to position the attribute relative to the block, so it needs
    // to have a position attributed
    block.setAttribute('style', 'position: relative')
    const isAddition = type === 'changed'
    const revType = isAddition ? 'revised' : 'removed'
    // create a span with + or - and give the appropriate classes
    const asterisk = document.createElement('span')
    asterisk.setAttribute('class', `o-revision o-revision__${revType}`)
    // positioning:
    // For the top, we use the prosemirror-discovered offset from the parent block.
    // Horizontally, we want to get the asterisk over to the right gutter. We need to
    // account for the parent block's indentation
    const parentLeft = block.getBoundingClientRect().left
    const asteriskLeft = horizontalPosition - parentLeft
    asterisk.setAttribute(
      'style',
      `top: ${blockYOffset}px;left: ${asteriskLeft}px;`,
    )
    block.appendChild(asterisk)
  })
}

export const removeAsteriskNodes = () => {
  const asterisks = document.querySelectorAll('.o-revision')
  asterisks.forEach((a) => a.remove())
}
