import React from 'react'

import { Stack } from '@mantine/core'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { DevToolbar, EditorToolbar } from '@components/EditorToolbar'
import { GutterComments } from '@components/GutterComments'
import { GutterLeft } from '@components/GutterLeft'
import { ScriptMiniHeader } from '@components/MiniHeader'
import { PrompterView } from '@components/PrompterView'
import { ScriptStatusBar } from '@components/ScriptStatusBar'
import { HyperlinkPopover } from '@components/SelectionPopovers'
import { ScriptToastHost } from '@components/Toast'
import { useNavigation } from '@hooks'
import { InlineInkStyles } from '@ink/components'
import { ILoadedScript, useMst } from '@state'
import { scrollToBlock } from '@util/scrolling'

import { CommentsPanel } from './CommentsPanel'
import { InlineStyles } from './InlineStyles'
import { ScriptWrapper } from './ScriptWrapper'

import styles from './Script.module.scss'

export const Script = observer(function Script({
  script,
}: {
  script: ILoadedScript
}) {
  const { currentInkProject, currentOrg, socketManager, user, view } = useMst()

  const { removeBlockParam } = useNavigation()
  const pageless = user.prefs.pageless || script.isInk
  const noWrap = script.isInk && !user.inkPreferences?.lineWrap

  const isCreatingComment = script.pmEditor.hasUnsavedComment
  const [pmHost, pmHostRef] = React.useState<HTMLDivElement | null>(null)

  // don't show script until we have the right styles loaded. This hidden flag
  // ONLY makes the script invisible, doesn't hide any error toasts/loaders
  const hideScript = !script
  const classes = cn(styles.pmHostWrapper, {
    [styles.safelyHidden]: hideScript,
    [styles.disconnected]:
      !socketManager.connected || script.syncStatus.isTooStaleForSafety,
    [styles.isCreatingComment]: isCreatingComment,
    [styles.emptySelection]: !!script.pmEditor.selection?.empty,
  })

  // if you land on this page with a block param (ie: ?block=<blockId>) then once
  // the script is ready, we remove the query param from the URL and scroll to the block
  React.useEffect(() => {
    if (!hideScript) {
      const blockId = removeBlockParam()
      if (blockId) {
        scrollToBlock(blockId)
      }
    }
  }, [hideScript, removeBlockParam])

  React.useLayoutEffect(() => {
    const liveEditorView = script.pmEditor.editorManager?.view
    if (pmHost) {
      if (!liveEditorView || liveEditorView.dom.parentNode !== pmHost) {
        script.launchEditor(pmHost)
      }
    } else if (!pmHost && liveEditorView) {
      script.tearDownEditor()
    }
  })

  // When switching view modes or view sizes, trigger prosemirror re-render
  // so things like avatar and comment positioning get updated
  React.useEffect(() => {
    script.pmEditor.rerender()
  }, [
    view.dimensions.scriptScroller.height,
    view.dimensions.scriptScroller.width,
    view.editorZoom,
    pageless,
    noWrap,
    script.pmEditor,
  ])

  return (
    <Stack gap={0}>
      {script.isInk && currentInkProject && (
        <InlineInkStyles
          messages={currentInkProject.compilerMessagesForScript(script.id)}
        />
      )}
      <InlineStyles script={script} />
      <ScriptMiniHeader />
      {currentOrg && <EditorToolbar script={script} org={currentOrg} />}
      <DevToolbar script={script} />
      <ScriptWrapper
        pageCount={script.pageCount ?? 1}
        pageless={pageless}
        noWrap={noWrap}
        zoomLevel={view.editorZoom}
        commentsPanel={<CommentsPanel script={script} />}
        blockFormats={script.blockFormats}
        paginationType={script.paginationType}
      >
        <div id="pm-host-wrapper" className={classes}>
          <div className="c-editor">
            <div className="c-editor__gutterleft" id="editor-gutter-left"></div>
            <div className="c-editor__overlay"></div>
            <div className="l-box l-box--column c-editor-banner">
              <div id="editor" ref={pmHostRef}></div>
            </div>
            <div
              id="editor-gutter-right"
              className="c-editor__gutterright"
            ></div>
            <GutterLeft script={script} />
            {script.canAddComments && <GutterComments script={script} />}
            <div data-blurred-cursor className={styles.blurredCursor} />
          </div>
        </div>
        {script.showPrompterView && <PrompterView />}
        {!script.isInk && <HyperlinkPopover />}
      </ScriptWrapper>
      <ScriptStatusBar />
      <ScriptToastHost />
    </Stack>
  )
})
