import { Portal } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { ILoadedScript } from '@state'

import { Collaborators } from './Collaborators'
import { ElementNumbers } from './ElementNumbers'

export const GutterLeft = observer(function GutterLeft({
  script,
}: {
  script: ILoadedScript
}) {
  return (
    <Portal target="#editor-gutter-left">
      <ElementNumbers script={script} />
      <Collaborators script={script} />
    </Portal>
  )
})
