import React from 'react'

import { format } from 'date-fns'
import { useSearch } from 'wouter/use-location'

import { UnknownErrorPage } from '@components/ErrorPage'
import { showError } from '@components/Modals'
import { useMst } from '@state'
import { createProsemirrorDoc, extractSleneFragment, listSlenes } from '@util'
import { buildPrintableDiffHtml } from '@util/princePrinting'
import { getBlobAndPrint, getBlobAndSave } from '@util/printing'

import { SnapshotPage } from '../SnapshotPage'
import { useScriptData, useSnapshotData } from '../useSnapshotQueries'

import { showSelectSlenes, SleneItem } from './SelectSlenesModal'
import { SideBySideDiff } from './SideBySideDiff'
import { SideBySideViewMenu } from './SideBySideViewMenu'
import { SlenePickerToolbarButton } from './SlenePickerToolbarButton'

const SNAPSHOT_DATE_FMT = "MMM d, yyyy' - 'h:mm aaa"
const FETCHED_AT_DATE_FMT = "'as of 'h:mm aaa"

const parseLinesOfContext = (
  queryParamValue: string | undefined,
): number | undefined => {
  if (typeof queryParamValue === 'string') {
    const intValue = parseInt(queryParamValue)
    if (intValue >= 0 && intValue < 100) {
      return intValue
    }
  }
}

export const SideBySideDiffPage = ({
  params,
}: {
  params: {
    scriptId: string
    snapshotId: string
  }
}) => {
  const mst = useMst()
  // ensures that the component redraws when url search params are updated
  // we could parse the string this hook returns, but our own helper below is handier
  useSearch()

  const linesOfContext = parseLinesOfContext(
    mst.location.getQueryParam('context'),
  )
  const { scriptId, snapshotId } = params
  const {
    isError: isScriptError,
    isLoading: isScriptLoading,
    data: scriptPayload,
  } = useScriptData(scriptId)

  const {
    isError: isSnapshotError,
    isLoading: isSnapshotLoading,
    data: snapshotPayload,
  } = useSnapshotData({ snapshotId, scriptId })

  const leftPmDoc = snapshotPayload
    ? createProsemirrorDoc(snapshotPayload?.doc)
    : null

  const rightPmDoc = scriptPayload
    ? createProsemirrorDoc(scriptPayload.script.doc)
    : null

  const leftSleneItems: SleneItem[] = leftPmDoc ? listSlenes(leftPmDoc) : []
  const rightSleneItems: SleneItem[] = rightPmDoc ? listSlenes(rightPmDoc) : []

  const [monochrome, setMonochrome] = React.useState(false)
  const printRef = React.useRef<HTMLDivElement>(null)

  const fileName = scriptPayload?.script.name ?? 'Side-by-side comparison'

  const isScreenplay = scriptPayload?.script.doc.attrs.docType === 'screenplay'
  const [leftSlene, setLeftSlene] = React.useState<SleneItem | null>(null)
  const [rightSlene, setRightSlene] = React.useState<SleneItem | null>(null)
  const [isPrinting, setIsPrinting] = React.useState(false)

  const handleCreatePdf = async (type: 'print' | 'download') => {
    setIsPrinting(true)
    try {
      await mst.doDebug()
      const html = printRef.current?.outerHTML ?? ''
      const headerHtml =
        printRef.current?.querySelector('.sbs-diff_titlerow')?.outerHTML ?? ''

      if (html && snapshotPayload) {
        const title = `${fileName}.pdf`

        const downloadFn = () => {
          const diffHtml = buildPrintableDiffHtml({
            title,
            html,
            headerHtml,
            prefs: {
              ...mst.user.scriptPrintPrefs,
              headers: { enabled: true, showOnFirstPage: true, slots: {} },
            },
            snapshot: snapshotPayload,
          })

          return mst.apiClient.passThroughPrint({
            html: diffHtml,
            fileName,
            unwatermark: mst.view.shouldUnwatermark,
          })
        }
        if (type === 'print') {
          await getBlobAndPrint(downloadFn)
          mst.analytics.track(mst.analytics.EVENTS.SIDE_BY_SIDE_PRINTED)
        } else {
          await getBlobAndSave({ downloadFn, fileName })
          mst.analytics.track(mst.analytics.EVENTS.SIDE_BY_SIDE_EXPORTED)
        }
      }
    } catch (e) {
      const message = 'Failed to ' + (type === 'print' ? 'print' : 'create PDF')
      showError(message)
    } finally {
      setIsPrinting(false)
    }
  }

  const handlePickSlenes = ({
    left,
    right,
  }: {
    left: SleneItem | null
    right: SleneItem | null
  }) => {
    setLeftSlene(left)
    setRightSlene(right)
  }

  const showSlenePickerModal = () => {
    showSelectSlenes({
      leftItems: leftSleneItems,
      rightItems: rightSleneItems,
      selectedLeftId: leftSlene?.id,
      selectedRightId: rightSlene?.id,
      sleneType: isScreenplay ? 'scene' : 'slug',
      onSelect: handlePickSlenes,
    })
  }

  const scriptFetchedAt = scriptPayload
    ? format(scriptPayload.fetchedAt, FETCHED_AT_DATE_FMT)
    : ''
  const snapshotTimestamp = snapshotPayload
    ? format(new Date(snapshotPayload.createdAt), SNAPSHOT_DATE_FMT)
    : ''
  const snapshotName = snapshotPayload?.name ?? 'Snapshot'

  const leftFragment =
    leftPmDoc && leftSlene
      ? extractSleneFragment(leftPmDoc, leftSlene.id)
      : leftPmDoc?.content
  const rightFragment =
    rightPmDoc && rightSlene
      ? extractSleneFragment(rightPmDoc, rightSlene.id)
      : rightPmDoc?.content

  return (
    <SnapshotPage
      loading={isScriptLoading || isSnapshotLoading || isPrinting}
      {...params}
    >
      <SnapshotPage.Toolbar
        activeTab="diff"
        scriptId={scriptId}
        snapshotId={snapshotId}
        viewMenuItems={
          <SideBySideViewMenu
            monochrome={monochrome}
            setMonochrome={setMonochrome}
            linesOfContext={linesOfContext}
          />
        }
        onPrint={() => handleCreatePdf('print')}
        onDownloadPdf={() => handleCreatePdf('download')}
        slenePicker={
          <SlenePickerToolbarButton
            sleneType={isScreenplay ? 'scene' : 'slug'}
            isComparing={!!(leftSlene || rightSlene)}
            onClick={showSlenePickerModal}
            disabled={leftSleneItems.length + rightSleneItems.length === 0}
          />
        }
      />

      {(isScriptError || isSnapshotError) && <UnknownErrorPage />}
      {leftFragment && rightFragment && (
        <SideBySideDiff
          ref={printRef}
          leftTitle={`${snapshotName} - ${snapshotTimestamp}`}
          leftFragment={leftFragment}
          rightFragment={rightFragment}
          rightSubtitle={scriptFetchedAt}
          linesOfContext={linesOfContext}
          monochrome={monochrome}
          leftSleneName={leftSlene?.label}
          rightSleneName={rightSlene?.label}
        />
      )}
    </SnapshotPage>
  )
}
