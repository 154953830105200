import * as betaFlags from './betaFlags'
import * as comments from './comments'
import * as exportz from './exportz'
import * as listings from './listings'
import * as miscellaneous from './miscellaneous'
import * as orgs from './orgs'
import * as rundowns from './rundowns'
import * as scripts from './scripts'
import * as staff from './staff'
import * as steps from './steps'
import * as stytch from './stytch'
import { ApiConfig } from './types'

export type { ApiConfig }

// These are used by the codegen script that creates
// ScriptoApiClient.ts
export const API_CONFIGS = {
  ...betaFlags,
  ...comments,
  ...exportz,
  ...listings,
  ...miscellaneous,
  ...orgs,
  ...rundowns,
  ...scripts,
  ...staff,
  ...steps,
  ...stytch,
}
