import { Group, Portal, Text, UnstyledButton } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { ILoadedScript } from '@state'

import { CreateThreadButton } from './CreateThreadButton'
import { usePositionedCommentCounts } from './hooks'

import styles from './GutterComments.module.scss'

const CommentCount = ({
  top,
  count,
  onClick,
}: {
  top: number
  count: number
  commentIds: string[]
  onClick?: () => void
}) => {
  return (
    <UnstyledButton
      className={styles.commentCount}
      onClick={onClick}
      style={{ top }}
    >
      <Group gap={2} c="gray.6">
        <FaIcon size="12" icon="fa-comment" />
        <Text size="14" fw="bold">
          {count}
        </Text>
      </Group>
    </UnstyledButton>
  )
}

export const GutterComments = observer(function GutterComments({
  script,
}: {
  script: ILoadedScript
}) {
  const { canAddComment, hasUnsavedComment } = script.pmEditor
  const { blockSummaries, handleClick } = usePositionedCommentCounts(script)

  return (
    <Portal target="#editor-gutter-right">
      {canAddComment && !hasUnsavedComment && (
        <CreateThreadButton script={script} />
      )}
      {blockSummaries.map((summary) => {
        return (
          <CommentCount
            key={summary.blockId}
            {...summary}
            onClick={() => handleClick(summary)}
          />
        )
      })}
    </Portal>
  )
})
