import React from 'react'

import { PDFDocument } from 'pdf-lib'
import printJS from 'print-js-updated'
import sanitizeFilename from 'sanitize-filename'

import { IRoot, useMst } from '@state'
import { delay, saveBlobToFile } from '@util'
import { chunkPromiseAll } from '@util/chunking'
import { getPdfBlobForScript } from '@util/printing'
import { RundownRowData } from '@util/ScriptoApiClient/types'

const MAX_DOCRAPTOR_CONCURRENCY = 10

export type PrintableScriptData = {
  scriptId: string
  title: string
  rundownRowData?: RundownRowData
}

type PdfBlobResult =
  | { success: true; blob: Blob }
  | { success: false; data: PrintableScriptData }

const getPdfBlobOrError = async (
  data: PrintableScriptData,
  mst: IRoot,
): Promise<PdfBlobResult> => {
  try {
    const blob = await getPdfBlobForScript({ ...data, mst })
    return {
      success: true,
      blob,
    }
  } catch {
    return {
      success: false,
      data,
    }
  }
}

export const usePrintScripts = () => {
  const mst = useMst()
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const fetchBlobs = async (data: PrintableScriptData[]) => {
    const fetchFns: Array<() => Promise<PdfBlobResult>> = data.map(
      (opts) => async () => getPdfBlobOrError(opts, mst),
    )
    return chunkPromiseAll(fetchFns, MAX_DOCRAPTOR_CONCURRENCY)
  }

  const mergePdfs = async (
    data: PrintableScriptData[],
  ): Promise<{
    mergedPdf: Blob
    failures: PrintableScriptData[]
  }> => {
    if (data.length === 1) {
      return {
        failures: [],
        mergedPdf: await getPdfBlobForScript({ ...data[0], mst }),
      }
    }

    const failures: PrintableScriptData[] = []
    const mergedDoc = await PDFDocument.create()
    const blobResults = await fetchBlobs(data)
    for (const result of blobResults) {
      if (result.success) {
        const buffer = await new Response(result.blob).arrayBuffer()
        // Load a PDFDocument from each of the existing PDFs
        const pdf = await PDFDocument.load(new Uint8Array(buffer))
        const pages = await mergedDoc.copyPages(pdf, pdf.getPageIndices())
        pages.forEach((page) => mergedDoc.addPage(page))
      } else {
        failures.push(result.data)
      }
    }

    const pdfUint8 = await mergedDoc.save()
    const mergedPdf = new Blob([pdfUint8], {
      type: 'application/octet-stream',
    })

    return {
      mergedPdf,
      failures,
    }
  }

  const print = async ({
    fileName,
    type,
    printableItems,
  }: {
    fileName: string
    type: 'print' | 'download'
    printableItems: PrintableScriptData[]
  }) => {
    setErrorMessage('')
    setLoading(true)

    try {
      await mst.doDebug()
      const result = await mergePdfs(printableItems)
      if (result.failures.length > 0) {
        mst.log.error('multi-print failure', {
          printableItems,
          failed: result.failures,
        })
        setErrorMessage(
          `Partial failure: ${result.failures.length} script(s) omitted`,
        )
      }

      if (type === 'download') {
        saveBlobToFile({
          blob: result.mergedPdf,
          fileName: sanitizeFilename(fileName),
        })
      } else {
        await delay(1)
        const href = URL.createObjectURL(result.mergedPdf)
        printJS({ printable: href, type: 'pdf' })
        URL.revokeObjectURL(href)
      }
      setLoading(false)
      return result.failures.length === 0
    } catch {
      setErrorMessage('Error printing scripts')
      setLoading(false)
      return false
    }
  }
  return {
    loading,
    errorMessage,
    print,
  }
}
