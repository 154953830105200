import { Text, Tooltip } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { Toolbar } from '@components/Toolbar'
import { showCustomizeFormatModal } from '@debug/CustomizeScriptFormat'
import { useMonkeyTyping } from '@debug/useMonkeyTyping'
import { ILoadedScript, useMst } from '@state'

import styles from './EditorToolbar.module.scss'

const getOverideFormatOptions = ({
  isStructural,
}: {
  isStructural: boolean
  nidoBetaEnabled: boolean
}): { disabled: boolean; tooltip: string } => {
  if (isStructural) {
    return {
      disabled: true,
      tooltip: 'Override script format (disabled for structural scripts)',
    }
  }

  return {
    disabled: false,
    tooltip: 'Override script format',
  }
}

export const DevToolbar = observer(function DevToolbar({
  script,
}: {
  script: ILoadedScript
}) {
  const { user, view, scrapi } = useMst()
  const { isTyping, toggleTyping } = useMonkeyTyping(script)

  const displayToolbar = user.staff && user.prefs.showDebugToolbar
  if (!displayToolbar) {
    return null
  }

  const hideToolbar = () => {
    user.updatePreferences({ showDebugToolbar: false })
  }

  const isStructural =
    script.pmEditor.configData?.paginationType === 'structural'

  const overrideFormatOpts = getOverideFormatOptions({
    isStructural,
    nidoBetaEnabled: view.useNidoBeta,
  })

  const convertToInline = async () => {
    const result = await scrapi.scripts.convertToInline({
      params: { id: script.id },
      body: {},
    })
    if (result.status === 200) {
      return
    }
    if (result.status === 420 && result.body.retryable) {
      alert(result.body.description + ' ... try again.')
    } else {
      alert('Unexpected failure, check console')
      // eslint-disable-next-line no-console
      console.error(result.body)
    }
  }

  const monkeyTooltip = isTyping
    ? 'Click to stop monkey typing'
    : 'Click to make a monkey start typing stuff in your script'

  return (
    <div className={styles.toolbar}>
      <Toolbar bg="yellow.0">
        <Toolbar.Section position="left">
          <Toolbar.Button
            tooltip={overrideFormatOpts.tooltip}
            icon="fa-stars"
            onClick={() => showCustomizeFormatModal(script)}
            disabled={overrideFormatOpts.disabled}
          />
          <Toolbar.Button
            tooltip="Convert script to inline"
            icon="fa-file-dashed-line"
            onClick={convertToInline}
            disabled={!isStructural}
          />
          <Toolbar.Button
            tooltip={monkeyTooltip}
            icon="fa-monkey"
            onClick={toggleTyping}
            active={isTyping}
            disabled={!script.isEditable}
          />
        </Toolbar.Section>
        <Toolbar.Section position="right">
          <Tooltip position="bottom" label="Current script pagination type">
            <Text size="sm" fw="bold" pr={10}>
              {script.pmEditor.configData?.paginationType}
            </Text>
          </Tooltip>
          <Toolbar.Button
            tooltip="Hide debug toolbar"
            icon="fa-xmark"
            onClick={hideToolbar}
          />
        </Toolbar.Section>
      </Toolbar>
    </div>
  )
})
