/*
this sucker is more or less copy/pasta of the docInfo plugin
juggling separate plugins helps us avoid rerending unrelated UI
when this highly churny state is recalculated
*/

import deepEqual from 'fast-deep-equal'
import { EditorState, Plugin, PluginKey } from 'prosemirror-state'

import { PluginFactory } from '../types'

import { extractNavLinks } from './extractNavLinks'
import { NavLinksState } from './types'

export const navLinksPluginKey = new PluginKey<NavLinksState>('navLinks')

export const navLinkFactory: PluginFactory = (): Plugin<NavLinksState> => {
  const getPluginState = ({ doc }: EditorState): NavLinksState => {
    return extractNavLinks(doc)
  }

  return new Plugin<NavLinksState>({
    key: navLinksPluginKey,
    state: {
      init(editorState: EditorState) {
        return getPluginState(editorState)
      },
      apply(tr, pluginState, oldState, newState) {
        if (oldState.doc.eq(newState.doc)) {
          return pluginState
        }

        const newPluginState = getPluginState(newState)

        // Only update the state when the value has changed to avoid unnecessary
        // rerendering of the same values
        return deepEqual(newPluginState, pluginState)
          ? pluginState
          : newPluginState
      },
    },
  })
}
