import { Node as PmNode } from 'prosemirror-model'

import { INLINE_PAGE_BREAK, NodeTypeMap } from '@showrunner/codex'
import { schemas, ZInfer } from '@showrunner/scrapi'

import { DatadogClient } from '@util/datadog'
import { BlockFormats, FormatBlockName } from '@util/types'

type DualFormatBlockConfig = ZInfer<
  typeof schemas.scriptFormats.DualBlockConfig
>

const ddLog = DatadogClient.getInstance()

export const isManualBreak = (node: PmNode) =>
  node.attrs.pageBreak === INLINE_PAGE_BREAK.manual

// Info we can determine by looking at a 2nd level node and
// the script format
type DirectBlockInfo = {
  blockId: string
  blockType: string
  lineHeight: number
  spaceAbove: number
  manualBreak: boolean
}

// Combine the previous with doc position info so we can
// add decorations or mutate in appendTransaction
export type PositionedBlockInfo = DirectBlockInfo & {
  startPos: number
  nodeSize: number
}

// We want to get the script format config for a block. Our prosemirror schema
// isn't typed so we swap in some reasonable defaults and log an error if
// we can't resolve a config (it indicates a bug)
type ConfigData = {
  width: number
  lineHeight: number
  blockTopMargin: number
}
const DEFAULT_CONFIG: ConfigData = {
  width: 50,
  lineHeight: 1,
  blockTopMargin: 0,
}
const getBlockConfig = (
  node: PmNode,
  blockFormats: BlockFormats,
  inDual?: boolean,
): ConfigData => {
  const config: ValueOf<BlockFormats> | undefined =
    blockFormats[node.type.name as FormatBlockName]

  if (!config) {
    ddLog.errorOnce(
      'Failed to find block config',
      {
        topic: 'inlinePagination',
        nodeType: node.type.name,
        inDual,
      },
      'block-config-lookup',
    )
  }

  const forcedConfig = config ?? DEFAULT_CONFIG

  if (!inDual) {
    return forcedConfig
  }

  const result = (forcedConfig as DualFormatBlockConfig).dualDialogOverrides

  if (!result) {
    ddLog.errorOnce(
      'Failed to find dual format config',
      { type: node.type.name, topic: 'inlinePagination' },
      'dual-block-config-lookup',
    )
  }
  return result ?? { ...DEFAULT_CONFIG, width: DEFAULT_CONFIG.width / 2 }
}

// Count the lines of text (including hard breaks) for a Textblock
const getTextblockHeightInfo = (
  node: PmNode,
  blockFormats: BlockFormats,
  inDual?: boolean,
): DirectBlockInfo => {
  const config = getBlockConfig(node, blockFormats, inDual)

  // for each section of text without hard breaks,
  // add up the # of characters and push in this array
  const textSegments: number[] = [0]
  let lineCount = 0
  node.forEach((inlineNode) => {
    if (inlineNode.text) {
      const segmentIndex = textSegments.length - 1
      textSegments[segmentIndex] =
        textSegments[segmentIndex] + inlineNode.text.length
    } else if (inlineNode.type.name === NodeTypeMap.HARD_BREAK) {
      lineCount += 1
      textSegments.push(0)
    }
  })
  textSegments.forEach((charCount) => {
    lineCount += Math.ceil(charCount / config.width)
  })

  // If we have a line count of 0, we still need to account for the
  // blank line
  const effectiveLineCount = Math.max(lineCount, 1)
  const lineHeight = effectiveLineCount * config.lineHeight

  return {
    blockId: node.attrs.id,
    blockType: node.type.name,
    manualBreak: isManualBreak(node),
    lineHeight,
    spaceAbove: config.blockTopMargin,
  }
}

// we measure individual text blocks OR dual dialogue sections. This takes a
// level 2 node and returns either the single textblock or the array of textblocks
// in each column of dual dialogue
type MeasureableBlock = PmNode | [PmNode[], PmNode[]]
const breakDownToMeasurable = (node: PmNode): MeasureableBlock => {
  if (node.type.name === NodeTypeMap.DUAL_DIALOGUE) {
    const column1: PmNode[] = []
    const column2: PmNode[] = []
    node.forEach((column, _, index) => {
      column.forEach((node) => {
        if (index === 0) {
          column1.push(node)
        } else {
          column2.push(node)
        }
      })
    })
    return [column1, column2]
  }
  return node
}

// adds together heights of an array of blocks, throwing
// away the space above for the first block of the page
// or dual dialogue column
export const addBlockHeights = (
  items: Array<{ lineHeight: number; spaceAbove: number }>,
): number => {
  return items.reduce((cumulative, block, index) => {
    const currentBlockHeight =
      index === 0 ? block.lineHeight : block.lineHeight + block.spaceAbove
    return cumulative + currentBlockHeight
  }, 0)
}

// for each depth 2 block in the script, we want the line height and space
// above. For dual dialogue, this means traversing into each column and summing
// the blocks.
export const getBlockHeightInfo = (
  node: PmNode,
  blockFormats: BlockFormats,
): DirectBlockInfo => {
  const measurable = breakDownToMeasurable(node)

  // not dual dialogue
  if (measurable instanceof PmNode) {
    return getTextblockHeightInfo(node, blockFormats)
  }

  // dual dialogue
  const column1Height = addBlockHeights(
    measurable[0].map((block) =>
      getTextblockHeightInfo(block, blockFormats, true),
    ),
  )
  const column2Height = addBlockHeights(
    measurable[1].map((block) =>
      getTextblockHeightInfo(block, blockFormats, true),
    ),
  )

  const lineHeight = Math.max(column1Height, column2Height)
  return {
    blockId: node.attrs.id,
    blockType: NodeTypeMap.DUAL_DIALOGUE,
    manualBreak: isManualBreak(node),
    lineHeight,
    spaceAbove: blockFormats.dual_dialogue.blockTopMargin,
  }
}

// There are three widgets that can go below a page and we need them
// to be in the correct order. At the end of the doc, ensureNewline
// should be ABOVE bottom of page (or there will be a gap). Between
// pages, bottom of page needs to go ABOVE top of (the next) page.
export const PAGE_WIDGET_ORDER = {
  ensureNewline: 0,
  bottomOfPage: 1,
  topOfPage: 2,
}

export const isCharacterFollower = (blockInfo: DirectBlockInfo) => {
  return (
    blockInfo.blockType === NodeTypeMap.DIALOGUE ||
    blockInfo.blockType === NodeTypeMap.PARENTHETICAL
  )
}

export const extractBlockInfo = ({
  doc,
  blockFormats,
}: {
  doc: PmNode
  blockFormats: BlockFormats
}): PositionedBlockInfo[] => {
  const orderedBlocks: PositionedBlockInfo[] = []
  doc.descendants((node, pos, parent) => {
    if (parent.type.name === NodeTypeMap.PAGE) {
      const blockData: PositionedBlockInfo = {
        ...getBlockHeightInfo(node, blockFormats),
        startPos: pos,
        nodeSize: node.nodeSize,
      }

      orderedBlocks.push(blockData)

      // don't traverse below depth 2
      return false
    }
  })
  return orderedBlocks
}
