import { history } from 'prosemirror-history'

import { baseKeymapPlugin } from './base-keymap.js'
import { characterAutocompletePlugin } from './character-autocomplete.js'
import { collabUpdatePlugin } from './collab-update'
import { commentsPlugin } from './comments/index'
import { configDataPlugin } from './configData'
import { decorateSelectionPlugin } from './decorateSelection'
import { docInfoFactory } from './doc-info'
import { editorKeymapPlugin } from './editor-keymap'
import { ensureNewlinePlugin } from './ensure-newline'
import { idMakerPlugin } from './id-maker.js'
import { indentationStylesFactory } from './indentation-styles'
import { inkInputRulesPlugin } from './ink-input-rules'
import { inkKeymapPlugin } from './ink-keymap'
import { inlineManualPageBreaksPlugin } from './inlineManualPageBreaks'
import { inlinePageBreaksPlugin } from './inlinePageBreaks'
import { inputRulesPlugin } from './input-rules.js'
import { navLinkFactory } from './nav-links'
import { pageBreakerPlugin } from './page-breaker'
import { placeholderPlugin } from './placeholder.js'
import { recordDomPositions } from './recordDomPositions'
import { remoteCollaboratorsPlugin } from './remoteCollaborators'
import { removeStructuralPaginationPlugin } from './removeStructuralPagination'
import { syntaxHighlightingFactory } from './syntax-highlighting'
import { EditorContext, EnabledCheck, PluginConfig } from './types'

const editableCheck: EnabledCheck = ({ script }) => script.isEditable

const combineChecks =
  (...checkFns: EnabledCheck[]): EnabledCheck =>
  (ctx: EditorContext) =>
    checkFns.every((fn) => fn(ctx))

const isInk: EnabledCheck = ({ script }) => script.isInk
const notInk: EnabledCheck = ({ script }) => !script.isInk

const isPageless: EnabledCheck = ({ mst }) => !!mst.user.prefs?.pageless
const isNotPageless: EnabledCheck = ({ mst }) => !mst.user.prefs?.pageless

export const configData: PluginConfig = {
  key: 'configData',
  plugin: configDataPlugin,
}

export const docInfo: PluginConfig = {
  key: 'docInfo',
  plugin: docInfoFactory,
}

export const navLinks: PluginConfig = {
  key: 'navLinks',
  plugin: navLinkFactory,
}

export const collabUpdate: PluginConfig = {
  key: 'collabUpdate',
  plugin: collabUpdatePlugin,
}

export const characterAutocomplete: PluginConfig = {
  key: 'characterAutocomplete',
  plugin: characterAutocompletePlugin,
  checkEnabled: editableCheck,
}

export const inputRules: PluginConfig = {
  key: 'inputRules',
  plugin: inputRulesPlugin,
  checkEnabled: notInk,
}

export const editorKeymap: PluginConfig = {
  key: 'editorKeymap',
  plugin: editorKeymapPlugin,
  checkEnabled: notInk,
}

export const baseKeymap: PluginConfig = {
  key: 'baseKeymap',
  plugin: baseKeymapPlugin,
}

export const ensureNewline: PluginConfig = {
  key: 'ensureNewline',
  plugin: ensureNewlinePlugin,
  checkEnabled: editableCheck,
}

export const pageBreaker: PluginConfig = {
  key: 'pageBreaker',
  plugin: pageBreakerPlugin,
  checkEnabled: editableCheck,
}

export const remoteCollaborators: PluginConfig = {
  key: 'remoteCollaborators',
  plugin: remoteCollaboratorsPlugin,
}

export const placeholder: PluginConfig = {
  key: 'placeholder',
  plugin: placeholderPlugin,
  checkEnabled: notInk,
}

export const idMaker: PluginConfig = {
  key: 'idMaker',
  plugin: idMakerPlugin,
  checkEnabled: editableCheck,
}

export const historyPlugin: PluginConfig = {
  key: 'history',
  plugin: () => history(),
  checkEnabled: editableCheck,
}

export const recordDomPositionsPlugin: PluginConfig = {
  key: 'recordDomPositions',
  plugin: recordDomPositions,
}

export const comments: PluginConfig = {
  key: 'comments',
  plugin: commentsPlugin,
  checkEnabled: ({ script }) => script.canAddComments,
}

export const inkKeymap: PluginConfig = {
  key: 'inkKeymap',
  plugin: inkKeymapPlugin,
  checkEnabled: combineChecks(editableCheck, isInk),
}

export const inkInputRules: PluginConfig = {
  key: 'inkInputRules',
  plugin: inkInputRulesPlugin,
  checkEnabled: combineChecks(editableCheck, isInk),
}

export const indentationStyles: PluginConfig = {
  key: 'detectIndentation',
  plugin: indentationStylesFactory,
  checkEnabled: isInk,
}

export const decorateSelection: PluginConfig = {
  key: 'decorateSelection',
  plugin: decorateSelectionPlugin,
}

export const syntaxHighlighting: PluginConfig = {
  key: 'syntaxHighlighting',
  plugin: syntaxHighlightingFactory,
  checkEnabled: isInk,
}

export const removeStructuralPagination: PluginConfig = {
  key: 'removeStructuralPagination',
  plugin: removeStructuralPaginationPlugin,
  checkEnabled: editableCheck,
}

export const inlineManualPageBreaks: PluginConfig = {
  key: 'inlineManualPageBreaks',
  plugin: inlineManualPageBreaksPlugin,
  checkEnabled: isPageless,
}

export const inlinePageBreaks: PluginConfig = {
  key: 'inlinePageBreaks',
  plugin: inlinePageBreaksPlugin,
  checkEnabled: isNotPageless,
}
