import { Center, Loader, Overlay } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { useMst } from '@state'

export const SleepOverlay = observer(function SleepOverlay() {
  const { appStatus } = useMst()
  if (!appStatus.suspended) {
    return null
  }

  return (
    <Overlay color="#000" backgroundOpacity={0.1} blur={5}>
      <Center h="100vh">{appStatus.resuming && <Loader />}</Center>
    </Overlay>
  )
})
