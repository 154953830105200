import { useState } from 'react'

import {
  ActionIcon,
  Button,
  Card,
  Code,
  CopyButton,
  Group,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core'
import { format } from 'date-fns'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { UnknownErrorPage } from '@components/ErrorPage'
import { FaIcon } from '@components/FaIcon'
import { showConfirmModal, showError } from '@components/Modals'
import { Spinner } from '@components/Spinner'
import { Toast } from '@components/Toast'
import { SHORT_DAY_MASK } from '@hooks'
import { saveTextToFile } from '@util'

import {
  useCreateApiCredential,
  useDeleteApiCredential,
  useFindApiCredentials,
} from './useApiCredentials'

import styles from './DevSettings.module.scss'

type ApiCredential = ZInfer<typeof schemas.ApiCredentials>[number]
const FULL_SECRET_BLANKS = new Array(44).fill('*').join('')
const SHORT_SECRET_BLANKS = '****'

const maskSecret = (secret: string, fullLengthMask: boolean) => {
  const lastFour = secret.slice(-4)
  const blanks = fullLengthMask ? FULL_SECRET_BLANKS : SHORT_SECRET_BLANKS
  return blanks + lastFour
}

const ApiKeyRow = ({
  credential,
  onDelete,
}: {
  credential: ApiCredential
  onDelete: () => void
}) => {
  const handleDelete = () => {
    showConfirmModal({
      title: 'Delete API key',
      children:
        'Are you sure you want to delete this API key? It will immediately stop working. This cannot be undone',
      dangerous: true,
      onConfirm: onDelete,
    })
  }

  return (
    <Table.Tr>
      <Table.Td>{credential.clientId}</Table.Td>
      <Table.Td>{maskSecret(credential.clientSecretLastFour, false)}</Table.Td>
      <Table.Td>{format(credential.createdAt, SHORT_DAY_MASK)}</Table.Td>
      <Table.Td>
        <Button variant="subtle" color="red" onClick={handleDelete}>
          Delete
        </Button>
      </Table.Td>
    </Table.Tr>
  )
}

const NewCredentialCard = ({
  clientId,
  clientSecret,
}: {
  clientId: string
  clientSecret: string
}) => {
  const [showSecret, setShowSecret] = useState(false)
  const toggleShow = () => setShowSecret(!showSecret)
  const displayedSecret = showSecret
    ? clientSecret
    : maskSecret(clientSecret, true)

  const downloadAsCsv = () => {
    const text = ['key, secret', `${clientId}, ${clientSecret}`, ''].join('\n')
    const fileName = 'Scripto-API-credentials.csv'
    saveTextToFile({ text, fileName })
  }

  return (
    <>
      <Title order={3}>Your new API key and secret</Title>
      <Card className={styles.devSettings_card}>
        <Stack gap="lg">
          <Toast
            type={'neutral'}
            dismissable={false}
            message="You've created a new API key. This is the only time you'll be able to view your secret, so download it or copy it to a safe location now."
          ></Toast>
          <Stack gap={5}>
            <Text fw="bold">API Key</Text>
            <Group
              className={styles.devSettings_codeGroup}
              justify="space-between"
            >
              <Code>{clientId}</Code>
              <Group className={styles.devSettings_actionGroup}>
                <CopyButton value={clientId} timeout={2000}>
                  {({ copied, copy }) => (
                    <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow>
                      <ActionIcon
                        color={copied ? 'green' : 'gray'}
                        variant="subtle"
                        onClick={copy}
                        size="15"
                        className={styles.devSettings_actionIcon}
                      >
                        {copied ? (
                          <FaIcon icon="fa-check" />
                        ) : (
                          <FaIcon icon="fa-copy" />
                        )}
                      </ActionIcon>
                    </Tooltip>
                  )}
                </CopyButton>
              </Group>
            </Group>
          </Stack>
          <Stack gap={5}>
            <Text fw="bold">Secret</Text>
            <Group
              className={styles.devSettings_codeGroup}
              justify="space-between"
            >
              <Code className={styles.devSettings_secret}>
                {displayedSecret}
              </Code>
              <Group className={styles.devSettings_actionGroup} gap={5}>
                <Tooltip label="Show" withArrow>
                  <ActionIcon
                    className={styles.devSettings_actionIcon}
                    variant="subtle"
                    onClick={toggleShow}
                  >
                    <FaIcon icon="fa-eye" />
                  </ActionIcon>
                </Tooltip>
                <CopyButton value={clientSecret} timeout={2000}>
                  {({ copied, copy }) => (
                    <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow>
                      <ActionIcon
                        color={copied ? 'green' : 'gray'}
                        variant="subtle"
                        onClick={copy}
                        size="15"
                        className={styles.devSettings_actionIcon}
                      >
                        {copied ? (
                          <FaIcon icon="fa-check" />
                        ) : (
                          <FaIcon icon="fa-copy" />
                        )}
                      </ActionIcon>
                    </Tooltip>
                  )}
                </CopyButton>
              </Group>
            </Group>
          </Stack>
          <Group justify="flex-end">
            <Button
              variant="subtle"
              color="purple"
              leftSection={<i className="fa-solid fa-download" />}
              onClick={downloadAsCsv}
            >
              Download .CSV
            </Button>
          </Group>
        </Stack>
      </Card>
    </>
  )
}

export const DevSettings = () => {
  const findCredentialsResult = useFindApiCredentials()
  const { foundCredentials } = findCredentialsResult
  const deleteMutation = useDeleteApiCredential()
  const createMutation = useCreateApiCredential()
  const [freshClientId, setFreshClientId] = useState('')
  const [freshClientSecret, setFreshClientSecret] = useState('')

  const handleCreateCredential = async () => {
    try {
      const apiResult = await createMutation.mutateAsync()
      if (apiResult.status === 200) {
        const { clientId, clientSecret } = apiResult.body
        setFreshClientId(clientId)
        setFreshClientSecret(clientSecret)
      } else if (apiResult.status === 420) {
        showError({ message: apiResult.body.code })
      } else {
        showError({ message: 'Unknown server error' })
      }
    } catch {
      showError({ message: 'Unknown server error' })
    }
  }

  if (findCredentialsResult.isError) return <UnknownErrorPage />

  const showCard = freshClientId && freshClientSecret
  const showSpinner =
    findCredentialsResult.isLoading ||
    deleteMutation.isLoading ||
    createMutation.isLoading

  return (
    <Stack className={styles.devSettings}>
      {showSpinner && <Spinner delayMs={300} />}
      <Title order={1}>Developers</Title>
      <Stack gap={40}>
        <Stack gap="lg">
          {showCard && (
            <NewCredentialCard
              clientId={freshClientId}
              clientSecret={freshClientSecret}
            />
          )}
          <Group justify="space-between">
            <Title order={3}>API keys</Title>
            <Button
              leftSection={<i className="fa-solid fa-plus" />}
              onClick={handleCreateCredential}
            >
              New API key
            </Button>
          </Group>
          {foundCredentials.length > 0 && (
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>API key</Table.Th>
                  <Table.Th>Secret</Table.Th>
                  <Table.Th>Created</Table.Th>
                </Table.Tr>
              </Table.Thead>

              <Table.Tbody className={styles.apiKeyTable}>
                {foundCredentials.map((credential) => (
                  <ApiKeyRow
                    key={credential.clientId}
                    credential={credential}
                    onDelete={() => deleteMutation.mutate(credential.clientId)}
                  />
                ))}
              </Table.Tbody>
            </Table>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
