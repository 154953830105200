import { Plugin, PluginKey } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { NodeTypeMap } from '@showrunner/codex'

import { CursorCoords, IPmDomInfo, IRoot } from '@state/types'

const getOffsetCoords = (
  editorCoords: { top: number; left: number },
  absoluteCoords: Rect,
): CursorCoords => {
  const y = absoluteCoords.top - editorCoords.top
  const x = absoluteCoords.left - editorCoords.left
  const height = absoluteCoords.bottom - absoluteCoords.top

  return { x, y, height }
}

const recordDomPositionsPluginKey = new PluginKey('recordDomPositions')

export const recordDomPositions = ({ mst }: { mst: IRoot }): Plugin => {
  const recordSelection = (view: EditorView, pmDomInfo: IPmDomInfo) => {
    const viewCoords = view.dom.getBoundingClientRect()
    try {
      const { $anchor, $head } = view.state.selection
      const anchor = getOffsetCoords(viewCoords, view.coordsAtPos($anchor.pos))
      const head = getOffsetCoords(viewCoords, view.coordsAtPos($head.pos))
      pmDomInfo.setSelectionCoords({ anchor, head })
    } catch (err) {
      mst.log.errorOnce(
        'Could not compute selection coords',
        { err },
        'record-selection',
      )
    }
  }

  const recordBlockTops = (view: EditorView, pmDomInfo: IPmDomInfo) => {
    const viewTop = view.dom.getBoundingClientRect().top

    view.state.doc.descendants((node, pos, parent) => {
      const blockId = node.attrs.id
      if (
        parent.type.name === NodeTypeMap.PAGE &&
        typeof blockId === 'string'
      ) {
        try {
          const blockCoords = view.coordsAtPos(pos + 1)
          const blockTop = blockCoords.top - viewTop
          pmDomInfo.setBlockTop(blockId, blockTop)
        } catch (err) {
          mst.log.errorOnce(
            'Could not compute block top',
            { err, blockId },
            'record-selection-block-top',
          )
        }

        // don't recurse
        return false
      }
    })
  }

  return new Plugin({
    key: recordDomPositionsPluginKey,

    view(view) {
      const pmDomInfo = mst.currentScript?.pmDomInfo
      if (pmDomInfo && !view.isDestroyed) {
        recordSelection(view, pmDomInfo)
        recordBlockTops(view, pmDomInfo)
        mst.currentScript?.updateEditorViewObservables()
      }

      return {
        update(ev) {
          const pmDomInfo = mst.currentScript?.pmDomInfo
          if (!pmDomInfo || view.isDestroyed) {
            return
          }

          recordSelection(ev, pmDomInfo)
          recordBlockTops(ev, pmDomInfo)
          mst.currentScript?.updateEditorViewObservables()
        },
      }
    },
  })
}
