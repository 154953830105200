import { AxiosResponse } from 'axios'

import { templateSchema } from '@util/rundowns'
import type { RundownPayload } from '@util/ScriptoApiClient/types'

import type { ApiConfig } from './types'

// This is a quick hack to prototype new rundown schemas without
// needing to create them in the database-- we shim them into the
// response payload. Set this to true and tweak the template schema
// to force all rundowns to use the template
const USE_TEMPLATE_SCHEMA = false

const templateTransformer = (response: AxiosResponse): RundownPayload => ({
  ...response.data,
  schema: {
    ...response.data.schema,
    name: 'TEMPLATE_SCHEMA',
    schema: templateSchema,
  },
})

// note that importing noop from @util makes the codegen barf
const noop = () => {
  // noop
}

export const getRundown: ApiConfig<number | string, RundownPayload> = {
  buildRequest: (id) => ({
    method: 'GET',
    url: `/rundowns/${id}`,
  }),
  transformResponse: USE_TEMPLATE_SCHEMA ? templateTransformer : undefined,
}

export const moveRundown: ApiConfig<
  { rundownId: number; folderId: string },
  void
> = {
  buildRequest: ({ rundownId, folderId }) => ({
    method: 'PUT',
    url: `/rundowns/${rundownId}/move`,
    data: { folderId },
  }),
  transformResponse: noop,
}
