import React from 'react'

import { Button, Group, Space, Stack, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'

import { useMst } from '@state'
import { isEmailValid } from '@util'

import { OnboardingPage } from '../OnboardingPage'

import { TemplateCard } from './TemplateCard'
import {
  NewWorkspaceFormValues,
  TemplateWorkspaceListing,
  useActiveTemplates,
  useCreateWorkspaceMutation,
} from './util'

type ServerErrorCode = 'unknown'

export type NewWorkspaceProps = {
  onSubmit: (values: NewWorkspaceFormValues) => void
  userEmail: string
  serverError?: ServerErrorCode
  loading?: boolean
  phase: 'pick-template' | 'create-workspace'
  switchPhase: () => void
  templates: TemplateWorkspaceListing[]
  homeButtonLabel?: string
}

const serverErrorMessages: Record<ServerErrorCode, string | React.ReactNode> = {
  unknown: 'An unknown error occurred',
}

const optionalEmail = (v: string) =>
  v === '' || isEmailValid(v) ? null : 'Not a valid email'

export const NewWorkspace = ({
  onSubmit,
  userEmail,
  loading,
  serverError,
  phase,
  switchPhase,
  templates,
  homeButtonLabel,
}: NewWorkspaceProps) => {
  const form = useForm<NewWorkspaceFormValues>({
    validateInputOnBlur: true,
    initialValues: {
      templateCode: '',
      templateName: '',
      name: '',
      email: '',
      email2: '',
    },
    validate: {
      name: (value) => {
        if (phase === 'create-workspace' && value.length === 0) {
          return 'Value is required'
        }
      },
      email: optionalEmail,
      email2: optionalEmail,
    },
  })

  const errorMessage = serverError
    ? serverErrorMessages[serverError]
    : undefined

  const title =
    phase === 'pick-template'
      ? 'What kind of project are you working on?'
      : 'Create your workspace'

  return (
    <OnboardingPage title={title} loading={loading}>
      <OnboardingPage.Buttons
        includedButtons={['logout']}
        homeButtonLabel={homeButtonLabel}
      />
      <OnboardingPage.Form
        onSubmit={form.onSubmit(onSubmit)}
        errorMessage={errorMessage}
      >
        {phase === 'pick-template' && (
          <Stack gap={0}>
            {templates.map((t) => (
              <TemplateCard
                key={t.code}
                template={t}
                selected={form.values.templateCode === t.code}
                onSelect={() => {
                  form.setFieldValue('templateCode', t.code)
                  form.setFieldValue('templateName', t.name)
                }}
              />
            ))}
          </Stack>
        )}
        {phase === 'create-workspace' && (
          <>
            <TextInput
              size="md"
              label="Workspace name"
              {...form.getInputProps('name')}
            />
            <Text fw="bold">
              Invite collaborators by email <br />
              <Text size="sm" span fw={400}>
                (You can also do this later)
              </Text>
            </Text>
            <TextInput
              placeholder="laverne@shotz.com"
              {...form.getInputProps('email')}
            />

            <TextInput
              placeholder="shirley@shotz.com"
              {...form.getInputProps('email2')}
            />
          </>
        )}
        <Group justify="center">
          <Button
            onClick={switchPhase}
            variant={phase === 'create-workspace' ? 'outline' : 'filled'}
            disabled={phase === 'pick-template' && !form.values.templateCode}
          >
            {phase === 'create-workspace' ? 'Back' : 'Next'}
          </Button>
          {phase === 'create-workspace' && (
            <Button type="submit">Create Workspace</Button>
          )}
        </Group>
      </OnboardingPage.Form>
      <Stack align="center">
        <Space h="sm" />
        {/* high contrast 'dimmed' for dark backgrounds */}
        <Text fw={400} color="#a9a9ad">
          You&apos;re logged in as&nbsp;
          <Text size="sm" span fw="bold">
            {userEmail}
          </Text>
        </Text>
      </Stack>
      <OnboardingPage.Links types={['support']} />
    </OnboardingPage>
  )
}

export const NewWorkspaceRoute = () => {
  const { user, currentOrg } = useMst()
  const templatesQuery = useActiveTemplates()
  const createMutation = useCreateWorkspaceMutation()
  const loading = templatesQuery.isLoading || createMutation.isLoading
  const [phase, setPhase] =
    React.useState<NewWorkspaceProps['phase']>('pick-template')
  const togglePhase = () => {
    if (phase === 'pick-template') {
      setPhase('create-workspace')
    } else {
      setPhase('pick-template')
    }
  }

  return (
    <NewWorkspace
      serverError={createMutation.isError ? 'unknown' : undefined}
      onSubmit={createMutation.mutate}
      templates={templatesQuery.list}
      loading={loading}
      phase={phase}
      switchPhase={togglePhase}
      userEmail={user.email}
      homeButtonLabel={
        currentOrg?.name ? `Go to "${currentOrg.name}"` : undefined
      }
    />
  )
}
