import React from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'

import {
  LISTING_MAX_HEIGHT,
  LISTING_MAX_HEIGHT_PX,
} from '@components/FolderExplorer/constants'
import { useMst } from '@state'
import { IListing } from '@state/types'

import { DocumentListing } from './DocumentListing'

import styles from './DocumentList.module.scss'

class DocListingRenderer extends React.PureComponent<{
  data: IListing[]
  index: number
  style: React.CSSProperties
}> {
  render() {
    return (
      <div style={this.props.style}>
        <DocumentListing listing={this.props.data[this.props.index]} />
      </div>
    )
  }
}

// If there's an outstanding crosshairs request, there might be two selected
// doc. If not, there will only be one
const findMatchingDocIndex = (
  listings: IListing[],
  crosshairsDocId?: string,
) => {
  return listings.findIndex(({ id, isSelected }) => {
    const matchesCrosshairs = !crosshairsDocId || String(id) === crosshairsDocId
    return isSelected && matchesCrosshairs
  })
}

export const ListWrapper = ({
  children,
  scrollOverflow = false,
}: {
  children: React.ReactNode
  scrollOverflow?: boolean
}) => (
  <div
    className={cn(styles.wrapper, {
      [styles.wrapper__overflow]: scrollOverflow,
    })}
    style={{
      '--doc-listing-max-height': LISTING_MAX_HEIGHT_PX,
    }}
  >
    {children}
  </div>
)

export const DocumentList = observer(function DocumentList({
  documents,
  crosshairsDocId,
}: {
  documents: IListing[]
  crosshairsDocId?: string
}) {
  const { view, user } = useMst()

  // rerender if/when the sort order changes
  user.currentFolderSortOrder

  // for the list, we use state instead of ref because we need to trigger
  // an effect when the ref gets set
  const [list, setList] = React.useState<FixedSizeList | null>(null)
  // If a document was selected once the list mounts, we scroll to that item, then
  // set a state variable so we don't do this again. This handles any time a folder is
  // selected with a current document (e.g. on page load)
  const [hasAutoscrolled, setHasAutoscrolled] = React.useState(false)

  React.useEffect(() => {
    if (!hasAutoscrolled && list) {
      // If there's an outstanding crosshairs request, there might be two selected
      // doc. If not, there will only be one
      const targetIndex = findMatchingDocIndex(documents, crosshairsDocId)
      if (targetIndex > -1) {
        setHasAutoscrolled(true)
        view.explorerState.clearCrosshairsDocRequest()
        list.scrollToItem(targetIndex, 'smart')
      }
    }
  }, [crosshairsDocId, documents, hasAutoscrolled, list, view.explorerState])

  // if a crosshairs request lands, reset the hasAutoscrolled so
  // that if the item is in the list, we scroll to it
  React.useEffect(() => {
    if (crosshairsDocId && hasAutoscrolled) {
      setHasAutoscrolled(false)
    }
  }, [crosshairsDocId, hasAutoscrolled])

  return (
    <ListWrapper>
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            ref={(elt) => setList(elt)}
            height={height}
            itemCount={documents.length}
            itemSize={LISTING_MAX_HEIGHT}
            width={width}
            itemData={documents}
            overscanCount={20}
            itemKey={(index) => documents[index].id}
          >
            {DocListingRenderer}
          </FixedSizeList>
        )}
      </AutoSizer>
    </ListWrapper>
  )
})
