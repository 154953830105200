import { Plugin, PluginKey } from 'prosemirror-state'
import { DecorationSet } from 'prosemirror-view'

import { PluginFactory } from '../types'

import { createRemoteCursors } from './createRemoteCursors'

export const remoteCollaboratorsKey = new PluginKey('remoteCollaborators')

export const remoteCollaboratorsPlugin: PluginFactory = ({ mst }) => {
  return new Plugin<{
    decorations: DecorationSet
  }>({
    key: remoteCollaboratorsKey,
    state: {
      init() {
        return {
          decorations: DecorationSet.empty,
        }
      },
      apply(tr, pluginState, oldState, newState) {
        const collaborators = mst.currentScript?.collaborators
        // if the doc has changed, remap the positions for the
        // gutter avatars
        if (!oldState.doc.eq(newState.doc)) {
          collaborators?.forEach((c) => {
            c.applyMapping(tr.mapping)
          })
        }

        if (collaborators) {
          const decos = createRemoteCursors(collaborators)
          return {
            decorations: DecorationSet.create(tr.doc, decos),
          }
        }

        return pluginState
      },
    },
    props: {
      decorations(state) {
        if (mst.user.prefs.showCollabCursors) {
          return remoteCollaboratorsKey.getState(state).decorations
        }
        return DecorationSet.empty
      },
    },
    view() {
      return {
        update(view) {
          const collaborators = mst.currentScript?.collaborators
          const editorTop = view.dom.getBoundingClientRect().top
          collaborators?.forEach((collaborator) => {
            if (collaborator.startPosition) {
              try {
                const cursorTop = view.coordsAtPos(
                  collaborator.startPosition,
                ).top
                collaborator.setUnscaledOffsetTop(cursorTop - editorTop)
              } catch (e) {
                const { clientId, startPosition } = collaborator
                mst.log.errorOnce(
                  'invalid cursor position',
                  {
                    clientId,
                    startPosition,
                  },
                  'remote-collab',
                )
              }
            }
          })
        },
      }
    },
  })
}
