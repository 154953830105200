import { types } from 'mobx-state-tree'

import { extractTsRestSuccess } from '@util/extractTsRest'

import { ListingBase } from './ListingBase'

export const RundownListing = ListingBase.named('RundownListing')
  .props({
    modelType: types.frozen<'rundown'>('rundown'),
    id: types.identifierNumber,
    isEditingName: false,
    pendingLocationRequest: false,
  })
  .views((self) => ({
    get path() {
      return `/rundowns/${self.id}`
    },
    get isSelected(): boolean {
      return (
        self.rootStore.location.getPathParam('rundownId') === String(self.id)
      )
    },
  }))
  // sync actions
  .actions((self) => ({
    setFolder(folderId: string) {
      self.folderId = folderId
    },
    setIsEditingName(value: boolean) {
      self.isEditingName = value
    },
    setName(value: string) {
      self.name = value
    },
    setPendingLocationRequest(value: boolean) {
      self.pendingLocationRequest = value
    },
    trackModified() {
      self.analytics.trackDocModified('rundown', self.id)
    },
  }))
  // async actions
  .actions((self) => ({
    async moveToTrash() {
      await extractTsRestSuccess(
        self.legacyApi.moveRundownToTrash({
          params: { id: self.id },
          body: {},
        }),
        200,
      )
      // we're assuming that the rundown went to shared trash since
      // we don't support private rundowns. (The api is a little vague
      // about trash)
      const trashFolder = self.rootStore.rootFolders.sharedTrash
      if (trashFolder) {
        self.setFolder(trashFolder.id)
      }
    },

    async moveToFolder(folderId: string) {
      const { useNidoAlpha } = self.rootStore.view
      const fn = useNidoAlpha
        ? self.scrapi.rundowns.moveRundown
        : self.legacyApi.moveRundown
      await extractTsRestSuccess(
        fn({
          params: { id: self.id },
          body: {
            folderId,
          },
        }),
        200,
      )
      self.setFolder(folderId)
    },

    async rename(name: string) {
      const params = {
        params: { id: self.id },
        body: {
          name,
        },
      }
      await extractTsRestSuccess(
        self.scrapi.rundowns.updateRundown(params),
        200,
      )
      self.trackModified()
      self.setIsEditingName(false)
      self.setName(name)
      if (self.rootStore.currentRundown?.id === self.id) {
        self.rootStore.currentRundown.setName(name)
      }
    },

    async getAssociatedScriptCount(): Promise<number> {
      const { rows } = await self.rootStore.apiClient.getRundown(self.id)
      const scriptMap: { [key: string]: boolean } = {}
      rows.forEach(({ identityScriptId, linkedScriptId }) => {
        const scriptId = identityScriptId ?? linkedScriptId
        if (scriptId) {
          scriptMap[scriptId] = true
        }
      })
      return Object.keys(scriptMap).length
    },

    async duplicate(): Promise<{ id: number }> {
      await self.rootStore.doDebug()
      const {
        body: { id },
      } = await extractTsRestSuccess(
        self.scrapi.rundowns.copyRundown({
          params: {
            id: self.id,
          },
          body: {
            name: `Copy of ${self.name}`,
          },
        }),
        200,
      )
      self.analytics.trackDocCreated({
        type: 'rundown',
        format: self.rootStore.currentOrg?.rundownSchemaName ?? 'rundown',
        accessLevel: 'shared',
        from: 'duplicate',
      })
      // refresh the folder to get the updated listing
      await self.rootStore.folderMap.get(self.folderId)?.refresh()
      return { id }
    },

    async clone(): Promise<{ id: number; folderId: string }> {
      await self.rootStore.doDebug()
      const {
        body: { id, folderId },
      } = await extractTsRestSuccess(
        self.scrapi.rundowns.cloneRundown({
          params: { id: self.id },
          body: { prefix: `Copy of ` },
        }),
        200,
      )

      self.analytics.trackDocCreated({
        type: 'rundown',
        format: self.rootStore.currentOrg?.rundownSchemaName ?? 'rundown',
        accessLevel: 'shared',
        from: 'clone',
      })
      // load the folder that was created along with its listings
      const folder = await self.apiClient.getFolderDetails(folderId)
      self.rootStore.ingestGetFolderPayload(folder)

      return { id, folderId }
    },
  }))
