import {
  Diff,
  DIFF_DELETE,
  DIFF_EQUAL,
  DIFF_INSERT,
  diff_match_patch as DiffMatchPatch,
} from 'diff-match-patch'
import { Fragment, Node as PmNode } from 'prosemirror-model'

import { NodeTypeKey } from '@showrunner/codex'

import { buildSideBySide, filterLines } from './sideBySide'

type DiffableBlockInfo = {
  blockType: NodeTypeKey
  text: string
  position: number
  id: string | null
}

const getRawDiff = (left: string, right: string): Diff[] => {
  const dmp = new DiffMatchPatch()
  const rawDiff = dmp.diff_main(left, right)
  dmp.diff_cleanupSemantic(rawDiff)
  return rawDiff
}

// Find all the blockText nodes in a prose doc and build a flat
// array of them, preserving their positions
const fragmentToDiffableBlockInfo = (doc: Fragment) => {
  const result: DiffableBlockInfo[] = []
  doc.descendants((node, pos) => {
    const isTextblock = node.isTextblock
    if (!isTextblock) {
      return
    }
    const id = typeof node.attrs.id === 'string' ? node.attrs.id : null

    result.push({
      blockType: node.type.name as NodeTypeKey,
      text: node.textContent,
      // pos is the beginning of the block but the inline text node is inset
      // by 1
      position: pos + 1,
      id,
    })
  })

  return result
}

export const extractSlugFragment = (
  doc: PmNode,
  slugId: string,
): Fragment | null => {
  let startPosition = -1
  let endPosition: number | undefined = undefined
  doc.descendants((node, pos) => {
    if (startPosition < 0) {
      if (node.type.name === 'slug' && node.attrs.id === slugId) {
        startPosition = pos
      }
    } else if (endPosition === undefined) {
      if (node.type.name === 'slug') {
        endPosition = pos
      }
    }
  })
  if (startPosition > -1) {
    const slice = doc.slice(startPosition, endPosition)
    return slice.content
  }

  return null
}

// take an array of block infos and create a string concatenating all the text info
// and adding newline characters wherever there are gaps. The indexes of the string
// should correspond to the positions in the DiffableBlockInfo items
export const toDiffableText = (
  blockInfoList: DiffableBlockInfo[],
  preservePmPositions?: boolean,
): string => {
  const characters: string[] = []

  const addBlockInfo = (bi: DiffableBlockInfo) => {
    const startPosition = bi.position
    // if we are preserving PM positions, we insert as many \n characters
    // as needed to pad the count
    if (preservePmPositions && startPosition > characters.length) {
      const newlinesToAdd = startPosition - characters.length
      for (let i = 0; i < newlinesToAdd; i++) {
        characters.push('\n')
      }
    }
    characters.push(...bi.text.split(''))
    // if we are not preserving PM positions, we want each block to terminate in a
    // newline
    if (!preservePmPositions) {
      characters.push('\n')
    }
  }
  blockInfoList.forEach(addBlockInfo)
  return characters.join('')
}

type EnrichedDiff = {
  text: string
  diffType: Diff[0]
  leftPosition: number
  rightPosition: number
}

type DiffRange = [startPosition: number, endPosition: number]

const buildEnrichedDiff = (left: string, right: string): EnrichedDiff[] => {
  const rawDiff = getRawDiff(left, right)
  const result: EnrichedDiff[] = []
  let leftPosition = 0
  let rightPosition = 0
  rawDiff.forEach(([diffType, text]) => {
    result.push({
      text,
      diffType,
      leftPosition,
      rightPosition,
    })
    if (diffType !== DIFF_INSERT) {
      leftPosition += text.length
    }
    if (diffType !== DIFF_DELETE) {
      rightPosition += text.length
    }
  })

  return result
}

export class FragmentDiff {
  private readonly _leftBlockInfo: DiffableBlockInfo[]
  private readonly _rightBlockInfo: DiffableBlockInfo[]

  private _pmAwareDiff: Diff[] | null = null
  private _pmObliviousDiff: Diff[] | null = null

  private _pmAwareDiffs: EnrichedDiff[] | null = null
  private _pmObliviousDiffs: EnrichedDiff[] | null = null

  constructor({ left, right }: { left: Fragment; right: Fragment }) {
    this._leftBlockInfo = fragmentToDiffableBlockInfo(left)
    this._rightBlockInfo = fragmentToDiffableBlockInfo(right)
  }

  // lazy getter
  get pmAwareDiff(): Diff[] {
    if (this._pmAwareDiff) {
      return this._pmAwareDiff
    }
    const leftText = toDiffableText(this._leftBlockInfo, true)
    const rightText = toDiffableText(this._rightBlockInfo, true)
    this._pmAwareDiff = getRawDiff(leftText, rightText)
    return this._pmAwareDiff
  }

  get pmObliviousDiff(): Diff[] {
    if (this._pmObliviousDiff) {
      return this._pmObliviousDiff
    }
    const leftText = toDiffableText(this._leftBlockInfo, false)
    const rightText = toDiffableText(this._rightBlockInfo, false)
    this._pmObliviousDiff = getRawDiff(leftText, rightText)
    return this._pmObliviousDiff
  }

  get pmAwareDiffs(): EnrichedDiff[] {
    if (this._pmAwareDiffs) {
      return this._pmAwareDiffs
    }
    const leftText = toDiffableText(this._leftBlockInfo, true)
    const rightText = toDiffableText(this._rightBlockInfo, true)
    this._pmAwareDiffs = buildEnrichedDiff(leftText, rightText)
    return this._pmAwareDiffs
  }

  get pmObliviousDiffs(): EnrichedDiff[] {
    if (this._pmObliviousDiffs) {
      return this._pmObliviousDiffs
    }
    const leftText = toDiffableText(this._leftBlockInfo, false)
    const rightText = toDiffableText(this._rightBlockInfo, false)
    this._pmObliviousDiffs = buildEnrichedDiff(leftText, rightText)
    return this._pmObliviousDiffs
  }

  // for one side or the other, return an array of position ranges that are changed. This is
  // the position data needed to generate prosemirror decorations showing one of the two docs
  getChangePositionsForSide(side: 'left' | 'right'): {
    changed: DiffRange[]
    removed: number[]
  } {
    const result: { changed: DiffRange[]; removed: number[] } = {
      changed: [],
      removed: [],
    }

    this.pmAwareDiffs.forEach(
      ({ diffType, text, leftPosition, rightPosition }) => {
        // skip the unchanged parts
        if (diffType === DIFF_EQUAL) {
          return
        }
        const startPosition = side === 'left' ? leftPosition : rightPosition
        const textIsFromThisSide =
          (side === 'left' && diffType === DIFF_DELETE) ||
          (side === 'right' && diffType === DIFF_INSERT)

        if (textIsFromThisSide) {
          result.changed.push([startPosition, startPosition + text.length])
        } else {
          result.removed.push(startPosition)
        }
      },
    )

    return result
  }

  get sideBySide() {
    return buildSideBySide(this.pmObliviousDiffs)
  }

  getSideBySideDiff(linesOfContext?: number) {
    const lines = buildSideBySide(this.pmObliviousDiffs)
    if (typeof linesOfContext === 'number') {
      return filterLines(lines, linesOfContext)
    }
    return lines
  }
}
