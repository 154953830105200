import React from 'react'

import { Divider, Text } from '@mantine/core'
import { isAfter, isToday, isYesterday, startOfToday, subDays } from 'date-fns'

import { IOpenedListing } from '@state'

import { ListWrapper } from './DocumentList'
import { DocumentListing } from './DocumentListing'

const Header = ({ text }: { text: string }) => (
  <Text c="dimmed" fw="bold" size="13" tt="uppercase" px={10} mt={10} mb={10}>
    {text}
  </Text>
)

export const MyHistoryList = ({
  documents,
}: {
  documents: IOpenedListing[]
}) => {
  let previousHeader: string

  // TODO: return d.openedAt from server
  const listings = documents.map((d) => {
    const { openedAt } = d

    const header = isToday(openedAt)
      ? 'Today'
      : isYesterday(openedAt)
        ? 'Yesterday'
        : isAfter(openedAt, subDays(startOfToday(), 6))
          ? 'Past 7 days'
          : 'Older'

    const noHeader = previousHeader === undefined

    const showHeader =
      header !== previousHeader &&
      // hide "Older" when there is nothing younger
      !(noHeader && header === 'Older')

    const showDivider = showHeader && !noHeader

    previousHeader = header

    return (
      <React.Fragment key={d.id}>
        {showDivider && <Divider mx={10} mt={10} mb={15} color="gray.3" />}
        {showHeader && <Header text={header} />}
        <DocumentListing listing={d} />
      </React.Fragment>
    )
  })

  return <ListWrapper scrollOverflow>{listings}</ListWrapper>
}
