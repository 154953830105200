import printJS from 'print-js-updated'

import { IRoot } from '@state/types'
import { RundownRowData } from '@util/ScriptoApiClient/types'

import { saveBlobToFile } from '../fileDownload'
import { buildPrintableScriptHtml } from '../princePrinting'

export const getBlobAndSave = async ({
  downloadFn,
  fileName = 'Untitled',
}: {
  downloadFn: () => Promise<Blob | undefined>
  fileName?: string
}) => {
  const blob = await downloadFn()
  if (!blob) return
  saveBlobToFile({ blob, fileName })
}

export const getBlobAndPrint = async (
  downloadFn: () => Promise<Blob | undefined>,
) => {
  const blob = await downloadFn()
  if (!blob) return
  const href = URL.createObjectURL(blob)
  printJS({ printable: href, type: 'pdf' })
  URL.revokeObjectURL(href)
}

export const getPdfBlobForScript = async ({
  scriptId,
  title,
  mst,
  rundownRowData,
}: {
  scriptId: string
  title: string
  mst: IRoot
  rundownRowData?: RundownRowData
}) => {
  const { apiClient, user, view, currentOrg } = mst
  const unwatermark = view.shouldUnwatermark
  const printPrefs = user.scriptPrintPrefs

  const { id: snapshotId } = await apiClient.createSnapshot({
    scriptId,
    autoSave: 'export',
  })

  const snapshot = await apiClient.getSnapshot({ scriptId, snapshotId })

  const html = buildPrintableScriptHtml({
    snapshot,
    prefs: printPrefs,
    title,
    readRate: currentOrg?.readRate,
    rundownRowData,
  })

  // saveTextToFile({
  //   text: html,
  //   fileName: 'test.html',
  // })

  return apiClient.passThroughPrint({
    html,
    fileName: `${title}.pdf`,
    unwatermark,
  })
}
