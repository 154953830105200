/*
  Serializers for prose docs/fragments.

  TIP: For working on the clipboard, this tool is super handy (you can
  see the different clipboards: plain text, html, etc)
    https://langui.net/clipboard-viewer/
*/

import { DOMSerializer, Fragment, Slice } from 'prosemirror-model'

import { FormatUtils, schema } from '@showrunner/codex'

import { FormatBlockName, ScriptFormatConfiguration } from '@util/types'

const standardSerializer = DOMSerializer.fromSchema(schema)

export const getTextClipboardSerializer = (
  format: ScriptFormatConfiguration,
) => {
  const shouldUppercase = (nodeType: string) => {
    const blockFormat = format.blocks[nodeType as FormatBlockName]
    return blockFormat?.uppercase
  }

  return (slice: Slice) => {
    const parts: string[] = []
    slice.content.descendants((node) => {
      if (node.isTextblock) {
        const text = node.textContent
        if (shouldUppercase(node.type.name)) {
          parts.push(text.toUpperCase())
        } else {
          parts.push(text)
        }
      }
    })

    return parts.join('\n')
  }
}

// prevent the auto-margin padding that <p> tags typically get
// and stop empty blocks from collapsing (doesn't work for google Drive)
const BLOCK_STYLES = `
.o-block-element {
  margin: 0;
  min-height: 1em;
}
`

const createStyleElement = (format: ScriptFormatConfiguration): Element => {
  // generate format specific styles
  const formatStyles = FormatUtils.createStylesheet(format, {
    media: 'print',
  }).toString()
  const template = document.createElement('template')
  template.innerHTML = `<style>${BLOCK_STYLES}${formatStyles}</style>`
  return template.content.children[0]
}

export class ScriptFormatClipboardSerializer extends DOMSerializer {
  private readonly styleElement: Element

  constructor(format: ScriptFormatConfiguration) {
    super(standardSerializer.nodes, standardSerializer.marks)
    this.styleElement = createStyleElement(format)
  }

  serializeFragment(
    fragment: Fragment,

    options: { [key: string]: unknown } | undefined,
  ) {
    const domFragment = standardSerializer.serializeFragment(fragment, options)
    // stick in the style element
    domFragment.prepend(this.styleElement)
    return domFragment
  }
}
