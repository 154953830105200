import { Avatar, Group, Tooltip } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { Toolbar } from '@components/Toolbar'
import { UserAvatar } from '@components/UserAvatar'
import { useMst } from '@state'
import { ButtonMode, IRemoteCollaborator } from '@state/types'

import { getMaxCollaborators, jumpToCollaborator } from './helpers'
import { OverflowCollaborators } from './Overflow'

import styles from './Collaborators.module.scss'

const canJumpTo = (c: IRemoteCollaborator) => !!c.positionInfo

const tooltipFor = (c: IRemoteCollaborator) =>
  canJumpTo(c) ? `${c.user.name} - Click to find` : c.user.name

export const Collaborators = observer(function Collaborators({
  mode,
  collaborators,
}: {
  mode: ButtonMode
  collaborators: IRemoteCollaborator[]
}) {
  const { view } = useMst()

  // on extremely small screens, we suppress the component entirely
  if (mode === 'micro') return null

  const maxCollab = getMaxCollaborators(mode)
  const collabCount = collaborators.length
  const hasOverflow = collabCount > maxCollab
  const primary = collaborators.slice(0, maxCollab)

  return (
    <Toolbar.ButtonGroup>
      <Group gap={0}>
        <Avatar.Group spacing="sm">
          {primary.map((c) => (
            <Tooltip label={tooltipFor(c)} key={c.clientId}>
              <UserAvatar
                avatarSrc={c.user.avatar}
                ringColor={c.color}
                className={styles.collaborators_avatar}
                diameter={28}
                radius="xl"
                onClick={() => jumpToCollaborator(c)}
              />
            </Tooltip>
          ))}
          {hasOverflow && (
            <OverflowCollaborators
              containerHeight={view.dimensions.scriptScroller.height}
              collaborators={collaborators}
              mode={mode}
            />
          )}
        </Avatar.Group>
      </Group>
    </Toolbar.ButtonGroup>
  )
})
