import { Checkbox, Stack, Text } from '@mantine/core'

import { FormatKey } from '@layouts/Script/buildFormatStyles'
import { ILoadedScript } from '@state'
import { FormatBlockName } from '@util/types'
import { BlockOverrides } from '@util/zodSchemas/formatOverrides'

import { ChangeHandler } from './helpers'

import styles from './FormattingCheckboxes.module.scss'

const StyleCheckboxData: { id: FormatKey; label: string; className: string }[] =
  [
    { id: 'bold', label: 'Bold', className: styles.cb_bold },
    { id: 'italic', label: 'Italic', className: styles.cb_italic },
    { id: 'underline', label: 'Underline', className: styles.cb_underline },
    { id: 'uppercase', label: 'All caps', className: styles.cb_caps },
  ]

export const FormattingCheckboxes = ({
  script,
  unsavedOverrides,
  selectedBlock,
  onChange,
}: {
  script: ILoadedScript
  unsavedOverrides: BlockOverrides
  selectedBlock: FormatBlockName
  onChange: ChangeHandler
}) => {
  return (
    <Stack gap={2}>
      <Text fw="bold">Style</Text>
      <Stack className={styles.styleBox} gap={8}>
        {StyleCheckboxData.map((m) => {
          const unsavedVal = unsavedOverrides[selectedBlock]?.[m.id]
          const currVal = script.currentFormatValue(selectedBlock, m.id)

          return (
            <Checkbox
              key={m.id}
              label={m.label}
              className={m.className}
              checked={!!(unsavedVal ?? currVal)}
              onChange={(e) =>
                onChange({
                  blockName: selectedBlock,
                  option: m.id,
                  value: e.target.checked,
                })
              }
            />
          )
        })}
      </Stack>
    </Stack>
  )
}
