import { useQuery } from '@tanstack/react-query'

import { useMst } from '@state'

export const useStaffFormats = () => {
  const { scrapi } = useMst()
  const formatsQuery = useQuery({
    queryFn: async () => {
      const result = await scrapi.staff.listFormats()
      if (result.status === 200) {
        return result.body.formats
      }
      throw new Error('Unexpected result', { cause: result })
    },
    queryKey: ['staff.listFormats'],
  })

  return {
    formatsQuery,
  }
}
