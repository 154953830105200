import cn from 'classnames'
import {
  EditorState,
  Plugin,
  PluginKey,
  TextSelection,
} from 'prosemirror-state'
import { Decoration, DecorationSet, DirectEditorProps } from 'prosemirror-view'

import { NodeTypeKey, ScriptDocType } from '@showrunner/codex'

import { PAGE_WIDGET_ORDER } from '@choo-app/lib/editor/plugins/inlinePageBreaks'
import { cursorInDualDialogueBlock, nextNodeOnEnter } from '@util'

import { PluginFactory } from './types'

const ensureNewlineKey = new PluginKey<DecorationSet>('ENSURE_NEWLINE')

const handleClick: DirectEditorProps['handleClick'] = (editorView, _, evt) => {
  if (
    evt.target instanceof HTMLElement &&
    evt.target.classList.contains('ensure-newline-target')
  ) {
    // exit early if not editable
    if (!editorView.editable) {
      return false
    }

    const { tr, doc } = editorView.state
    const lastBlock = doc.lastChild?.lastChild
    const lastBlockHasContent = lastBlock && lastBlock.textContent.length > 0

    if (lastBlockHasContent) {
      const finalBlockPos = doc.nodeSize - 3

      const nextBlock = nextNodeOnEnter(
        lastBlock.type.name as NodeTypeKey,
        doc.attrs.docType as ScriptDocType,
        cursorInDualDialogueBlock(editorView.state),
      )

      tr.insert(finalBlockPos, editorView.state.schema.node(nextBlock))
        .setSelection(TextSelection.create(tr.doc, finalBlockPos))
        .scrollIntoView()
      editorView.dispatch(tr)
    }
    return true
  }
  return false
}

// Plugin adds a widget decoration at the bottom of the script. Clicking
// on it will create a new blank block at the end if the last block
// is not empty. This is designed to help collaborators append to
// a doc without colliding on other folks' work/breaking blocks.
export const ensureNewlinePlugin: PluginFactory = ({ mst }) => {
  const visible = mst.view.isDebugEnabled('show-newline')
  const generateDecoration = (editorState: EditorState) => {
    const finalBlockPos = editorState.doc.nodeSize - 3
    const elt = document.createElement('div')
    elt.className = cn('ensure-newline-target', { visible })
    const decoration = Decoration.widget(finalBlockPos, elt, {
      side: PAGE_WIDGET_ORDER.ensureNewline,
    })
    return DecorationSet.create(editorState.doc, [decoration])
  }

  return new Plugin<DecorationSet>({
    key: ensureNewlineKey,
    state: {
      init(cfg, editorState) {
        return generateDecoration(editorState)
      },
      apply(tr, pluginState, oldState, newState) {
        if (oldState.doc.eq(newState.doc)) {
          return pluginState
        }
        return generateDecoration(newState)
      },
    },
    props: {
      decorations(editorState) {
        return this.getState(editorState)
      },
      handleClick,
    },
  })
}
