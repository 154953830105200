import pTimeout from 'p-timeout'
import { io, Socket } from 'socket.io-client'
import { z } from 'zod'

import {
  ClientToServerEvents,
  schemas,
  ServerToClientEvents,
  ZInfer,
} from '@showrunner/scrapi'

import { DatadogClient } from '@util/datadog'

import { SOCKET_STATUS_EVENTS } from './types'

const {
  COMMENT_ADDED,
  COMMENT_DELETED,
  COMMENT_RESOLVED,
  COMMENT_UNRESOLVED,
  COMMENT_UPDATED,
  CURSOR_UPDATED,
  FOLDER_LISTING_UPDATED,
  RUNDOWN_LISTING_UPDATED,
  RUNDOWN_ROW_BLOBS_UPDATED,
  RUNDOWN_ROWS_DELETED,
  RUNDOWN_ROWS_INSERTED,
  RUNDOWN_ROWS_MOVED,
  SCRIPT_LISTING_UPDATED,
  SCRIPT_STATUS_CHANGED,
  SCRIPT_UPDATED,
  USER_JOINED,
  USER_LEFT,
} = schemas.sockets

// this is a really high value but it's what we've always used. If
// the client and server are up, socket connections take < 100ms
const SOCKET_CONNECTION_TIMEOUT_MS = 10000

export type ScriptoSocketClient = Socket<
  ServerToClientEvents,
  ClientToServerEvents
>

export const createSocket = (apiUrl: string, getSocketToken: () => string) =>
  io(`${apiUrl}/v1`, {
    transports: ['websocket'],
    reconnectionDelay: 250,
    reconnectionDelayMax: 5000,
    timeout: 5000,
    // we are creating the instance at launch without a token
    // so we set autoconnect to false, then we'll call connect
    // later when we have a token
    autoConnect: false,
    auth: (cb) => {
      const socketToken = getSocketToken()
      cb({ socketToken })
    },
  }) as ScriptoSocketClient

// wrapper to enable waiting for a socket connection and failing after a
// specified timeout
export const connectSocket = async (
  socket: Socket,
  timeoutMs = SOCKET_CONNECTION_TIMEOUT_MS,
): Promise<void> => {
  if (!socket.connected) {
    const promise = pTimeout(
      new Promise<void>((resolve) => {
        socket.once(SOCKET_STATUS_EVENTS.CONNECT, resolve)
      }),
      { milliseconds: timeoutMs },
    )
    socket.connect()
    return promise
  }
}

const { serverEventPayload } = schemas.sockets

type ServerEvent = z.infer<typeof serverEventPayload>
type ServerEventName = keyof ServerToClientEvents

// Helpers to identify/typecast rundown payloads
const RUNDOWN_EVENT_NAMES: readonly ServerEventName[] = [
  'RUNDOWN_ROWS_DELETED',
  'RUNDOWN_ROWS_INSERTED',
  'RUNDOWN_ROWS_MOVED',
  'RUNDOWN_ROW_BLOBS_UPDATED',
] as const
export type RundownEventPayload = ZInfer<
  | typeof RUNDOWN_ROW_BLOBS_UPDATED
  | typeof RUNDOWN_ROWS_DELETED
  | typeof RUNDOWN_ROWS_MOVED
  | typeof RUNDOWN_ROWS_INSERTED
>
export function isRundownEvent(
  event: ServerEvent,
): event is RundownEventPayload {
  return RUNDOWN_EVENT_NAMES.some((item) => item === event.eventType)
}

// Helpers to identify/typecast script payloads
const SCRIPT_EVENT_NAMES: readonly ServerEventName[] = [
  'COMMENT_ADDED',
  'COMMENT_DELETED',
  'COMMENT_RESOLVED',
  'COMMENT_UNRESOLVED',
  'COMMENT_UPDATED',
  'SCRIPT_STATUS_CHANGED',
  'CURSOR_UPDATED',
  'SCRIPT_UPDATED',
  'USER_JOINED',
  'USER_LEFT',
] as const
export type ScriptEventPayload = ZInfer<
  | typeof COMMENT_ADDED
  | typeof COMMENT_DELETED
  | typeof COMMENT_RESOLVED
  | typeof COMMENT_UNRESOLVED
  | typeof COMMENT_UPDATED
  | typeof CURSOR_UPDATED
  | typeof SCRIPT_STATUS_CHANGED
  | typeof SCRIPT_UPDATED
  | typeof USER_JOINED
  | typeof USER_LEFT
>
export function isScriptEvent(event: ServerEvent): event is ScriptEventPayload {
  return SCRIPT_EVENT_NAMES.some((item) => item === event.eventType)
}

export type CommentEventPayload = ZInfer<
  | typeof COMMENT_ADDED
  | typeof COMMENT_DELETED
  | typeof COMMENT_RESOLVED
  | typeof COMMENT_UNRESOLVED
  | typeof COMMENT_UPDATED
>

// Helpers to identify/typecast workspace payloads
const WORKSPACE_EVENT_NAMES: readonly ServerEventName[] = [
  'RUNDOWN_LISTING_UPDATED',
  'SCRIPT_LISTING_UPDATED',
  'FOLDER_LISTING_UPDATED',
] as const
export type WorkspaceEventPayload = ZInfer<
  | typeof RUNDOWN_LISTING_UPDATED
  | typeof SCRIPT_LISTING_UPDATED
  | typeof FOLDER_LISTING_UPDATED
>
export function isWorkspaceEvent(
  event: ServerEvent,
): event is WorkspaceEventPayload {
  return WORKSPACE_EVENT_NAMES.some((item) => item === event.eventType)
}

// we need to ignore these events
const DEPRECATED_EVENTS: string[] = ['userEvent', 'AUTH_ERROR']

// Function to turn an unknown payload into a well-typed socket message
// safely using zod. If we can't parse it and we don't have it marked
// as deprecated, log a warning (it's maybe a code bug).
export const parseSocketEvent = (
  eventName: string,
  payload: unknown,
): ServerEvent | undefined => {
  const parsed = serverEventPayload.safeParse(payload)
  if (parsed.success) {
    return parsed.data
  } else if (!DEPRECATED_EVENTS.includes(eventName)) {
    DatadogClient.getInstance().errorOnce(
      'Unhandled socket event',
      {
        eventName,
        payload,
        zodError: parsed.error,
      },
      `unhandled-socket-message-${eventName}`,
    )
  }
}
