import { NodeTypeKey, NodeTypeMap } from '@showrunner/codex'

import { Keys } from '@hooks'
import { FormatBlockName } from '@util/types'

// DISPLAY DATA
// ------------
// tracking display metadata separately from schema
// to reduce any possibility of breaking schema changes

const {
  ACTION,
  BRACKET,
  CHARACTER,
  DIALOGUE,
  END_OF_ACT,
  GENERAL,
  NEW_ACT,
  PARENTHETICAL,
  SCENE_HEADING,
  SLUG,
  TRANSITION,
} = NodeTypeMap

type MenuItem = {
  // We have two type systems going on here, id must satisify both
  id: NodeTypeKey & FormatBlockName
  title: string
  icon: `icon-${string}`
  keys: [string, string]
}

const screenplay: MenuItem[] = [
  {
    id: GENERAL,
    title: 'General',
    icon: 'icon-general',
    keys: [Keys.CMD, '0'],
  },
  {
    id: SCENE_HEADING,
    title: 'Scene heading',
    icon: 'icon-scene-heading',
    keys: [Keys.CMD, '1'],
  },
  {
    id: ACTION,
    title: 'Action',
    icon: 'icon-action',
    keys: [Keys.CMD, '2'],
  },
  {
    id: CHARACTER,
    title: 'Character',
    icon: 'icon-character',
    keys: [Keys.CMD, '3'],
  },
  {
    id: PARENTHETICAL,
    title: 'Parenthetical',
    icon: 'icon-parenthetical',
    keys: [Keys.CMD, '4'],
  },
  {
    id: DIALOGUE,
    title: 'Dialogue',
    icon: 'icon-dialogue',
    keys: [Keys.CMD, '5'],
  },
  {
    id: TRANSITION,
    title: 'Transition',
    icon: 'icon-transition',
    keys: [Keys.CMD, '6'],
  },
  {
    id: NEW_ACT,
    title: 'New act',
    icon: 'icon-new-act',
    keys: [Keys.CMD, '8'],
  },
  {
    id: END_OF_ACT,
    title: 'End of act',
    icon: 'icon-end-of-act',
    keys: [Keys.CMD, '9'],
  },
]

const variety: MenuItem[] = [
  {
    id: GENERAL,
    title: 'General',
    icon: 'icon-general',
    keys: [Keys.CMD, '0'],
  },
  {
    id: SLUG,
    title: 'Slug',
    icon: 'icon-slug',
    keys: [Keys.CMD, '1'],
  },
  {
    id: BRACKET,
    title: 'Bracket',
    icon: 'icon-bracket',
    keys: [Keys.CMD, '2'],
  },
  {
    id: CHARACTER,
    title: 'Character',
    icon: 'icon-character',
    keys: [Keys.CMD, '3'],
  },
  {
    id: PARENTHETICAL,
    title: 'Parenthetical',
    icon: 'icon-parenthetical',
    keys: [Keys.CMD, '4'],
  },
  {
    id: DIALOGUE,
    title: 'Dialogue',
    icon: 'icon-dialogue',
    keys: [Keys.CMD, '5'],
  },
  {
    id: NEW_ACT,
    title: 'New act',
    icon: 'icon-new-act',
    keys: [Keys.CMD, '8'],
  },
  {
    id: END_OF_ACT,
    title: 'End of act',
    icon: 'icon-end-of-act',
    keys: [Keys.CMD, '9'],
  },
]

export const menuData = { screenplay, variety, classic: variety }

// https://stackoverflow.com/questions/4215737/convert-array-to-object
const elArrayToMap = (
  a: Record<string, { icon: string }>,
  v: { id: string; icon: string },
) => ({
  ...a,
  [v.id]: {
    icon: v.icon,
  },
})

export const allDisplayData = [...screenplay, ...variety].reduce(
  elArrayToMap,
  {},
)

export const dualTypes = [DIALOGUE, PARENTHETICAL]
