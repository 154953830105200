import { commentDomHelpers } from '@components/Comments'
import { ILoadedScript } from '@state'

type PositionedBlockSummary = {
  blockId: string
  commentIds: string[]
  count: number
  top: number
}
export const usePositionedCommentCounts = (script: ILoadedScript) => {
  const pmCommentInventory = script.pmEditor.commentInventory
  const { threadCountMap, commentMap } = script.commentData

  const handleClick = (summary: PositionedBlockSummary) => {
    const firstId: string | undefined = summary.commentIds.find((cid) => {
      const comment = commentMap.get(cid)
      return comment && !comment.resolvedAt
    })

    if (firstId) {
      script.commentData.openPanel()
      script.commentData.selectThread(firstId)
      commentDomHelpers.scrollCommentPanel(firstId)
    }
  }

  const blockSummaries: PositionedBlockSummary[] = []
  if (pmCommentInventory) {
    Object.entries(pmCommentInventory.blockToComments).forEach(
      ([blockId, commentIds]) => {
        const blockItem: PositionedBlockSummary = {
          blockId,
          top: script.pmDomInfo.getBlockTop(blockId),
          commentIds,
          count: 0,
        }

        commentIds.forEach((cid) => {
          const commentTotal = threadCountMap.get(cid) ?? 0
          blockItem.count += commentTotal
        })

        if (blockItem.count > 0) {
          blockSummaries.push(blockItem)
        }
      },
    )
  }

  return {
    blockSummaries,
    handleClick,
  }
}
