import {
  CreateStepsParams,
  CreateStepsPayload,
  GetStepsParams,
  GetStepsPayload,
} from '../types'

import { ApiConfig } from './types'

export const createSteps: ApiConfig<CreateStepsParams, CreateStepsPayload> = ({
  clientId,
  scriptId,
  steps,
  version,
}) => ({
  method: 'POST',
  url: `/scripts/${scriptId}/steps`,
  data: { clientId, steps, version },
})

export const getSteps: ApiConfig<GetStepsParams, GetStepsPayload> = ({
  scriptId,
  from,
}) => ({
  method: 'GET',
  url: `/scripts/${scriptId}/steps?from=${from}`,
})
