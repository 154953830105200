import { useQuery } from '@tanstack/react-query'

import { ScriptLoadErrorPage } from '@components/ErrorPage'
import { Loader } from '@components/Loader'
import { Script } from '@layouts'
import { useMst } from '@state'

export const ScriptLoader = ({ scriptId }: { scriptId: string }) => {
  const mst = useMst()
  const { data, isLoading, isError, error } = useQuery({
    queryFn: async () => mst.requestScript(scriptId),
    queryKey: ['script', scriptId],
    cacheTime: 0,
    staleTime: Infinity,
  })

  if (isError) {
    return <ScriptLoadErrorPage error={error} scriptId={scriptId} />
  }

  if (isLoading) {
    return <Loader visible />
  }

  return <Script script={data} />
}
