import { format } from 'date-fns'
import { Instance, types } from 'mobx-state-tree'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { IFolder, IListing } from '@state/types'
import { extractTsRestSuccess } from '@util/extractTsRest'

import { BaseModel } from './BaseModel'
import { sortAlphaByNameFn, sortByCreationFn, sortRecentFn } from './util'

export const UncreatedFolder = types
  .model('UncreatedFolder', {
    name: '',
  })
  .actions((self) => ({
    setName(value: string) {
      self.name = value
    },
  }))

export const Folder = BaseModel.named('Folder')
  .props({
    inTrash: types.boolean,
    isPrivate: types.boolean,
    id: types.identifier,
    name: types.string,
    parentId: types.string,
    // have we ever loaded the contents of this folder?
    contentsLoadedOnce: false,
    // are we currently loading the contents?
    contentsLoading: false,
    uncreatedChild: types.maybe(UncreatedFolder),
    pendingLocationRequest: false,
  })
  .views((self) => ({
    get isSelected(): boolean {
      return self.id === self.rootStore.view.selectedFolderId
    },
    get isRootFolder() {
      return self.id === self.parentId
    },
    get isRootTrash() {
      return this.isRootFolder && self.inTrash
    },
    get isSharedDash(): boolean {
      return this.isRootFolder && !self.isPrivate && !self.inTrash
    },
    get displayName(): string {
      if (this.isRootTrash) return 'Trash'
      if (this.isRootFolder) return self.isPrivate ? 'Private' : 'Shared'
      return self.name
    },
    get unsortedDocuments(): IListing[] {
      // if this is the shared trash, get the children of any private trash root
      const documents = self.rootStore.getDocumentsInFolder(self.id)
      if (this.isRootTrash && !self.isPrivate) {
        const { privateTrash } = self.rootStore.rootFolders
        const privateDocs = privateTrash
          ? self.rootStore.getDocumentsInFolder(privateTrash.id)
          : []
        documents.push(...privateDocs)
      }
      return documents
    },
    get sortedDocuments(): IListing[] {
      // cant reference unsortedDocuments here because mst caches
      const documents = self.rootStore.getDocumentsInFolder(self.id)
      if (this.isRootTrash && !self.isPrivate) {
        const { privateTrash } = self.rootStore.rootFolders
        const privateDocs = privateTrash
          ? self.rootStore.getDocumentsInFolder(privateTrash.id)
          : []
        documents.push(...privateDocs)
      }

      switch (self.rootStore.user.getFolderSortOrder(self.id)) {
        case 'alphabetical':
          return documents.sort(sortAlphaByNameFn)
        case 'newest':
          return documents.sort(sortByCreationFn)
        case 'oldest':
          return documents.sort(sortByCreationFn).reverse()
        default:
          return documents.sort(sortRecentFn)
      }
    },
    get childFolders(): IFolder[] {
      const children = self.rootStore.getChildFolders(self.id)
      // if this is the shared trash root, merge in the children of
      // private trash as well
      if (this.isRootTrash && !self.isPrivate) {
        const { privateTrash } = self.rootStore.rootFolders
        const privateTrashChildren = privateTrash
          ? self.rootStore.getChildFolders(privateTrash.id)
          : []
        children.push(...privateTrashChildren)
      }
      return children
    },
    get path(): string {
      if (this.isSharedDash) {
        return '/'
      }
      return `/folders/${self.id}`
    },
    get treeDepth(): number {
      const parent = self.rootStore.folderMap.get(self.parentId)
      if (!parent || parent.id === self.id) {
        return 0
      }
      return parent.treeDepth + 1
    },
    get isCreatingChild(): boolean {
      return self.rootStore.view.folderCreatingChildId === self.id
    },
    get isBeingRenamed(): boolean {
      return self.rootStore.view.folderBeingRenamedId === self.id
    },
  }))

  // synchronous actions
  .actions((self) => ({
    getRootFolder(isPrivate: boolean, inTrash: boolean): IFolder | undefined {
      return self.rootStore.getRootFolder(isPrivate, inTrash)
    },
    startCreatingChild() {
      self.rootStore.view.setCreatingChild(true)
      if (!self.uncreatedChild) {
        self.uncreatedChild = UncreatedFolder.create()
      }
    },
    startRenaming() {
      self.rootStore.view.startRenamingFolder(self.id)
    },
    markLoading(value: boolean) {
      self.contentsLoading = value
    },
    markLoadingComplete() {
      self.contentsLoading = false
      self.contentsLoadedOnce = true
    },
    setName(name: string) {
      self.name = name
    },
    updateFields({
      inTrash,
      isPrivate,
      name,
      parentId,
    }: {
      inTrash: boolean
      isPrivate: boolean
      name: string
      parentId: string | undefined
    }) {
      self.inTrash = inTrash
      self.isPrivate = isPrivate
      self.name = name
      if (parentId) {
        self.parentId = parentId
      }
    },
  }))
  .actions((self) => ({
    setPendingLocationRequest(value: boolean) {
      self.pendingLocationRequest = value
    },
    async createRundown(
      name = `Rundown ${format(new Date(), 'MM/dd/yyyy')}`,
    ): Promise<{ rundownId: number }> {
      const params = { body: { name, folderId: self.id } }

      const {
        body: { id: rundownId },
      } = await extractTsRestSuccess(self.scrapi.rundowns.create(params), 200)

      const rowCount = 10
      const rowDataList: ZInfer<typeof schemas.RowDataInput>[] = []
      for (let i = 0; i < rowCount; i++) {
        rowDataList.push({ rowTypeId: 'element', blobData: {} })
      }

      await extractTsRestSuccess(
        self.legacyApi.insertRundownRows({
          params: { id: rundownId },
          body: {
            sequence: 1,
            rowDataList,
          },
        }),
        200,
      )
      await self.rootStore.refreshFolder(self.id)
      return { rundownId }
    },

    async createSubfolder({ name }: { name: string }) {
      const result = await self.scrapi.folders.create({
        body: {
          name,
          parentId: self.id,
        },
      })

      if (result.status === 201) {
        self.rootStore.ingestFolderList([result.body])
      }
      self.rootStore.view.setCreatingChild(false)
    },

    async importScriptFromFdx(file: File): Promise<{ id: string } | undefined> {
      const result = await self.apiClient.importFdx({
        folderId: self.id,
        file,
        formatId: self.rootStore.currentOrg?.screenplayFormat.id ?? '',
      })
      return result[0]
    },

    async load() {
      if (self.rootStore.view.useNidoAlpha) {
        return this.loadViaScrapi()
      }
      self.markLoading(true)
      const result = await self.apiClient.getFolderDetails(self.id)
      if (result) {
        self.rootStore.ingestGetFolderPayload(result)
        self.markLoadingComplete()
      }
      self.markLoading(false)
    },

    async loadViaScrapi() {
      self.markLoading(true)
      const { body, status } = await self.scrapi.folders.findOne({
        params: { id: self.id },
      })
      if (status === 200) {
        self.rootStore.ingestGetFolderPayload(body)
        self.markLoadingComplete
      }
      self.markLoading(false)
    },

    async moveToTrash() {
      const { message, error } = await self.apiClient.trashFolder({
        folderId: self.id,
      })
      // optimistically update the folderMap on success
      const parentId = self.rootStore.rootFolders.sharedTrash?.id
      if (parentId && message && error === undefined) {
        self.updateFields({
          inTrash: true,
          parentId,
          name: self.name,
          isPrivate: self.isPrivate,
        })
      }
    },

    async moveToFolder(parentId: string) {
      const { isPrivate, inTrash, name } = await self.apiClient.updateFolder({
        folderId: self.id,
        parentId,
      })
      self.updateFields({
        isPrivate,
        inTrash,
        name,
        parentId,
      })
    },

    async rename(name: string) {
      await self.apiClient.updateFolder({
        folderId: self.id,
        name,
      })
      self.setName(name)
      self.rootStore.view.stopRenamingFolder()
    },

    async refresh() {
      await self.rootStore.refreshFolder(self.id)
    },
  }))

export const sortAlphaFn = (
  a: Instance<typeof Folder>,
  b: Instance<typeof Folder>,
): number => {
  const aName = a.displayName.toLowerCase()
  const bName = b.displayName.toLowerCase()
  // use id to be deterministic if names are the same
  if (aName === bName) {
    return a.id < b.id ? -1 : 1
  }

  // https://stackoverflow.com/questions/2802341/natural-sort-of-alphanumerical-strings-in-javascript
  return aName.localeCompare(bName, undefined, { numeric: true })
}
