import { LogsInitConfiguration } from '@datadog/browser-logs'

import pkg from '../../../package.json'

const version = pkg.version

const appLaunchTime = new Date().valueOf()
const CLIENT_TOKEN = 'pub389619c1c76486680ca38d505456f03d'
const SITE = 'datadoghq.com'
const UNKNOWN_ENV = 'unknown'

const getEnvTag = (): string => {
  if (location.hostname === 'app.scripto.live') {
    return 'production'
  }

  if (
    location.hostname.endsWith('scripto.live') ||
    location.hostname.endsWith('scripto.dev') ||
    location.hostname.endsWith('scripto.local')
  ) {
    return location.hostname.split('.')[0]
  }

  return UNKNOWN_ENV
}

const envTag = getEnvTag()

// called before each event is sent, return false to bypass
// sending to datadog
const beforeSend: LogsInitConfiguration['beforeSend'] = (evt) => {
  // typically we only log in known environments but if you've
  // set the special debug flag, we'll send anyway
  const isUnknown = envTag === UNKNOWN_ENV
  const alwaysSend = !!window.d?.sendDebugLogs
  if (isUnknown && !alwaysSend) {
    return false
  }

  // Update the app age on datadog logs.
  evt.app_age_seconds = (new Date().valueOf() - appLaunchTime) / 1000
  return true
}

export const config: LogsInitConfiguration = {
  clientToken: CLIENT_TOKEN,
  site: SITE,
  service: 'wallaby',
  version,
  env: envTag,
  beforeSend,
}
