import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { Anchor, Text } from '@mantine/core'

import { ModalShell, useModalControls } from '@components/Modals'
import { useMst } from '@state'

import { useStaffTemplates } from './useTemplateApis'

const INFO_TEXT = `
  If you decommission a template, it will no longer be available to end
  users. It will become a normal workspace and you will become the owner and
  only member of the workspace.
`

const DecommissionTemplate = ({ code }: { code: string }) => {
  const controls = useModalControls()
  const { decommissionMutation } = useStaffTemplates()
  const { switchOrgAndRelaunch } = useMst()

  const handleSubmit = () => decommissionMutation.mutate(code)

  return (
    <ModalShell
      title="Decommission Template"
      dangerous
      confirmLabel="Nuke it!"
      cancelLabel={decommissionMutation.isSuccess ? 'Close' : 'Cancel'}
      onConfirm={handleSubmit}
      opened={controls.opened}
      loading={decommissionMutation.isLoading}
      errorMessage={
        decommissionMutation.isError ? 'Error decommissioning' : undefined
      }
      onClose={controls.onClose}
      disabled={
        decommissionMutation.isSuccess || decommissionMutation.isLoading
      }
    >
      <Text>{INFO_TEXT}</Text>
      {decommissionMutation.isSuccess && (
        <Anchor
          onClick={() =>
            switchOrgAndRelaunch(decommissionMutation.data.workspaceId)
          }
        >
          Success! Click to go to workspace:&ensp;
          {decommissionMutation.data.workspaceId}
        </Anchor>
      )}
    </ModalShell>
  )
}

export const DecommissionTemplateModal = NiceModal.create(DecommissionTemplate)

export const showDecommissionModal = (code: string) =>
  NiceModal.show(DecommissionTemplateModal, { code })
