import React from 'react'

import {
  DotDotDotItem,
  DotDotDotMenu,
  STANDARD_FOLDER_ITEMS,
  TRASH_FOLDER_ITEMS,
} from '@components/FolderExplorer/DotDotDotMenu'
import { Loader } from '@components/Loader'
import { showMoveToFolder } from '@components/Modals'
import { launchExplorerToast } from '@components/Toast'
import { useMst } from '@state'
import { IFolder } from '@state/types'
import { capitalize } from '@util'

export const FolderListingMenu = ({ folder }: { folder: IFolder }) => {
  const { getRootFolder, view } = useMst()
  const [loading, setLoading] = React.useState(false)

  const handleMoveToFolder = async (folderId: string, message: string) => {
    await folder.moveToFolder(folderId)
    launchExplorerToast({
      message,
      type: 'success',
      autoCloseDelayMs: 3000,
    })
  }

  const handleShowSelectFolder = (option: 'move' | 'restore') => {
    const failureMessage = `Could not ${option} folder.`
    const successMsg = `${capitalize(option)}d "${folder.name}" successfully.`
    const title = `${capitalize(option)} Folder`
    const rootFolder = getRootFolder(folder.isPrivate, false)
    if (!rootFolder) {
      return
    }
    const folderListState = view.initializeReadonlyFolderState(
      rootFolder,
      folder.id,
    )
    showMoveToFolder({
      folderListState,
      title,
      itemName: folder.name,
      onSubmit: (folderId) => handleMoveToFolder(folderId, successMsg),
      failureMessage,
    })
  }

  const handleSelectItem = async (item: DotDotDotItem) => {
    switch (item.id) {
      case 'copylink':
        navigator.clipboard.writeText(
          `${window.location.origin}/folders/${folder.id}`,
        )
        launchExplorerToast({
          message: 'Link copied to clipboard 🔗',
          type: 'success',
          autoCloseDelayMs: 3000,
        })
        break
      case 'rename':
        folder.startRenaming()
        break
      case 'move':
        handleShowSelectFolder('move')
        break
      case 'restore':
        handleShowSelectFolder('restore')
        break
      case 'trash':
        try {
          setLoading(true)
          await folder.moveToTrash()
          launchExplorerToast({
            message: `Moved "${folder.name}" to trash.`,
            type: 'success',
            autoCloseDelayMs: 3000,
          })
        } catch (e) {
          launchExplorerToast({
            message: `Couldn't move "${folder.name}" to trash.`,
            type: 'error',
          })
        }
        setLoading(false)
        break
      default:
        break
    }
  }
  return (
    <>
      <Loader visible={loading} fullScreen />
      <DotDotDotMenu
        menuItems={folder.inTrash ? TRASH_FOLDER_ITEMS : STANDARD_FOLDER_ITEMS}
        onSelect={handleSelectItem}
      />
    </>
  )
}
