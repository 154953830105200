import { observer } from 'mobx-react-lite'
import { toggleMark } from 'prosemirror-commands'

import { MarkTypeKey, MarkTypeMap, schema } from '@showrunner/codex'

import { CustomIcon } from '@components/CustomIcon'
import { Toolbar } from '@components/Toolbar'
import { Keys, ShortcutConfig, useShortcuts } from '@hooks'
import { useMst } from '@state'
import { capitalize, forceCaps } from '@util'

import { ColorPickerPopover } from './ColorPickerPopover'
import { getSelectionColors } from './ColorPickerPopover/pmHelpers'

const { STRONG, EM, UNDERLINE, STRIKE } = MarkTypeMap

type SHORTCUTTED_MARK = 'strong' | 'em' | 'underline' | 'strike'

const formattingOptions: Array<{
  name: SHORTCUTTED_MARK
  label: string
  keys: string[]
}> = [
  { name: STRONG, label: 'bold', keys: [Keys.CMD, 'B'] },
  { name: EM, label: 'italic', keys: [Keys.CMD, 'I'] },
  { name: UNDERLINE, label: 'underline', keys: [Keys.CMD, 'U'] },
  {
    name: STRIKE,
    label: 'strikethrough',
    keys: [Keys.SHIFT, Keys.CMD, 'U'],
  },
]

export const StyleButtons = observer(function StyleButtons() {
  const { currentScript, view } = useMst()
  const observableEditor = currentScript?.observableEditor

  const toggleStyle = (type: MarkTypeKey) => {
    if (observableEditor) {
      const { editorState, editorView } = observableEditor
      toggleMark(schema.marks[type], undefined, { removeWhenPresent: false })(
        editorState,
        editorView.dispatch,
      )
    }
  }

  const disabled = !observableEditor?.canFormatText

  const config: Record<string, ShortcutConfig> = {}
  for (const option of formattingOptions) {
    const MARK_KEY = option.name
    config[MARK_KEY] = {
      keys: option.keys,
      action: () => toggleStyle(MARK_KEY as MarkTypeKey),
      disabled,
    }
  }

  const { getItemProps } = useShortcuts(config)
  const capsItemProps = useShortcuts({
    caps: {
      keys: [Keys.CMD, Keys.ALT, 'A'],
      action: () => forceCaps(observableEditor?.editorView),
      disabled,
    },
  }).getItemProps('caps')

  if (!observableEditor) return null

  const [color, bgColor] = getSelectionColors(observableEditor.editorState)
  return (
    <>
      {formattingOptions.map(({ name, label }) => (
        <Toolbar.Button
          key={name}
          icon={`fa-${label}`}
          tooltip={`${capitalize(label)} (${getItemProps(name).shortcut})`}
          {...getItemProps(name)}
          active={observableEditor.selectionContainsMark(name)}
          focusEditor
        />
      ))}
      <div className="o-icon o-icon--custom">
        <Toolbar.Button
          customIcon={<CustomIcon icon="icon-caps" />}
          tooltip={`All caps (${capsItemProps.shortcut})`}
          {...capsItemProps}
          focusEditor
        />
      </div>
      {view.hasBetaFormatting && (
        <ColorPickerPopover
          editorView={observableEditor.editorView}
          color={color}
          bgColor={bgColor}
          active={!(color === '' && bgColor === '')}
          disabled={!observableEditor.canFormatText}
        />
      )}
    </>
  )
})
