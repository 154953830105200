import { types } from 'mobx-state-tree'

import { ScriptFormat, ScriptFormatMap } from '@showrunner/codex'

import { IFolder } from '@state/types'
import { extractTsRestSuccess } from '@util/extractTsRest'

import { ScriptListingBase } from './ListingBase'
import { ScriptFormatSummaryModel } from './ScriptFormats'

export const ScriptListing = ScriptListingBase.named('ScriptListing')
  .props({
    id: types.identifier,
    modelType: types.frozen<'scriptListing'>('scriptListing'),
    type: types.enumeration<ScriptFormat>(Object.values(ScriptFormatMap)),
    scriptFormat: ScriptFormatSummaryModel,
    isEditingName: false,
    pendingLocationRequest: false,
  })
  .views((self) => ({
    get path() {
      return `/scripts/${self.id}`
    },
    get isSelected(): boolean {
      return self.rootStore.location.getPathParam('scriptId') === self.id
    },
  }))
  .actions((self) => ({
    getRootFolder(isPrivate: boolean, inTrash: boolean): IFolder | undefined {
      return self.rootStore.getRootFolder(isPrivate, inTrash)
    },
    placeInTrash() {
      const trashId = self.rootStore.getRootFolder(self.isPrivate, true)?.id
      if (trashId) {
        self.folderId = trashId
        if (self.id === self.rootStore.currentScript?.id) {
          self.rootStore.currentScript.setFolderId(trashId)
        }
      }
    },
    placeInFolder(folderId: string) {
      self.folderId = folderId

      if (self.id === self.rootStore.currentScript?.id) {
        self.rootStore.currentScript.setFolderId(folderId)
      }
    },
    setName(value: string) {
      self.name = value
    },
    setIsEditingName(value: boolean) {
      self.isEditingName = value
    },
    setPendingLocationRequest(value: boolean) {
      self.pendingLocationRequest = value
    },
  }))
  .actions((self) => ({
    async destroy() {
      await self.apiClient.destroyScript(self.id)
      self.rootStore.removeScriptFromState(self.id)
    },

    async duplicate(): Promise<void> {
      await self.rootStore.doDebug()
      await extractTsRestSuccess(
        self.scrapi.scripts.copy({
          params: { id: self.id },
          body: { name: `Copy of ${self.name}` },
        }),
        200,
      )
      // refresh the document pane
      const folder = self.rootStore.folderMap.get(self.folderId)
      await folder?.load()
      const { currentOrg } = self.rootStore
      if (folder && currentOrg) {
        const type = self.type === 'screenplay' ? 'screenplay' : 'studio'
        self.analytics.trackDocCreated({
          type,
          format: self.scriptFormat.id,
          from: 'duplicate',
          accessLevel: folder.isPrivate ? 'private' : 'shared',
        })
      }
    },

    async moveToTrash() {
      const { message, error } = await self.apiClient.trashScript({
        scriptId: self.id,
      })
      // optimistically update the folderMap if appropriate
      if (message && error === undefined) self.placeInTrash()
    },

    async moveToFolder(folderId: string) {
      const response = await self.apiClient.moveScript({
        scriptId: self.id,
        folderId,
      })

      // optimistically update the folderMap if appropriate
      if (response.folderId === folderId) {
        self.placeInFolder(folderId)
      }

      const isLoadedScript = self.rootStore.currentScript?.id === self.id
      if (response.status === 'LIMITED' || response.status === 'OPEN') {
        self.setSharedStatus(response.status)
        if (isLoadedScript) {
          self.rootStore.currentScript?.setSharedStatus(response.status)
        }
      }
    },

    async rename(name: string) {
      await self.apiClient.renameScript({
        scriptId: self.id,
        name,
      })
      self.setIsEditingName(false)
      self.setName(name)
      if (self.rootStore.currentScript?.id === self.id) {
        self.rootStore.currentScript.setName(name)
      }
    },
  }))
