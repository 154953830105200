import React from 'react'

import { TextInput } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { ILoadedScript } from '@state'

import styles from './ElementNumbers.module.scss'

const valToCh = (text: string) => text.length + 'ch'

const ElementNumber = ({
  canEdit,
  label,
  top,
  onSubmit,
}: {
  canEdit: boolean
  label: string | null
  top: number
  onSubmit: (value: string) => void
}) => {
  const [val, setVal] = React.useState(label ?? '')

  return (
    <TextInput
      classNames={{
        root: 'element-number-input-root',
        input: styles.input,
      }}
      styles={{ root: { top }, input: { width: valToCh(val) } }}
      type="text"
      value={val}
      placeholder="#"
      maxLength={5}
      readOnly={!canEdit}
      onBlur={() => onSubmit(val)}
      onChange={(e) => {
        e.target.style.width = valToCh(e.target.value)
        setVal(e.target.value)
      }}
      size="0"
    />
  )
}

export const ElementNumbers = observer(function ElementNumbers({
  script,
}: {
  script: ILoadedScript
}) {
  // sort to ensure Tab order is preserved top to bottom
  const elemNums = script.blocksWithElementNumbers
    .map(({ id, elementNumber }) => ({
      id,
      elementNumber,
      top: script.pmDomInfo.getBlockTop(id),
    }))
    .sort((a, b) => a.top - b.top)

  return (
    <div>
      {elemNums.map(({ id, elementNumber, top }) => (
        <ElementNumber
          key={`${id}-${elementNumber}`}
          canEdit={script.isEditable}
          top={top}
          label={elementNumber}
          onSubmit={(value) => script.updateElementNumber({ id, value })}
        />
      ))}
    </div>
  )
})
