import { Group, NumberInput, Select, Stack, Text } from '@mantine/core'

import { ILoadedScript } from '@state'
import { capitalize } from '@util'
import {
  BlockOverrides,
  FormatBlockName,
  FormatOption,
  FormatOptionValue,
} from '@util/types'
import { SingleBlockOverride } from '@util/zodSchemas/formatOverrides'

import { ChangeHandler } from './helpers'

type DefinedSingleBlockOverride = Exclude<SingleBlockOverride, undefined>

export const LayoutInputs = ({
  script,
  unsavedOverrides,
  selectedBlock,
  onChange,
}: {
  script: ILoadedScript
  unsavedOverrides: BlockOverrides
  selectedBlock: FormatBlockName
  onChange: ChangeHandler
}) => {
  const getUnsavedVal = <T extends FormatOption>(
    key: T,
  ): FormatOptionValue<T> | undefined => {
    if (unsavedOverrides && unsavedOverrides[selectedBlock]) {
      const blockOverrides: DefinedSingleBlockOverride =
        unsavedOverrides[selectedBlock]
      const unsavedValue = blockOverrides[key]
      if (unsavedValue !== undefined) {
        return unsavedValue as FormatOptionValue<T>
      }
    }
  }

  const getCurrVal = <T extends FormatOption>(key: T): FormatOptionValue<T> => {
    const unsavedVal = getUnsavedVal(key)
    const currVal: FormatOptionValue<T> = script.currentFormatValue(
      selectedBlock,
      key,
    )
    return unsavedVal ?? currVal
  }

  const leftMarginInch = (getCurrVal('marginLeft') * 9.6) / 96 + 1.5
  const rightMarginInch =
    8.5 - (getCurrVal('width') * 9.6) / 96 - leftMarginInch

  return (
    <Stack align="start" gap={20}>
      <Stack gap={2}>
        <Text fw="bold">Text alignment</Text>
        <Select
          size="md"
          data={['Left', 'Center', 'Right']}
          value={capitalize(getCurrVal('alignment'))}
          onChange={(a) =>
            onChange({
              blockName: selectedBlock,
              option: 'alignment',
              value: a?.toLowerCase() ?? 'left',
            })
          }
          inputSize="6"
        />
      </Stack>
      <Group gap={20}>
        <Stack gap={2}>
          <Text fw="bold">Line spacing</Text>
          <Select
            size="md"
            inputSize="6"
            data={['1', '1.5', '2']}
            value={String(getCurrVal('lineHeight'))}
            onChange={(a) =>
              onChange({
                blockName: selectedBlock,
                option: 'lineHeight',
                value: Number(a) ?? getCurrVal('lineHeight'),
              })
            }
          />
        </Stack>
        <NumberInput
          inputSize="8"
          label="Space above"
          allowDecimal={false}
          max={30}
          min={0}
          value={getCurrVal('blockTopMargin')}
          onChange={(value) =>
            onChange({
              blockName: selectedBlock,
              option: 'blockTopMargin',
              value,
            })
          }
        />
      </Group>
      <Group gap={20}>
        <NumberInput
          inputSize="8"
          label="Left margin"
          decimalScale={1}
          step={0.5}
          min={1.5}
          max={4}
          suffix='"'
          value={leftMarginInch}
          disabled
        />
        <NumberInput
          inputSize="8"
          label="Right margin"
          step={0.5}
          min={1.5}
          max={4}
          suffix='"'
          value={Math.round(rightMarginInch * 10) / 10}
          disabled
        />
      </Group>
    </Stack>
  )
}
