import { differenceInSeconds } from 'date-fns'
import { types } from 'mobx-state-tree'
import { nanoid } from 'nanoid'

import { offerUpdate, showAlert } from '@components/Modals'
import { BaseModel } from '@state/models/BaseModel'
import { delay } from '@util'
import { isUpdateAvailable } from '@util/checkVersion'

const launchTime = new Date()

// 1 hour before sleep
const SUSPEND_AFTER_BACKGROUND_SECONDS = 60 * 60
const RECONNECT_GRACE_PERIOD_SECONDS = 5

type TimeListenerType = {
  intervalSeconds: number
  elapsedTicks: number
  cb: Callback
}

// some older browsers don't have UserActivation, so just
// pretend these users have been active
const getActivation = (): UserActivation => {
  return (
    navigator.userActivation ?? {
      isActive: true,
      hasBeenActive: true,
    }
  )
}

const notifyOffline = () =>
  showAlert({
    children:
      "We aren't able to reconnect your browser. Check your internet connection and click OK to try again",
    title: "Can't reconnect",
  })

export const AppStatus = BaseModel.named('AppStatus')
  .props({
    // universal timer for the app. We update this once per second
    now: types.optional(types.Date, launchTime),
    visibility: types.optional(
      types.enumeration<DocumentVisibilityState>(['hidden', 'visible']),
      document.visibilityState,
    ),
    lastVisibilityChange: types.optional(types.Date, launchTime),
    lastUserInteraction: types.optional(types.Date, launchTime),
    interval: types.maybe(types.number),
    suspended: false,
    resuming: false,
  })
  .views((self) => ({
    secondsSince(value: Date) {
      return differenceInSeconds(self.now, value)
    },

    get launchTime() {
      return launchTime
    },

    get visible() {
      return self.visibility === 'visible'
    },

    // this enables a grace period after foregrounded before we
    // show any
    get isForegroundDisconnected(): boolean {
      return (
        self.rootStore.socketManager.status !== 'connected' &&
        self.visibility === 'visible' &&
        this.secondsSince(self.lastVisibilityChange) >
          RECONNECT_GRACE_PERIOD_SECONDS
      )
    },
    get secondsUntilSleep(): number {
      return self.rootStore.view.debugSleep
        ? 10
        : SUSPEND_AFTER_BACKGROUND_SECONDS
    },
  }))
  .extend(() => {
    const timeListeners: { [key: string]: TimeListenerType } = {}

    return {
      actions: {
        registerTimeListener: ({
          cb,
          intervalSeconds = 1,
        }: {
          cb: Callback
          intervalSeconds?: number
        }) => {
          const id = nanoid()
          timeListeners[id] = {
            cb,
            intervalSeconds,
            elapsedTicks: 0,
          }
          return id
        },
        removeTimeListener: (id: string) => {
          delete timeListeners[id]
        },
        updateListeners: () => {
          Object.values(timeListeners).forEach((tl) => {
            // increment the tick count on all listeners
            tl.elapsedTicks = tl.elapsedTicks + 1
            // when the interval is reached, fire the callback
            // and reset the interval seconds to 0
            if (tl.elapsedTicks >= tl.intervalSeconds) {
              tl.cb()
              tl.elapsedTicks = 0
            }
          })
        },
      },
    }
  })
  .actions((self) => ({
    async checkForUpdate(): Promise<{
      isAvailable: boolean
      version?: string
    }> {
      if (self.rootStore.view.isDebugEnabled('update')) {
        return { isAvailable: true, version: 'v1.2.3' }
      }

      return isUpdateAvailable().catch((e) => {
        self.log.error('error checking for update', e)
        return { isAvailable: false }
      })
    },
    updateNow() {
      self.now = new Date()
      const backgroundAgeSeconds = self.secondsSince(self.lastVisibilityChange)
      self.environment.datadog.setAppStatus({
        backgroundAgeSeconds: self.secondsSince(self.lastVisibilityChange),
        activityAgeSeconds: self.secondsSince(self.lastUserInteraction),
      })

      if (
        self.visibility === 'hidden' &&
        backgroundAgeSeconds > self.secondsUntilSleep &&
        self.rootStore.loggedIn
      ) {
        this.suspendApp()
        self.log.info('suspending app')
      }
    },
    handleUserActivity() {
      self.lastUserInteraction = new Date()
    },
    setVisibility(value: DocumentVisibilityState) {
      if (value !== self.visibility) {
        self.visibility = value
        self.lastVisibilityChange = new Date()
        self.environment.datadog.setVisibility(value)
      }
    },
    runClockTick() {
      this.updateNow()
      self.updateListeners()
      if (getActivation().isActive) {
        this.handleUserActivity()
      }
    },
    startInterval() {
      if (self.interval === undefined) {
        self.interval = window.setInterval(this.runClockTick, 1000)
      }
      this.runClockTick()
    },
    setResuming(value: boolean) {
      self.resuming = value
    },
    setSuspended(value: boolean) {
      self.suspended = value
      self.resuming = false
    },
    suspendApp() {
      if (!self.suspended) {
        this.setSuspended(true)
        if (self.interval !== undefined) {
          clearInterval(self.interval)
          self.interval = undefined
        }
        self.rootStore.socketManager.disconnect()
      }
    },
    async ensureConnected(): Promise<{
      connected: boolean
      loggedIn: boolean
    }> {
      try {
        const authStatus = await self.rootStore.authManager.getAuthStatus()
        if (authStatus.loggedIn) {
          await self.rootStore.socketManager.connect()
        }
        return {
          connected: true,
          loggedIn: authStatus.loggedIn,
        }
      } catch {
        return {
          connected: false,
          loggedIn: false,
        }
      }
    },
    async resumeApp(): Promise<void> {
      this.setResuming(true)
      const { connected, loggedIn } = await this.ensureConnected()

      // alert user if not connected and retry when they click ok
      if (!connected) {
        this.setResuming(false)
        await notifyOffline()
        // stick a delay in so it looks like we did something
        this.setResuming(true)
        await delay(3)
        // recurse
        return this.resumeApp()
      }

      // if connected but not logged in or out of date, reload
      // the app
      if (!loggedIn || (await this.checkForUpdate())) {
        return window.location.reload()
      }

      this.startInterval()

      // If we've got a script loaded and it's out of sync, give it 2s
      // before we remove the blur. If we're still out of sync after that
      // the sync status can handle it
      const syncStatus = self.rootStore.currentScript?.syncStatus
      if (syncStatus && syncStatus.secondsOfStaleness > 1) {
        await delay(2)
      }
      this.setSuspended(false)
    },
  }))
  .actions((self) => ({
    afterAttach: () => {
      self.startInterval()

      document.addEventListener('visibilitychange', async () => {
        const { visibilityState } = document
        self.setVisibility(visibilityState)
        // if we've gone from hidden to visible we always check for a new
        // version of the app where that happens, varies based on whether
        // we were suspended
        if (visibilityState === 'visible') {
          if (self.suspended) {
            self.resumeApp()
          } else {
            const { isAvailable, version } = await self.checkForUpdate()
            if (isAvailable) offerUpdate(version)
          }
        }
      })

      // if the user is scrolling with a trackpad or mouse wheel,
      // the navigator.userActivation doesn't update, so this catches that
      // case.
      document.addEventListener(
        'scrollend', // 'scroll' fires continuously so we use 'scrollend' instead
        () => self.handleUserActivity(),
        true,
      )
      self.setVisibility(document.visibilityState)
    },
  }))
