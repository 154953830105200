import { compareDesc, isAfter, subDays } from 'date-fns'
import { v4 as isUUID } from 'is-uuid'
import { autorun } from 'mobx'
import { detach, SnapshotIn, types } from 'mobx-state-tree'

import { ScriptStatus } from '@showrunner/codex'

import {
  IFolder,
  IListing,
  IOpenedListing,
  isOpenedListing,
  ListingId,
} from '@state/types'
import { delay } from '@util'
import { APP_STARTED } from '@util/mixpanel/eventNames'
import { extractResourceParameter } from '@util/pathConfigs'
import type {
  FindListingsPayload,
  FolderListPayload,
  GetFolderPayload,
  InvitePayload,
  RundownPayload,
  ScriptPayload,
  UserPayload,
} from '@util/ScriptoApiClient/types'

import { BaseModel } from '../BaseModel'
import { Folder, sortAlphaFn } from '../Folder'
import { InkProject } from '../InkProject'
import { LoadedScript } from '../LoadedScript'
import { Location } from '../Location'
import { Org } from '../Org'
import { createRundownInstance, Rundown } from '../Rundown/Rundown'
import { RundownListing } from '../RundownListing'
import { ScriptListing } from '../ScriptListing'
import { SocketManager } from '../SocketManager'
import { User } from '../User'
import { sortAlphaByNameFn, sortByCreationFn, sortRecentFn } from '../util'
import { View } from '../View'

import { AppStatus } from './AppStatus'
import { AuthManager } from './AuthManager'

type RootFolders = {
  sharedDashboard: IFolder
  sharedTrash: IFolder
  privateDashboard: IFolder
  privateTrash: IFolder
}

// the root store-- single instance created for
// the app.
export const Root = BaseModel.named('Root')
  .props({
    // Loading is true when we don't know if the user is authenticated or if we need
    // to reset user data (like when the org list changes).
    loading: true,
    // hydratingOrg === true if an org has been selected but before it
    // has sufficent data to render org-specific UI
    hydratingOrg: true,
    serverDown: false,
    user: types.optional(User, {}),
    currentOrg: types.maybe(Org),
    view: types.optional(View, {}),
    folderMap: types.map(Folder),
    rundownMap: types.map(RundownListing),
    scriptMap: types.map(ScriptListing),
    currentScript: types.maybe(LoadedScript),
    currentRundown: types.maybe(Rundown),
    currentInkProject: types.maybe(InkProject),
    // convenience prop to display designs in progress in storybook
    comingSoon: false,
    location: types.optional(Location, {}),
    launchInvitation: types.maybe(types.frozen<InvitePayload | 'invalid'>()),
    socketManager: types.optional(SocketManager, {}),
    appStatus: types.optional(AppStatus, {}),
    authManager: types.optional(AuthManager, {}),
  })
  .views((self) => ({
    get loggedIn() {
      return self.user.id !== ''
    },
    get rootFolders(): Partial<RootFolders> {
      const result: Partial<RootFolders> = {}
      Array.from(self.folderMap.values()).forEach((f) => {
        if (f.id !== f.parentId) return
        if (!f.inTrash && !f.isPrivate) result.sharedDashboard = f
        if (f.inTrash && !f.isPrivate) result.sharedTrash = f
        if (!f.inTrash && f.isPrivate) result.privateDashboard = f
        if (f.inTrash && f.isPrivate) result.privateTrash = f
      })
      return result
    },
    getRootFolder(isPrivate: boolean, inTrash: boolean) {
      return Array.from(self.folderMap.values()).find(
        (f) =>
          f.isPrivate === isPrivate &&
          f.inTrash === inTrash &&
          f.id === f.parentId,
      )
    },
    get currentFolder(): IFolder | undefined {
      return self.folderMap.get(self.view.selectedFolderId)
    },
    getChildFolders(parentId: string) {
      return Array.from(self.folderMap.values())
        .filter((item) => item.parentId === parentId && item.id !== parentId)
        .sort(sortAlphaFn)
    },
    getRundownsInFolder(folderId: string) {
      return Array.from(self.rundownMap.values()).filter(
        (item) => item.folderId === folderId,
      )
    },
    getScriptsInFolder(parentId: string) {
      return Array.from(self.scriptMap.values()).filter(
        (item) => item.folderId === parentId,
      )
    },
    getDocumentsInFolder(folderId: string): IListing[] {
      return [
        ...this.getRundownsInFolder(folderId),
        ...this.getScriptsInFolder(folderId),
      ]
    },
    getFolderPath(folderId: string): IFolder[] {
      const result: IFolder[] = []
      let currentFolder: IFolder | undefined = self.folderMap.get(folderId)
      while (currentFolder) {
        result.unshift(currentFolder)
        currentFolder = currentFolder.isRootFolder
          ? undefined
          : self.folderMap.get(currentFolder.parentId)
      }
      return result
    },
    sortListings(listings: IListing[], key: 'favorites' | 'recentlyEdited') {
      switch (self.user.getFolderSortOrder(key)) {
        case 'alphabetical':
          return listings.sort(sortAlphaByNameFn)
        case 'newest':
          return listings.sort(sortByCreationFn)
        case 'oldest':
          return listings.sort(sortByCreationFn).reverse()
        default:
          return listings
      }
    },
    get nodeEnv(): 'development' | 'test' | 'production' {
      return self.environment.config.NODE_ENV
    },
    get listings(): IListing[] {
      return [
        ...Array.from(self.scriptMap.values()),
        ...Array.from(self.rundownMap.values()),
      ].sort(sortRecentFn)
    },
    get recentListings(): IListing[] {
      const since = subDays(new Date(), 30)
      return this.sortListings(
        this.listings.filter(
          ({ inTrash, contentsModifiedAt }) =>
            !inTrash && isAfter(contentsModifiedAt, since),
        ),
        'recentlyEdited',
      )
    },
    get favoriteListings(): IListing[] {
      return this.sortListings(
        this.listings.filter((listing) => listing.isFavorite),
        'favorites',
      )
    },
    get myHistoryListings(): IOpenedListing[] {
      const rundownListings = Array.from(self.rundownMap.values()).filter(
        isOpenedListing,
      ) as IOpenedListing[]
      const scriptListings = Array.from(
        Array.from(self.scriptMap.values()).filter(isOpenedListing),
      ) as IOpenedListing[]

      return [...rundownListings, ...scriptListings].sort((a, b) =>
        compareDesc(a.openedAt, b.openedAt),
      )
    },
    resolveAccessLevel({
      folderId,
      status,
    }: {
      folderId: string
      status: string
    }): ScriptStatus {
      const folder = self.folderMap.get(folderId)
      if (folder?.isPrivate) {
        return 'PRIVATE'
      }
      if (status === 'LIMITED') {
        return 'LIMITED'
      }
      return 'OPEN'
    },
  }))
  .views((self) => ({
    getTrashChildFolders(): Array<IFolder> {
      const trashIds = [
        self.rootFolders?.sharedTrash?.id,
        self.rootFolders?.privateTrash?.id,
      ]
      return Array.from(self.folderMap.values())
        .filter((item) => {
          return trashIds.includes(item.parentId) && !trashIds.includes(item.id)
        })
        .sort(sortAlphaFn)
    },
    get ready(): boolean {
      return !(self.loading || self.hydratingOrg)
    },
    get launchInvitationGroupId(): string | undefined {
      if (self.launchInvitation && self.launchInvitation !== 'invalid') {
        return self.launchInvitation.group?.key.replace('group:', '')
      }
    },
  }))
  .actions((self) => ({
    setLaunchInvitation(value: InvitePayload | 'invalid') {
      self.launchInvitation = value
    },
    setLoading(loading: boolean) {
      self.loading = loading
    },
    setServerDown(val: boolean) {
      // self destruct
      self.serverDown = val
    },
    clearUser() {
      self.user = User.create({})
    },
    ingestRundown(payload: RundownPayload) {
      const isRequestedDocument = self.view.isRequestedDocument({
        id: String(payload.id),
        type: 'rundown',
      })

      if (isRequestedDocument) {
        self.currentRundown = createRundownInstance(payload)
        self.view.handleDocumentLoaded({
          id: payload.id,
          type: 'rundown',
          folderId: payload.folderId,
        })
      }
    },
    setUser(payload: UserPayload) {
      self.user = User.create(payload)
    },
    setHydrating(value: boolean) {
      self.hydratingOrg = value
    },
    setCurrentOrg(payload: SnapshotIn<typeof Org>) {
      self.currentOrg = Org.create(payload)
    },
    ingestGetFolderPayload(payload: GetFolderPayload) {
      self.folderMap.put({
        id: payload.id,
        parentId: payload.parentId,
        name: payload.name,
        inTrash: payload.inTrash,
        isPrivate: payload.isPrivate,
      })
      // The payload.files and payload.folders don't contain
      // the top-level folderId, inTrash and isPrivate all come from the
      // the parent payload so start creating enriched snapshots by splicing
      // in the needed parent data
      const {
        id: parentId,
        inTrash,
        isPrivate,
        folders = [],
        rundownListings,
        scriptListings,
      } = payload

      const childFolderSnapshots: SnapshotIn<typeof Folder>[] = folders.map(
        (item) => ({
          ...item,
          parentId,
          inTrash,
          isPrivate,
        }),
      )

      // child folders or scripts may have been removed so start finding any Ids
      // in the current values not in this payload and removing
      const scriptIdsToRemove = self
        .getScriptsInFolder(payload.id)
        .filter((item) => !scriptListings.find((f) => f.id === item.id))
        .map(({ id }) => id)
      scriptIdsToRemove.forEach((id) => self.scriptMap.delete(id))

      const folderIdsToRemove = self
        .getChildFolders(payload.id)
        .filter((item) => !childFolderSnapshots.find((f) => f.id === item.id))
        .map(({ id }) => id)
      folderIdsToRemove.forEach((id) => self.folderMap.delete(id))

      const rundownIdsToRemove = self
        .getRundownsInFolder(payload.id)
        .filter((item) => !rundownListings.find((r) => r.id === item.id))
        .map(({ id }) => id)
      rundownIdsToRemove.forEach((id) => self.rundownMap.delete(String(id)))

      // now merge in changes from the remaining children
      childFolderSnapshots.forEach((snap) => self.folderMap.put(snap))
      this.ingestListings({
        scriptListings,
        rundownListings,
      })
    },
    // process folders from GET /folders/shared or similar
    ingestFolderList(payload: FolderListPayload) {
      payload.forEach((folderPayload) => self.folderMap.put(folderPayload))
    },
    ingestListings({ rundownListings, scriptListings }: FindListingsPayload) {
      scriptListings.forEach((snap) => self.scriptMap.put(snap))
      rundownListings.forEach((snap) => self.rundownMap.put(snap))
    },
    removeCurrentScript() {
      if (self.currentScript) {
        self.currentScript.tearDownEditor()
        detach(self.currentScript)
        self.currentScript = undefined
      }
    },
    removeCurrentRundown() {
      if (self.currentRundown) {
        detach(self.currentRundown)
        self.currentRundown = undefined
      }
    },
    removeScriptFromState(scriptId: string) {
      if (self.currentScript?.id === scriptId) {
        self.currentScript = undefined
      }
      self.scriptMap.delete(scriptId)
    },
    ensureCurrentScript(payload: ScriptPayload) {
      if (self.currentScript && self.currentScript.id === payload.id) {
        return self.currentScript
      }

      if (self.currentScript && self.currentScript.id !== payload.id) {
        self.log.errorOnce(
          'loading script with another script loaded',
          {
            newId: payload.id,
            oldId: self.currentScript.id,
          },
          'root-ensure-current-script',
        )
        this.removeCurrentScript()
      }

      self.currentScript = LoadedScript.create(payload)
      self.view.handleDocumentLoaded({
        id: self.currentScript.id,
        type: 'script',
        folderId: self.currentScript.folderId,
      })

      return self.currentScript
    },

    // this is called when switching orgs. We switch to the loading view so no
    // components that depend on org-specific resources are available, then this
    // is called when view detects we enter the loading state
    resetOrgState() {
      self.scriptMap.clear()
      self.folderMap.clear()
      self.currentOrg = undefined
      self.view.selectedFolderId = ''
      self.view.requestedDocument = undefined
      self.view.expandedFolders.clear()
    },
    initializeInkProject() {
      self.currentInkProject = InkProject.create()
    },
  }))
  // async actions cannot set properties (see https://mobx-state-tree.js.org/concepts/async-actions)
  // and separating the action blocks gives us better typescript support
  .actions((self) => ({
    async logout() {
      self.setLoading(true)
      await self.authManager.logout()
    },
    async loadOrgAndFolders(orgId: string) {
      self.setHydrating(true)
      const [org, { folders }] = await Promise.all([
        self.apiClient.getOrg(orgId),
        self.apiClient.getOrgFolders(orgId),
      ])
      self.ingestFolderList(folders)
      self.setCurrentOrg(org)
      self.setHydrating(false)
    },
    async initializeAuthenticatedUser() {
      self.setLoading(true)
      self.setHydrating(true)

      const authCheck = await self.authManager.getAuthStatus()
      if (authCheck.loggedIn) {
        const userData = await self.apiClient.getUser(authCheck.userId)
        if (userData) {
          self.environment.localPersistence.markLoggedIn(true)
          self.setUser(userData)
          const orgId = await this.findInitialWorkspaceId()
          if (orgId) {
            self.user.selectMembership(orgId)
            await this.loadOrgAndFolders(orgId)
          }
          self.socketManager.connectOnAuthReady()
          self.analytics.identifyUser(userData, self.currentOrg)
          self.trackEvent(APP_STARTED)
          this.updateIntercomUser()
          self.log.info('session start')
        }
      }
      self.setLoading(false)
      self.setHydrating(false)
    },
    /*
      Find an appropriate workspace for the user based on the following (in priority order).
      For any of these, we first check that the orgId is one of the known memberships
      1. If the app was launched with a URL for a folder, script or rundown use
         the orgId associated with that resource
      2. The currentOrgId from the users localstorage preferences. That's the one last
         accessed in the current browser.
      3. If the user belongs to an org, pick the first one
    */
    async findInitialWorkspaceId(): Promise<string | undefined> {
      const { orgMemberships } = self.user
      const resourceParams = extractResourceParameter(location.pathname)
      const orgIdForResource = resourceParams
        ? (
            await self.apiClient
              .getOrgIdForResource(resourceParams)
              .catch(() => {
                // deliberate noop, just a best attempt
              })
          )?.orgId
        : undefined
      if (self.user.belongsToOrg(orgIdForResource)) {
        return orgIdForResource
      }

      const localStorageOrgId = self.user.prefs.currentOrgId
      if (self.user.belongsToOrg(localStorageOrgId)) {
        return localStorageOrgId
      }

      return orgMemberships[0]?.orgId
    },
    // switch the current org then reload the app
    async switchOrgAndRelaunch(orgId: string) {
      self.user.selectMembership(orgId)
      window.location.replace('/')
    },
    updateIntercomUser() {
      if (window.Intercom) {
        const { user } = self
        if (user.intercomHash && user.created) {
          // https://developers.intercom.com/installing-intercom/docs/intercom-javascript#intercomupdate
          window.Intercom('update', {
            name: user.name,
            email: user.email,
            show: user.currentOrgName,
            user_hash: user.intercomHash,
            created: user.created.getTime(),
          })
        }
      }
    },
    async ping() {
      try {
        const pong = await self.apiClient.ping()
        if (pong === 'pong') return true
        return false
      } catch {
        return false
      }
    },
    // This kicks off the app. If we have valid credentials in cookies, bootstrap
    // the choo app, otherwise, set loading and hydrating to false and let react manage
    // the pre-auth flows.
    async bootstrap() {
      const pong = await this.ping()

      // we can't use ?debug= flags in bootstrap because they only work if the
      // user is staff and we don't have a user yet, so we cheat:
      const debugParams = new URLSearchParams(window.location.search)
        .get('debug')
        ?.split(',')
      const fatal = debugParams?.includes('fatal')

      if (fatal || !pong) {
        self.setServerDown(true)
        return
      }

      await this.initializeAuthenticatedUser()
    },
    // More selective way to refresh the folder tree. Used when we suspect that
    // folders have moved around after an initial org load.
    async loadFolderTree(orgId: string) {
      const { folders } = await self.apiClient.getOrgFolders(orgId)
      self.ingestFolderList(folders)
    },
    async refreshRecentListings() {
      if (self.currentOrg) {
        const listings = await self.apiClient.getRecentListings({
          orgId: self.currentOrg.id,
        })
        self.ingestListings(listings)
      }
    },
    async refreshListingsById(ids: ListingId[], orgId: string) {
      const rundownIds: number[] = []
      const scriptIds: string[] = []
      ids.forEach((id) => {
        if (typeof id === 'string' && isUUID(id)) {
          scriptIds.push(id)
        } else if (typeof id === 'number') {
          rundownIds.push(id)
        }
      })

      const listings = await self.apiClient.getListingsById({
        rundownIds,
        scriptIds,
        orgId,
      })
      self.ingestListings(listings)
    },
    async refreshMyHistoryListings() {
      if (self.currentOrg) {
        this.refreshListingsById(
          self.currentOrg.myHistoryListingIds,
          self.currentOrg.id,
        )
      }
    },
    async refreshFavoriteListings() {
      if (self.currentOrg) {
        this.refreshListingsById(
          self.currentOrg.favoriteListingIds,
          self.currentOrg.id,
        )
      }
    },
    async refreshFolder(folderId: string) {
      // folders may appear in our org that we haven't loaded before,
      // so if they don't exist, reload the current org
      const existingFolder = self.folderMap.get(folderId)
      if (!existingFolder && self.currentOrg) {
        await this.loadFolderTree(self.currentOrg.id)
      }
      // we might still not have the folder-- could be missing/in the wrong
      // org, etc. If we have it, lets try reload the details
      self.folderMap.get(folderId)?.load()
    },
    async doDebug(seconds = 2) {
      if (self.view.isDebugEnabled('slow')) {
        await delay(seconds)
      }
      if (self.view.isDebugEnabled('fail')) {
        throw new Error('oopsie')
      }
    },
    async requestScript(id: string) {
      const payload = await self.apiClient.getScript(id)
      return self.ensureCurrentScript(payload)
    },
    async requestRundown(id: string) {
      const payload = await self.apiClient.getRundown(id)
      self.ingestRundown(payload)
      return self.currentRundown ?? null
    },
    handleStorageEvent(ev: StorageEvent) {
      if (self.environment.localPersistence.isScriptoPreferenceEvent(ev)) {
        // this first one is technically async. we'll yank it
        // here when the info is truly stored on the backend
        self.currentOrg?.refreshMyHistory()
        self.currentOrg?.refreshFavorites()
        self.currentRundown?.refreshColumnPrefs('print')
        self.user.refreshSyncedPrefs()
      }
    },
  }))
  .actions((self) => ({
    afterCreate() {
      autorun(() => {
        if (self.currentScript?.isInk && !self.currentInkProject) {
          self.initializeInkProject()
        }
      })
    },
  }))
