import React from 'react'

import { useForm } from 'react-hook-form'

import { useMst } from '@state'
import { BetaFlag, OrgPayload } from '@util/ScriptoApiClient/types'

type Inputs = Partial<Record<string, boolean>>

const hasFlag = (org: OrgPayload, flagName: string): boolean => {
  return org.betaFlags.some((name) => name === flagName)
}

const buildInputsFromFlags = (flags: BetaFlag[], org: OrgPayload): Inputs => {
  const result: Inputs = {}
  flags.forEach(({ name }) => {
    result[name] = hasFlag(org, name)
  })

  return result
}

export function BetaFlagsForm({
  org,
  betaFlags,
}: {
  org: OrgPayload
  betaFlags: BetaFlag[]
}) {
  const { apiClient } = useMst()
  const currentValues = buildInputsFromFlags(betaFlags, org)
  const { register, watch, resetField } = useForm({
    defaultValues: {
      ...currentValues,
    },
  })

  const toggleFlag = async (flagName: string, enable: boolean) => {
    try {
      const flagId = betaFlags.find((f) => f.name === flagName)?.id
      if (flagId) {
        await apiClient.toggleBetaFlagByStaff({
          orgId: org.id,
          flagId,
          enable,
        })
      }
      resetField(flagName, { defaultValue: enable })
    } catch (e) {
      alert('Server error, see browser console')
      // eslint-disable-next-line no-console
      console.error(e)
      resetField(flagName)
    }
  }

  React.useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (name && type === 'change') {
        const enable = !!data[name]
        // only ask for confirmation when disabling
        let confirmed = true
        if (!enable) {
          const message = `Are you sure you want to disable ${name} for this workspace? ${type}`
          confirmed = window.confirm(message)
        }
        if (confirmed) {
          toggleFlag(name, enable)
        } else {
          resetField(name)
        }
      }
    })
    return () => subscription.unsubscribe()
  })

  return (
    <form className="s-form s-form--inline">
      {betaFlags
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((f) => (
          <div className="option-setting" key={f.id}>
            <input id={f.name} type="checkbox" {...register(f.name)} />
            <label htmlFor={f.name}>{f.name}</label>
          </div>
        ))}
    </form>
  )
}
