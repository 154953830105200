import React from 'react'

import * as AgGrid from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import '@ag-grid-enterprise/core'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { useFloatingMenu } from '@hooks'
import { useMst } from '@state'
import { IRundown } from '@state/types'
import { EnrichedRowData, isGridBlobKey } from '@util/rundowns'

import { DEFAULT_COLUMN_DEF, getScreenColumnDefs } from './columns'
import { DropTargetWrapper } from './DropTargetWrapper'
import {
  getRowId,
  handleCellMouseDown,
  processCellForExport,
  processDataFromClipboard,
  sendToClipboard,
} from './eventHandlers'
import { rowClassRules } from './helpers/rowClassRules'
import { useRundownOperations } from './useRundownOperations'
import './RundownGridOverrides.scss'

export type RundownGridProps = {
  userCanEdit: boolean
  rundown: IRundown
  className?: string
}

// This component is the rundown grid itself, without any drag-over script detection
const RundownGridBase = observer(function RundownGridBase({
  rundown,
  className,
  userCanEdit,
}: RundownGridProps) {
  const { user } = useMst()
  const wrapText = !!user.prefs.wrapRundownText
  const classes = cn(className, 'ag-theme-alpine')
  const operations = useRundownOperations(rundown)
  const { handleContextMenu } = useFloatingMenu()

  const [rowData, setRowData] = React.useState(rundown.rowDataForGrid)
  React.useEffect(() => {
    setRowData(rundown.rowDataForGrid)
  }, [rundown.rowDataForGrid])

  // we need to refresh cells in the grid when certain rundown-wide
  // values change
  React.useEffect(() => {
    rundown.gridRef.current?.api?.refreshCells()
  }, [rundown.blobData.hash, rundown.gridRef])

  const handleCellValueChange = async (
    ev: AgGrid.CellValueChangedEvent<EnrichedRowData>,
  ) => {
    const { node, newValue, column, oldValue, colDef } = ev
    const rowId = node.data?.id

    if (colDef.equals?.(newValue, oldValue)) return
    if (typeof rowId !== 'number') {
      return
    }
    const { field: columnKey } = column.getColDef()

    // blob edits and non-blob edits are different. We
    // don't handle non-blob edits yet.
    if (!(columnKey && isGridBlobKey(columnKey))) {
      return
    }

    return operations.updateCellValue({
      rowId,
      columnKey,
      value: newValue,
    })
  }

  // handler for when a row drag operation ends. The grid has already moved the
  // rows locally but we need to figure out which rows moved to where to update the
  // server
  const handleRowDragEnd = async (e: AgGrid.RowDragEvent<EnrichedRowData>) => {
    // nodes are the rows that were dragged, overNode is which row was under the
    // cursor when the drag ended. y is the y-coord relative to the grid where dropped
    const { nodes, overNode, y } = e
    if (overNode && nodes.length > 0 && overNode.data) {
      const { rowTop, rowHeight } = overNode
      // look at the y and the overNode to see if we were on the top or bottom half of the
      // target
      const moveBelow =
        typeof rowTop === 'number' &&
        typeof rowHeight === 'number' &&
        y > rowTop + rowHeight / 2
      const rowIds: number[] = nodes.map((n) => n.data?.id || -1)
      const sequence = overNode.data.sequence + (moveBelow ? 1 : 0)
      operations.moveRowsToPosition({ rowIds, sequence })
    }
  }

  // It's important to memoize or use state for the column definitions
  // https://www.ag-grid.com/react-data-grid/react-hooks/
  const [columnDefs, setColumnDefs] = React.useState<AgGrid.ColDef[]>(
    getScreenColumnDefs({
      rundown,
      userCanEdit,
      wrapText,
    }),
  )
  // We need to regenerate the column defs in a few different situations but
  // we need to be selective or well destroy AgGrid perf
  React.useEffect(() => {
    setColumnDefs(
      getScreenColumnDefs({
        rundown,
        userCanEdit,
        wrapText: !!user.prefs.wrapRundownText,
      }),
    )
  }, [userCanEdit, user.prefs.wrapRundownText, rundown.schema.name, rundown])

  // When users resize/show/hide/move columns, we save the new settings
  // as a user preference but we don't want to rerender (the grid was the SOURCE
  // of the change)
  const handleColumnEvent = (e: AgGrid.ColumnEvent) => {
    rundown.saveColumnState(e.columnApi.getColumnState(), 'screen')
  }

  return (
    // This div sizes the grid overall
    <div className={classes} key={rundown.id} onContextMenu={handleContextMenu}>
      {/* This is the actual Grid component */}
      <AgGridReact<EnrichedRowData>
        ref={rundown.gridRef}
        enterNavigatesVerticallyAfterEdit
        rowClassRules={rowClassRules}
        getRowId={getRowId}
        context={rundown}
        columnDefs={columnDefs}
        defaultColDef={DEFAULT_COLUMN_DEF}
        rowData={rowData}
        // only hijack context menu with control key in production, otherwise it's
        // really hard to inspect the dom
        allowContextMenuWithControlKey={
          rundown.rootStore.nodeEnv === 'production'
        }
        suppressContextMenu
        tooltipShowDelay={400}
        suppressRowClickSelection
        rowSelection="multiple"
        // row dragging/moving
        animateRows
        rowDragMultiRow
        onRowDragEnd={handleRowDragEnd}
        suppressMoveWhenRowDragging
        rowDragManaged
        // editing
        onCellEditingStarted={() => {
          rundown.setGridEditing(true)
        }}
        onCellEditingStopped={() => {
          rundown.setGridEditing(false)
        }}
        stopEditingWhenCellsLoseFocus
        // user-driven column reconfiguration
        onColumnMoved={handleColumnEvent}
        onColumnVisible={handleColumnEvent}
        onColumnResized={(e) => {
          if (e.finished) {
            handleColumnEvent(e)
          }
        }}
        onCellMouseDown={handleCellMouseDown}
        // editing
        onCellValueChanged={handleCellValueChange}
        onRowDataUpdated={rundown.restoreFocus}
        processCellForClipboard={processCellForExport}
        processDataFromClipboard={processDataFromClipboard}
        sendToClipboard={sendToClipboard}
        onCellFocused={rundown.updateFocusedRow}
      />
    </div>
  )
})

// Export the base grid wrapped in the DropTargetWrapper which will
// handle script drag/drop into the grid. All internal-to-grid row dragging is
// handled by AgGrid itself.
export const RundownGrid = (props: RundownGridProps) => (
  <DropTargetWrapper rundown={props.rundown}>
    <RundownGridBase {...props} />
  </DropTargetWrapper>
)
