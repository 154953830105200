import React from 'react'

import cn from 'classnames'

import { useDebouncedElementSize } from '@hooks'

import styles from './PrintPreviewWrapper.module.scss'

// page editor is 8.5" inches
const PAGE_WIDTH_PX = 8.5 * 96
// we put 25px padding on either side for avatars on left & balance on right
const PAGE_AFFORDANCE_PX = 50
// this is the what the print preview wrapper needs to wrap. It's scaled by the
// zoom level.
const UNSCALED_EDITOR_WIDTH = PAGE_WIDTH_PX + PAGE_AFFORDANCE_PX

export const PrintPreviewWrapper = ({
  zoomLevel,
  children,
  isStructural,
}: {
  zoomLevel: number
  children: React.ReactNode
  isStructural: boolean
}) => {
  const { ref: boundingRef, width: boundingWidth } = useDebouncedElementSize()
  // Because we're scaling the editor within the scroller, mantine's hook
  // doesn't calculate its width properly, but we can use getBoundingClientRec
  // instead
  const editorWidth = UNSCALED_EDITOR_WIDTH * zoomLevel
  // const freeHorizontalSpace = scrollerWidth - editorWidth
  // const paddingLeft = freeHorizontalSpace > 0 ? freeHorizontalSpace / 2 : 0
  const scrollNeeded = editorWidth > boundingWidth
  // css is weirdly different for zoom shrinking vs magnification,
  // so we scale the containers differently
  const shrunk = zoomLevel < 1

  return (
    <div
      ref={boundingRef}
      style={{
        '--unscaled-editor-width': `${UNSCALED_EDITOR_WIDTH}px`,
      }}
      className={cn(styles.printPreviewWrapper, {
        [styles.structuralPagination]: isStructural,
        [styles.inlinePagination]: !isStructural,
      })}
    >
      <div
        className={cn(styles.scriptScrollWrapper, {
          [styles.scroll]: scrollNeeded,
          [styles.shrunk]: shrunk,
        })}
        data-editor-scroller
      >
        <div className={cn(styles.scriptCenteringBox)}>
          <div className={styles.scaled}>{children}</div>
        </div>
      </div>
    </div>
  )
}
