import { joinBackward } from 'prosemirror-commands'
import { Node as PmNode } from 'prosemirror-model'
import { TextSelection } from 'prosemirror-state'

import { Command, defaultBlockType } from '@util/prosemirrorHelpers'

// TODO: we should export this from codex
const PAGE_BREAK_TYPE = {
  manual: 'manual',
  dynamic: 'dynamic',
}

const isManualPageBreak = (node: PmNode) => {
  return node.attrs.pageBreak === PAGE_BREAK_TYPE.manual
}

// When we create a page break, we do not split the block or delete selections,
// instead we put a page break above the block. If the block is already marked
// as pageBreak=manual, we create a new block above it with it being a
// manual page break as well.
const create: Command = (editorState, dispatch) => {
  const { $from } = editorState.selection

  const targetBlock = $from.node(2)
  const pos = $from.start(2)

  if (dispatch) {
    const { tr } = editorState
    // if we're already in a manual page break block, create a new block of the
    // default type above the current selection and make IT a page break as well,
    // otherwise just mark the current depth 2 node as being a manual page break
    if (isManualPageBreak(targetBlock)) {
      tr.setSelection(TextSelection.create(tr.doc, pos))
      tr.replaceSelectionWith(
        defaultBlockType.create({
          pageBreak: 'manual',
        }),
        false,
      )
    } else {
      tr.setNodeMarkup(pos - 1, undefined, {
        ...targetBlock.attrs,
        pageBreak: PAGE_BREAK_TYPE.manual,
      })
    }
    dispatch(tr)
  }

  return true
}

const remove: Command = (editorState, dispatch) => {
  const { selection } = editorState
  const { $from } = selection
  const targetBlock = $from.node(2)
  const pos = $from.start(2)

  const shouldRemovePageBreak =
    selection instanceof TextSelection &&
    selection.$cursor &&
    // check to see if joinBackward would normally happen if called
    joinBackward(editorState) &&
    isManualPageBreak(targetBlock)

  if (!shouldRemovePageBreak) {
    return false
  }

  if (dispatch) {
    const { tr } = editorState
    tr.setNodeMarkup(pos - 1, undefined, {
      ...targetBlock.attrs,
      pageBreak: null,
    })
    dispatch(tr)
  }

  return true
}

export const inlinePageBreak = {
  create,
  remove,
}
