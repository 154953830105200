import { useMutation, useQuery } from '@tanstack/react-query'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { useMst } from '@state'
import { noop } from '@util'
import { SHOW_CREATED } from '@util/mixpanel/eventNames'

export type TemplateWorkspaceListing = ZInfer<
  typeof schemas.TemplateWorkspaceListing
>

export type NewWorkspaceFormValues = {
  name: string
  templateCode: string
  templateName: string
  email: string
  email2: string
}

const GET_TEMPLATES_QUERY_KEY = ['templates-workspaces']

export const useActiveTemplates = () => {
  const { scrapi } = useMst()

  const { data, isLoading, error } = useQuery({
    queryFn: async () => {
      const result = await scrapi.workspaces.getTemplates()
      if (result.status !== 200) {
        throw new Error('Error fetching templates', { cause: result })
      }
      return result
    },
    queryKey: GET_TEMPLATES_QUERY_KEY,
  })

  const list: TemplateWorkspaceListing[] = data ? data.body.templates : []
  return {
    isLoading,
    error,
    list,
  }
}

export const useCreateWorkspaceMutation = () => {
  const { scrapi, apiClient, switchOrgAndRelaunch, doDebug, trackEvent, user } =
    useMst()

  return useMutation(
    async ({
      templateCode,
      templateName,
      name,
      email,
      email2,
    }: NewWorkspaceFormValues): Promise<void> => {
      await doDebug()
      const result = await scrapi.workspaces.create({
        body: { name, templateCode },
      })
      if (result.status !== 201) {
        throw new Error('Error creating workspace', { cause: result })
      }
      const workspaceId = result.body.id

      const emailsToInvite: string[] = [email, email2]
        .map((e) => e.toLowerCase().trim())
        .filter((e) => e.length > 0)

      await Promise.all(
        emailsToInvite.map((email) =>
          // try to send the invites but noop if this part fails, we don't have
          // any helpful UI for this case
          apiClient.createOrgInvite({ orgId: workspaceId, email }).catch(noop),
        ),
      )

      const invites: string[] = [email, email2].filter(
        (item) => item.length > 0,
      )
      trackEvent(SHOW_CREATED, {
        id: workspaceId,
        name,
        template: templateCode,
        templateName,
        userEmail: user.email,
        userName: user.name,
        invites,
      })

      // switch org
      switchOrgAndRelaunch(result.body.id)
    },
  )
}
