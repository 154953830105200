import cn from 'classnames'
import { Decoration } from 'prosemirror-view'

import { PaginationBreakdown } from '@util/pagination'

export const PAGE_WIDGET_ORDER = {
  ensureNewline: 0,
  bottomOfPage: 1,
  topOfPage: 2,
}

// create a widget that goes at the top of each page and provides the
// fake top of page look-and-feel
export const pageTopWidget = ({
  pos,
  pageNumber,
  isManualBreak,
}: {
  pos: number
  pageNumber?: string
  isManualBreak: boolean
}): Decoration => {
  const elt = document.createElement('div')
  elt.className = cn('top-of-page', { 'manual-break': isManualBreak })

  if (pageNumber !== undefined) {
    elt.setAttribute('style', `--page-number: '${pageNumber}'`)
  }

  return Decoration.widget(pos, elt, {
    side: PAGE_WIDGET_ORDER.topOfPage,
  })
}

// this widget for the page bottoms sets a css var for how much blank space
// is at the bottom of the page so the widget can be styled properly
const pageBottomWidget = ({
  pos,
  heightShortfall,
}: {
  pos: number
  heightShortfall: number
}): Decoration => {
  const elt = document.createElement('div')
  elt.className = 'bottom-of-page'
  elt.setAttribute('style', `--page-height-shortfall: ${heightShortfall}em`)

  return Decoration.widget(pos, elt, {
    side: PAGE_WIDGET_ORDER.bottomOfPage,
  })
}

export const buildDecorations = (
  breakdown: PaginationBreakdown,
): Decoration[] => {
  const decorations: Decoration[] = []
  breakdown.decorationData.forEach(
    ({ startPos, endPos, heightShortfall, pageNumber, isManualBreak }) => {
      decorations.push(
        pageTopWidget({ pos: startPos, pageNumber, isManualBreak }),
      )
      decorations.push(
        pageBottomWidget({
          pos: endPos,
          heightShortfall,
        }),
      )
    },
  )
  return decorations
}

export const buildDesiredDecorations = (
  breakdown: PaginationBreakdown,
): Decoration[] => {
  const decorations: Decoration[] = []
  breakdown.decorationData.forEach(
    ({ startPos, endPos, heightShortfall, pageNumber, isManualBreak }) => {
      decorations.push(
        pageTopWidget({ pos: startPos, pageNumber, isManualBreak }),
      )
      decorations.push(
        pageBottomWidget({
          pos: endPos,
          heightShortfall,
        }),
      )
    },
  )
  return decorations
}
