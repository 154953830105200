import LinkifyIt from 'linkify-it'
import { setBlockType } from 'prosemirror-commands'
import { InputRule } from 'prosemirror-inputrules'
import { DOMParser, Fragment, Slice } from 'prosemirror-model'
import { Plugin, PluginKey } from 'prosemirror-state'

import { schema, types } from '@showrunner/codex'
import converter from '@showrunner/converter'

import {
  addLinkMarkToSelection,
  injectMarkedUpLinks,
  isSingleBlockTextSelection,
  selectionHasUrl,
} from '@util'
import { DatadogClient } from '@util/datadog'

import {
  findLinksAndSplit,
  isEmptyBlockSelection,
  isEndOfParentNode,
  lockedPagesInSelection,
  plainStudioToProse,
  STUDIO_BLOCKS,
  STUDIO_REGEX,
} from '../prose-utils.js'

import { extendSelection } from './editor-keymap/extend-selection'

const ddLog = DatadogClient.getInstance()

const { fountainToProse, fountainRegex } = converter

const linkify = new LinkifyIt()

const TRANSITION_RE = /^((cut|back|dissolve|fade) to:|fade (in:|out\.))$/i

const inputRegex = {
  [types.PARENTHETICAL]: /^\($/,
  [types.SCENE_HEADING]: /^(INT|EXT|EST|I\.?\/E|INT\.?\/EXT|EXT\.?\/INT)[. ]$/i,
  [types.TRANSITION]: TRANSITION_RE,
  [types.BRACKET]: /^\[$/,
  [types.CHARACTER]: /^\{\*\*\*$/,
}
const MAX_MATCH = 500
const SUPPORTED_FOUNTAIN = [
  'scene_heading',
  'transition',
  'character',
  'dialogue',
  'parenthetical',
  'page_break',
]

const COMMENT_RE = /data-thread-id="([a-zA-Z0-9-]*)"/gi

const SMART_DOUBLEQUOTE_RE = /[\u201C\u201D]|&ldquo;|&rdquo;/g
const SMART_SINGLEQUOTE_RE = /[\u2018\u2019]|&lsquo;|&rsquo;/g
const ELLIPSIS_RE = /[\u2026…]/g
const unicodeToReplace = [
  { re: SMART_DOUBLEQUOTE_RE, replacement: '"' },
  { re: SMART_SINGLEQUOTE_RE, replacement: "'" },
  { re: ELLIPSIS_RE, replacement: '...' },
]
/**
 * Assemble text input rules for the given document type.
 * @param {string} docType - script doc type
 * @return {array} text input rules
 */
function getTextInputRules(docType) {
  // same in both
  const rules = [types.PARENTHETICAL]
  // screenplay only
  if (docType === types.SCREENPLAY) {
    rules.push(types.SCENE_HEADING, types.TRANSITION)
  }
  // classic scripto only
  if (docType === types.CLASSIC) {
    rules.push(types.CHARACTER)
  }
  // both studio types
  if (docType === types.VARIETY || docType === types.CLASSIC) {
    rules.push(types.BRACKET)
  }
  // map types to regular expressions to produce an array of input rules
  // TODO: avoid setting the block type when it was already correct
  return rules.map((type) => setBlockTypeInputRule(inputRegex[type], type))
}
/**
 * Given a pattern match, find nearest block node and convert it to the given type.
 * @param {RegExp} regex - regular expression
 * @param {string} blockType - block type
 * @return {InputRule} block type input rule
 */
function setBlockTypeInputRule(regex, blockType) {
  return new InputRule(regex, (state, match, start) => {
    const $start = state.doc.resolve(start)
    const node = schema.nodes[blockType]
    if (
      !$start
        .node(-1)
        .canReplaceWith($start.index(-1), $start.indexAfter(-1), node)
    ) {
      return null
    }
    const { attrs } = $start.parent
    const tr = state.tr.setBlockType(start, start, node, attrs)
    if (blockType === types.PARENTHETICAL) {
      tr.insertText(')')
    }
    if (blockType === types.BRACKET) {
      tr.insertText(']')
    }
    if (blockType === types.CHARACTER) {
      tr.insertText('***}')
    }
    return tr
  })
}
/**
 * the plain text that comes out of Classic during copy is stripped
 * of formatting marks.
 *
 * we need to inspect the html itself to identify the appropriate block type.
 *
 * even if we leave the rats nest of wrapper divs, spans and
 * inline styles and append classnames from our schema directly in place
 * PM can still sift through it.
 *
 * @param {string} html stringified html
 * @return {string} stringified html
 */
function massageClassicHTML(html) {
  const dummyHTML = document.createElement('div')
  dummyHTML.innerHTML = html
  // loop through the NodeList backwards because its a live collection
  // otherwise a child might move into a previously held slot
  for (let i = dummyHTML.childNodes.length - 1; i >= 0; i--) {
    const n = dummyHTML.childNodes[i]
    if (n.id && n.id.includes('magicdom')) {
      // remove whitespace to ensure bracket/paren blocks are recognized when trailing spaces are present
      const text = n.innerText.trim()
      // omit blank lines
      if (text === '') {
        // retain empty dialogue node, but strip nested <br> if present
        // because we want one blank line, not two
        if (n.childElementCount > 0) {
          n.removeChild(n.firstElementChild)
        }
      }
      for (let i = 0; i <= STUDIO_BLOCKS.length; i++) {
        if (i === STUDIO_BLOCKS.length) {
          // default to dialogue
          n.classList.add('o-dialogue')
        } else if (text.match(STUDIO_REGEX[STUDIO_BLOCKS[i]])) {
          n.classList.add(`o-${STUDIO_BLOCKS[i]}`)
          break
        }
      }
    }
  }
  // thank you garbage collector
  return dummyHTML.innerHTML
}
// a tad nerdier than string.replaceAll(), but safer
const stripComments = (html) => html.replace(COMMENT_RE, '')
/**
 * if paste target is an empty block, change its type before delegating to PM
 *
 * @param {object} EditorView
 * @param {string} stringified html
 */
function changeFirstBlockType(view, html) {
  // if cursor isnt within an empty block, abort/delegate
  if (!isEmptyBlockSelection(view.state.selection)) {
    return false
  }
  // create a DOM node using the html string, but dont add it to the document
  const dummyHtml = document.createElement('div')
  dummyHtml.innerHTML = html
  // check paste payload and use it to extract the first (or only) block from the first page
  const dummyDoc = DOMParser.fromSchema(schema).parse(dummyHtml)
  const { type, attrs, isTextblock } = dummyDoc.child(0).child(0)

  // ensure we actually have a valid reference to a text block
  if (!isTextblock) {
    const msg = 'pm anomaly: paste attempted without text block in clipboard'
    ddLog.warn(msg, dummyHtml)
    return false
  }

  // alignment, element numbers and the block id are carried through
  // even when the block type isn't actually changing
  // if the existing block id is still present in the doc
  // the idMaker plugin will overwrite this to ensure uniqueness
  // we purposefully avoid our wrapper method with its additional ()/[] smarts
  setBlockType(schema.nodes[type.name], attrs)(view.state, view.dispatch)

  return false
}

/**
 * Creates a new plugin to handle input rules
 * @param {object} config - plugin config
 * @param {object} config.script - script object from backend
 * @param {string} config.script.type - script doc type
 * @return {Plugin} input rules plugin
 */
function inputRulesPlugin({ script, mst }) {
  const { type } = script
  const textInputRules = getTextInputRules(type)
  // modified copy of inputRules that doesn't return true on handleTextInput
  // ref: https://github.com/ProseMirror/prosemirror-inputrules/blob/master/src/inputrules.js#L53-L83
  return new Plugin({
    key: new PluginKey('INPUT_RULES'),
    state: {
      // ref: https://prosemirror.net/docs/ref/#state.StateField.init
      init() {
        // initial state
        return null
      },
      // ref: https://prosemirror.net/docs/ref/#state.StateField.apply
      apply(tr, prev) {
        // not totally sure what this is doing...
        // but the inputRules plugin does it so not messing it for now
        const stored = tr.getMeta(this)
        if (stored) {
          return stored
        }
        return tr.selectionSet || tr.docChanged ? null : prev
      },
    },
    props: {
      /**
       * ref: https://prosemirror.net/docs/ref/#view.EditorProps.handleTextInput
       * @param {EditorView} view - Prosemirror EditorView instance
       * @param {number} from
       * @param {number} to
       * @param {string} text
       */
      handleTextInput(view, from, to, text) {
        // skip if this is a range/node selection and not just a cursor position
        if (from !== to) {
          // prevent if there's an active selection containing locked pages
          if (lockedPagesInSelection(view.state)) {
            return true
          }
          return false
        }
        const { state } = view
        const $from = state.doc.resolve(from)
        // exit if there's more text after the cursor
        if (!isEndOfParentNode($from)) {
          return false
        }
        // ref: http://prosemirror.net/docs/ref/#model.Node.textBetween
        const textBefore =
          $from.parent.textBetween(
            Math.max(0, $from.parentOffset - MAX_MATCH), // from
            $from.parentOffset, // to
            null, // blockSeparator
            '\ufffc', // leafText
          ) + text
        for (let i = 0; i < textInputRules.length; i++) {
          const match = textInputRules[i].match.exec(textBefore)
          const tr =
            match &&
            textInputRules[i].handler(
              state,
              match,
              from - (match[0].length - text.length),
              to,
            )
          if (!tr) {
            continue
          }
          view.dispatch(tr.setMeta(this, { transform: tr, from, to, text }))
          return false // return false to not suppress text insertion
        }
        return false
      },
      /**
       * Intercept paste and convert Fountain or Scripto Classic text to Prose if it's detected
       * ref: https://prosemirror.net/docs/ref/#view.EditorProps.handlePaste
       * @param {EditorView} view - Prosemirror EditorView instance
       * @param {dom.Event} event - paste event
       * @param {Slice} _ - slice parsed by PM (ignored)
       */
      handlePaste(view, event) {
        // reset
        mst.currentScript?.clearCutComments()
        // abort (and notify) if the active selection contains a locked page
        if (!view.state.selection.empty) {
          if (lockedPagesInSelection(view.state)) return true
        }
        const { docType } = view.state.doc.attrs
        const isScreenplay = docType === types.SCREENPLAY
        const html = event.clipboardData.getData('text/html')
        const text = event.clipboardData.getData('text/plain')
        const pmPaste = html && html.includes('data-pm-slice')
        const classicPaste =
          html &&
          html.includes('id="magicdomid') &&
          script.type === types.CLASSIC
        // delegate to PM right away if no text is detected
        if (!text) {
          return false
        }

        const cleanRangeSelection =
          !view.state.selection.empty &&
          isSingleBlockTextSelection(view.state) &&
          !selectionHasUrl(view.state)

        const pasteContainsLink = linkify.test(text)
        const linkDataStore = pasteContainsLink && findLinksAndSplit(text)
        const bareUrl =
          linkDataStore && linkDataStore.length === 1 && linkDataStore[0].url

        // if non-url text is selected within a single block and clipboard is one url
        // inject a mark instead of replacing the selection wholesale
        // whether the clipboard contents are ProseMirrory or not
        // the only cost is that we lose formatting marks in the process
        if (cleanRangeSelection && bareUrl) {
          addLinkMarkToSelection(view, bareUrl)
          // sidestep default PM paste behavior
          return true
        }
        if (pmPaste) {
          return changeFirstBlockType(view, html)
        }
        // we could be DRYer if we had direct access to the output of transformPastedHTML
        if (classicPaste) {
          return changeFirstBlockType(view, massageClassicHTML(html))
        }

        /*
        when text is copy/pasted into a screenplay we inspect it for valid Fountain
        syntax to ensure that the appropriate block type and marks are carried over

        we test the plain text and convert recognized Studio block types too
        */
        let pastedDoc
        const lines = text.split('\n')
        if (isScreenplay) {
          const fountainDetected = lines.some((line) => {
            return SUPPORTED_FOUNTAIN.some((i) => fountainRegex[i].test(line))
          })
          if (fountainDetected) {
            pastedDoc = fountainToProse(text)
          }
        } else {
          const studioDetected = lines.some((line) => {
            return STUDIO_BLOCKS.some((i) => STUDIO_REGEX[i].test(line))
          })
          if (studioDetected) {
            pastedDoc = plainStudioToProse(lines)
          }
        }

        if (pasteContainsLink && !pastedDoc) {
          injectMarkedUpLinks({ editorView: view, ds: linkDataStore })
          return true
        }

        // no supported fountain/studio content detected, delegating to pm
        if (!pastedDoc) {
          return false
        }
        try {
          const node = schema.nodeFromJSON(pastedDoc)
          const slice = new Slice(Fragment.from(node), 2, 2)
          const tr = view.state.tr
            .replaceSelection(slice)
            .scrollIntoView()
            .setMeta('paste', true)
            .setMeta('uiEvent', 'paste')
          view.dispatch(tr)
          return true
        } catch (e) {
          console.error('Error parsing fountain or studio, delegating to PM', e)
          return false
        }
      },
      /**
       * counterintuitively this function runs BEFORE handlePaste.
       *
       * ref: https://prosemirror.net/docs/ref/#view.EditorProps.transformPastedHTML
       * @param {string} html
       */
      transformPastedHTML(html) {
        // magicdomid wrapper nodes indicate a multiline copy from Scripto Classic
        // ie: <div id="magicdomid1">, <div id="magicdomid2"> etc.
        if (html && html.includes('id="magicdomid')) {
          html = massageClassicHTML(html)
        }
        const pmPaste = html && html.includes('data-pm-slice')
        /*
          if no comment marks were cut previously or one of the cut marks isnt present
          in the clipboard HTML now, strip the mark prior to paste (to avoid duplicates)
        */
        if (pmPaste) {
          const ids = this.props.cutComments()
          html =
            ids && ids.length === 0
              ? stripComments(html)
              : // first param is matched string, second param is first group in regex
                html.replace(
                  COMMENT_RE,
                  (match, id) =>
                    ids?.includes(id)
                      ? match // either return match unchanged
                      : '', // or strip relevant data attributes
                )
        }
        // lots of word processing tools inject unicode "smart quotes".
        // since most prompter software is archaic, our best bet is to
        // get rid of the fancy unicode characters altogether
        html = stripUnsafeUnicodeChars(html)
        return html
      },
      /**
       * ref: https://prosemirror.net/docs/ref/#view.EditorProps.transformPastedText
       * @param {string} text
       */
      transformPastedText(text) {
        // AFAIK, PM doesn't offer a mechanism to massage
        // rich and plain text paste payloads simultaneously
        return stripUnsafeUnicodeChars(text)
      },
      cutComments() {
        return mst.currentScript?.cutComments
      },
      handleDOMEvents: {
        // avoid orphaned/empty blocks on cut/paste
        // shoehorned into this plugin a little bit randomly
        cut: (view) => extendSelection(view.state, view.dispatch),
      },
    },
    // special prop to trigger undoInputRule, see prosemirror-inputrules, glhf
    isInputRules: true,
  })
}

const stripUnsafeUnicodeChars = (text) => {
  unicodeToReplace.forEach((o) => {
    if (o.re.test(text)) {
      text = text.replace(o.re, o.replacement)
    }
  })
  return text
}

export {
  COMMENT_RE,
  TRANSITION_RE,
  inputRulesPlugin,
  stripComments,
  stripUnsafeUnicodeChars,
}
