import { useQuery } from '@tanstack/react-query'

import { NotFoundPage, UnknownErrorPage } from '@components/ErrorPage'
import { Loader } from '@components/Loader'
import { RundownEditor } from '@components/RundownEditor'
import { useMst } from '@state'
import { parseServerError } from '@util'

export const RundownLoader = ({ rundownId }: { rundownId: string }) => {
  const root = useMst()
  const { data, isError, isLoading, error } = useQuery({
    queryFn: async () => root.requestRundown(rundownId),
    queryKey: ['rundown', rundownId],
    cacheTime: 0,
    staleTime: Infinity,
  })

  if (isError) {
    const parsedError = parseServerError(error)
    return parsedError.status === 404 ? <NotFoundPage /> : <UnknownErrorPage />
  }

  if (data) {
    return <RundownEditor rundown={data} />
  }

  return <Loader visible={isLoading} />
}
