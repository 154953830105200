import React from 'react'

import { NodeTypeKey, NodeTypeMap } from '@showrunner/codex'

import { DocInfoState } from '@choo-app/lib/editor/plugins/doc-info'
import { showAsyncConfirmModal } from '@components/Modals'
import { ILoadedScript } from '@state/types'

import { PaidMenuItem } from './PaidMenuItem'

const getNumberingState = (
  docNumbering: DocInfoState['elementNumbers'],
  key: NodeTypeKey,
): {
  nodeExists?: boolean
  isNumbered?: boolean
} => {
  const docInfoForKey = docNumbering[key]
  const docInfoForAltKey =
    key === NodeTypeMap.DIALOGUE
      ? docNumbering[NodeTypeMap.DUAL_DIALOGUE]
      : undefined
  return {
    nodeExists: docInfoForKey?.nodeExists || docInfoForAltKey?.nodeExists,
    isNumbered: docInfoForKey?.isNumbered || docInfoForAltKey?.isNumbered,
  }
}

export const NumberingItems = ({
  isUnpaidOrg,
  script,
  docNumbering,
}: {
  docNumbering: DocInfoState['elementNumbers']
  isUnpaidOrg: boolean
  script: ILoadedScript
}) => {
  const numberingItems: React.ReactNode[] = []

  script.manuallyNumberableBlockTypes.forEach((t) => {
    const { isNumbered, nodeExists } = getNumberingState(docNumbering, t)
    if (nodeExists) {
      const displayType = t === NodeTypeMap.SCENE_HEADING ? 'scene' : t
      const title = (isNumbered ? 'Remove' : 'Add') + ` ${displayType} numbers`

      const toggleNumbering = () => {
        if (isNumbered) {
          showAsyncConfirmModal({
            title: 'Element Numbering',
            children: `Are you sure you want to remove all ${displayType} numbers?`,
            errorMessage: 'Failed to remove element numbers',
            onConfirm: () => Promise.resolve(script.toggleElementNumbers(t)),
            dangerous: true,
          })
        } else {
          script.toggleElementNumbers(t)
        }
      }

      numberingItems.push(
        <PaidMenuItem
          key={t}
          title={title}
          onClick={toggleNumbering}
          disabled={!nodeExists || !script.isEditable}
          isUnpaidOrg={isUnpaidOrg}
        ></PaidMenuItem>,
      )
    }
  })

  return <>{numberingItems}</>
}
